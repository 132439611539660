import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BsArrowRight, BsFillArrowRightCircleFill, BsFillSunFill, BsMoonFill, BsTwitter } from 'react-icons/bs';
import { GrLinkedinOption } from 'react-icons/gr';
import "../styles/about.css";
// import hdfc from "../assets/About/HDFC.png"




import hdfc from "../assets/About/HDFCmin.png"
import IND from "../assets/About/INDmini.png"
import loco from "../assets/About/locoMINI.png"
import IDFC from "../assets/About/IDFCmini.png"



import logo1 from "../assets/logos/1.png";
import logo2 from "../assets/logos/2.png";
import logo3 from "../assets/logos/3.png";
import logo4 from "../assets/logos/4.png";
import logo5 from "../assets/logos/5.png";
import logo6 from "../assets/logos/6.png";
import logo7 from "../assets/logos/7.png";
import logo8 from "../assets/logos/8.png";
import logo9 from "../assets/logos/9.png";
import logo10 from "../assets/logos/10.png";
import logo11 from "../assets/logos/11.png";
import logo12 from "../assets/logos/12.png";









import profile from "../assets/About/profile.png"
import { Swiper, SwiperSlide } from "swiper/react";
import {
    EffectFade,
    Autoplay,
    Navigation,
    Pagination,
} from "swiper/modules";
import "swiper/css/bundle";
import "swiper/css/navigation";
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { DarkModeContext } from '../context/DarkModeState';
import SwitchCircle, { Circle } from '../components/Global/Circle';
import { Link } from 'react-router-dom';

export default function AboutUs() {

    const swiperRef = useRef();
    const swiperRef2 = useRef();
    const swiperRef3 = useRef();
    const arr1 = [hdfc, IND, loco, IDFC, IDFC, IND, loco];
const obj = [[IND, loco, IDFC, hdfc, logo2, logo4, hdfc], ,[logo1,logo2,logo3,logo4,logo5,logo6,logo7],[logo8,logo9,logo10,logo11,logo12,logo3,logo1]]

    let darkMode = useContext(DarkModeContext);
    const [dark, setDark] = useState(false);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        setDark(darkMode.darkMode);
    }, [darkMode.darkMode]);


    const [SmallCaro, setSmallCaro] = useState(0);
    const [SmallCaroTrans, setSmallCaroTrans] = useState(0);
    const smallLength = arr1.length;

    const SmallCaroFunc = () => {
        if (SmallCaro < smallLength - 4) {
            setSmallCaro(SmallCaro + 1);
            setSmallCaroTrans(SmallCaroTrans + 10)
        }
        else {
            setSmallCaro(0);
            setSmallCaroTrans(0);
        }
    }
    var dd = "-" + SmallCaroTrans + "rem";


    const [togleWhat, setTogleWhat] = useState(false);


    return (
        <div className='flex flex-col py-28 gap-14 relative overflow-x-hidden'>
            <div className={`fixed bottom-3 right-0 border-l  border-t border-b ${dark ? "border-white" : "border-black"} rounded-s-lg  p-2 z-[13] flex items-center gap-3`}>
                {dark ? <BsMoonFill size={22} color='white' /> : <BsFillSunFill size={25} />}
                <div onClick={() => {
                    darkMode.setDarkMode(!darkMode.darkMode);
                }} className={`w-[60px] border cursor-pointer rounded-full p-[1px] flex ${dark ? "justify-end border-white" : "justify-start border-black"}`}>
                    <div className={`w-[25px] h-[25px] ${dark ? "bg-white" : "bg-black"} rounded-full`}></div>
                </div>
            </div>

            <div className={`absolute top-0 left-0 w-full h-full ${dark ? "bg-black" : "bg-white"} z-[-5]`}></div>
            <div className={`absolute top-0 left-0 w-full h-full ${dark ? "bg-[#000B28]" : "bg-[#F8F8F8]"} opacity-70 blur-[50px] z-[-1]`}></div>

            <div className='min-h-[50vh] flex justify-center items-center px-8 sm:px-16 py-20 relative'>
                <Circle radius={"w-[300px] h-[300px]"} color={"#452979"} top={"20%"} left={"-6%"} />
                <p className={'text-center max-w-screen-lg 2xl:max-w-screen-xl tracking-[4px] font-[Kaisei Opti] text-[1.1rem] xxs:text-[1.5rem] xs::text-[1.7rem] s:text-[2rem] sm:text-[2.5rem] md:text-5xl 2xl:text-6xl ' + `${dark ? "text-white" : "text-black"}`}>“Our commitment revolves<br /> around you, your dreams, and <br /> financial success.”</p>
                <Circle radius={"w-[350px] h-[350px]"} color={"rgba(7,116,185,0.6)"} top={"20%"} left={"80%"} />
            </div>

            <div className='flex  justify-center px-6 text-center md:px-16'>
                <div className='flex flex-col md:flex-row max-w-screen-lg 2xl:max-w-screen-xl bg-[rgb(0,0,0,0.7)] rounded-2xl p-12 gap-10'>
                    <div className='md:w-[40%] flex justify-center items-center '>
                        <img className='w-[330px] h-full object-cover self-center ' src={profile} />
                    </div>
                    <div className='flex-1 flex flex-col gap-4 text-white text-left'>
                        <h2 className='text-[1.75rem] font-bold'>"Highly regarded figure in the financial products distribution"</h2>
                        <p className='font-thin'>A Chartered Accountant qualified in 1979, Raj worked overseas with KPMG for 2 years and practiced professionally in Delhi for a decade before founding ActivPaisa 1989. His visionary and pioneering effort helped set the tone for a flourishing distribution.</p>
                        <div>
                            <p>Mohammad Anwar</p>
                            <p className='font-thin text-[0.75rem]'>CEO</p>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <p className='font-thin text-[0.75rem] border-b border-white w-[max-content]'>Connect with Us!</p>
                            <div className='flex gap-2'>
                                <BsTwitter color='3080CF' />
                                <GrLinkedinOption color='3080CF' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-center px-4 xxs:p-8 md:px-16'>
                <div className='flex flex-col md:flex-row max-w-screen-lg 2xl:max-w-screen-xl bg-[rgb(0,0,0,0.25)] rounded-2xl p-4 xs:p-8   md:p-12 gap-10'>
                    <div className='flex-1 flex flex-col gap-4 text-black text-center sm:text-start'>
                        <p>
                            Choose ActivPaisa to simplify your borrowing journey. Since 1989,
                            ActivPaisa is helping customers, find the best financial products in
                            India. With 100+ banks and NBFCs we assure tailored deals on Personal
                            Loans, Home Loans, Business Loans, Loan Against Property, Gold Loans,
                            and Credit Cards according to customer preferences. Our family of
                            2500+ loan specialists are skilled & trained to assist customers make
                            informed decisions. We take care of complete loan process from
                            application, documentation, approval to disbursal, thereby creating
                            timeless experience for each customer query irrespective of the ticket-
                            size. We employ latest tech stack and assure data privacy & security on
                            our website & app.
                        </p>
                    </div>
                    <div className='md:w-[45%] flex justify-center items-center relative'>
                        <Circle radius={"w-[400px] h-[400px]"} color={"rgba(7,116,185,0.2)"} top={"-10%"} left={"10%"} />
                        <img className='w-[320px]' src={require("../assets/About/Hero2.png")} />
                    </div>
                </div>
            </div>

            <div className='flex justify-center px-4 xxs:p-8 md:px-16'>
                <div className='max-w-screen-lg 2xl:max-w-screen-xl flex flex-col relative'>
                    <SwitchCircle radius={"w-[700px] h-[700px]"} top={"0%"} left={"-5%"} />
                    <div className='h-full flex items-end'>
                        <div className={`${togleWhat ? "bg-[#9D9D9D]" : "bg-[#BFBFBF]"} border-t border-l border-r border-black h-[max-content] ${togleWhat ? "p-4" : "p-2.5"} rounded-t-2xl ${togleWhat ? "z-[13]" : "z-[12]"}`} onClick={() => { setTogleWhat(!togleWhat) }}>What we do?</div>
                        <div className={`${!togleWhat ? "bg-[#9D9D9D]" : "bg-[#BFBFBF]"} border-t border-l border-r border-black h-[max-content] ${!togleWhat ? "p-4" : "p-2.5"} rounded-t-2xl ${!togleWhat ? "z-[13]" : "z-[12]"}  ${togleWhat ? "pl-10 -ml-9" : ""}`} onClick={() => { setTogleWhat(!togleWhat) }}>What we do?</div>
                    </div>
                    <div className='w-full flex flex-col md:flex-row gap-10 bg-[#9D9D9D] rounded-b-2xl p-4 xxs:p-7 xs:p-9 md:p-12 border border-black'>
                        <div className='flex-1 flex justify-center items-center'>
                            {togleWhat?<img className='w-[200px] h-[180px]' src={require("../assets/About/Hero3.png")} />:<img className='w-[200px] h-[180px]' src={require("../assets/About/what-we-do-1.jpg")} />}
                        </div>
                        <div className='flex-1 flex flex-col gap-4 text-black'>
                            {togleWhat?("ActivPaisa is a real time marketplace for loans and credit cards. We employ latest technology, AI, ML & our pan India presence to search for the best available loan offers available on lender{`'`}s app, website or at branch."):("We are a dedicated team committed to providing you with the best financial solutions to support your goals. With years of experience and expertise in the industry, we strive to empower you on your financial journey.")}
                        </div>
                        <div className='flex-1 flex flex-col gap-4 text-black'>
                            {togleWhat?("We act as a bridge between lenders and borrowers by educating, facilitating and empowering applicants with the widest choice of financial products in India. You can reach out directly through our website or app."):("Our user-friendly platform, personalized approach, and transparent processes ensure that you have a seamless and satisfying experience.")}
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='w-full flex relative'>
                <div className='bg-[#0774B9] flex-1 px-16 py-12'>
                    <p className='text-4xl text-white tracking-wide text-border opacity-0'>
                        The best offers<br />
                        from India{`'`}s most<br />
                        <b>trusted banks</b>
                    </p>
                </div>
                <div className='w-[40%] px-16 py-12'></div>
                <div className='flex absolute w-full h-full'>
                    <p className='text-4xl text-white whitespace-nowrap tracking-wide text-border px-16 py-12'>
                        The best offers<br />
                        from India{`'`}s most<br />
                        <b>trusted banks</b>
                    </p>
                    <div className='flex-1 h-full flex items-center relative'>
                        <Swiper
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                            modules={[Navigation, Pagination, EffectFade, Autoplay]}
                            loop={true}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            slidesPerView={4}
                            spaceBetween={30}
                            preventInteractionOnTransition={true}
                            className="w-[max-content] max-w-[500px]"
                            keyboard={{
                                enabled: true,
                                onlyInViewport: true,
                            }}
                            freeMode={false}
                            draggable={true}
                        // breakpoints={{
                        //     300: {
                        //         slidesPerView: 1,
                        //         spaceBetween: 50,
                        //     },
                        //     500: {
                        //         slidesPerView: 2,
                        //         spaceBetween: 30,
                        //     },
                        //     768: {
                        //         slidesPerView: 3,
                        //         spaceBetween: 30,
                        //     },
                        //     1024: {
                        //         slidesPerView: 4,
                        //         spaceBetween: 30,
                        //     },
                        // }}
                        >
                            {arr1.map((i, index) => (
                                <SwiperSlide key={index}>
                                    <div key={index} className='w-[100px] h-[100px] px-6 py-3 bg-white rounded-md flex items-center justify-center'>
                                        <img className='w-[4rem]' src={i} />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div onClick={() => swiperRef.current?.slideNext()} className="cursor-pointer w-10 h-10 flex items-center justify-center bg-[#0774B9] p-1.5 mr-8 rounded-full shadow-sm">
                            <FaGreaterThan className="text-white" />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='w-full flex flex-col md:flex-row '>
                <div className='w-full md:w-[60%] bg-[#0774B9] py-5 px-5  md:px-7 lg:px-20'>
                    <p className='text-center md:text-start text-[1rem] s:text-[1.3rem] md:text-[1.65rem] lg:text-4xl text-white tracking-wide text-border'>
                        The best offers<br />
                        from India{`'`}s most<br />
                        <b>trusted banks</b>
                    </p>
                </div>

                <div className='w-full md:w-[60%] z-10 md:ml-[-20%] justify-center overflow-hidden flex flex-col relative '>

                    <Swiper
                        onBeforeInit={(swiper) => {
                            swiperRef3.current = swiper;
                        }}
                        modules={[Navigation, Pagination, EffectFade, Autoplay]}
                        loop={true}
                        // autoplay={{
                        //     delay: 3000,
                        //     disableOnInteraction: false,
                        // }}
                        slidesPerView={1}
                        spaceBetween={300}
                        preventInteractionOnTransition={true}
                        className="w-full h-full"
                        keyboard={{
                            enabled: true,
                            onlyInViewport: true,
                        }}
                        freeMode={false}
                        draggable={true}

                    >
                        {/* <SwiperSlide/> */}
                        {/* <div className='flex gap-7 vala'  style={{
                            "--smallCaro": dd,
                        }}>
                        {arr1.map((i)=>(
                            <div className='bg-white flex h-[7rem] min-w-[9rem]  justify-center items-center rounded-lg'>
                                <img className='w-[70px]' src={i}></img>
                            </div>
                        ))}
                    </div> */}
                        {obj.map((indexarr) => (
                            <SwiperSlide>
                                <div className='flex gap-7 mt-[1.5rem]' style={{
                                    "--smallCaro": dd,
                                }}>
                                    {indexarr.map((i) => (
                                        <div className='bg-white flex h-[7rem] min-w-[9rem]  justify-center items-center rounded-lg'>
                                            <img className='w-[70px]' src={i}></img>
                                        </div>
                                    ))}
                                </div>
                            </SwiperSlide>
                        ))}
                        {/* <SwiperSlide>
                            <div className='flex gap-7 mt-[1.5rem]' style={{
                                "--smallCaro": dd,
                            }}>
                                {arr1.map((i) => (
                                    <div className='bg-white flex h-[7rem] min-w-[9rem]  justify-center items-center rounded-lg'>
                                        <img className='w-[70px]' src={i}></img>
                                    </div>
                                ))}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='flex gap-7 mt-[1.5rem]' style={{
                                "--smallCaro": dd,
                            }}>
                                {arr1.map((i) => (
                                    <div className='bg-white flex h-[7rem] min-w-[9rem]  justify-center items-center rounded-lg'>
                                        <img className='w-[70px]' src={i}></img>
                                    </div>
                                ))}
                            </div>
                        </SwiperSlide> */}
                    </Swiper>
                    <BsFillArrowRightCircleFill className='text-[#0774b9] text-[2rem] absolute right-[1.5rem] z-40  ' onClick={() => swiperRef3.current?.slideNext()} />
                    {/* onClick={() => { SmallCaroFunc() }} */}

                </div>
            </div>

            {/* Testimonials */}
            <div className='w-full h-[60vh] flex justify-center relative px-2 xxs:px-4 s:px-8'>
                <div className='flex-1 max-w-screen-2xl w-full h-full flex items-center relative'>
                    <SwitchCircle radius={"w-[900px] h-[900px]"} top={"0%"} />
                    <div onClick={() => swiperRef2.current?.slidePrev()} className="w-10 h-10 flex items-center justify-center bg-white p-2 rounded-full shadow-sm cursor-pointer">
                        <FaLessThan className="text-gray-600" />
                    </div>
                    <Swiper
                        onBeforeInit={(swiper) => {
                            swiperRef2.current = swiper;
                        }}
                        modules={[Navigation, Pagination, EffectFade, Autoplay]}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        slidesPerView={1}
                        spaceBetween={30}
                        preventInteractionOnTransition={true}
                        className="w-full h-full"
                        keyboard={{
                            enabled: true,
                            onlyInViewport: true,
                        }}
                        freeMode={false}
                        draggable={true}
                    // breakpoints={{
                    //     300: {
                    //         slidesPerView: 1,
                    //         spaceBetween: 50,
                    //     },
                    //     500: {
                    //         slidesPerView: 2,
                    //         spaceBetween: 30,
                    //     },
                    //     768: {
                    //         slidesPerView: 3,
                    //         spaceBetween: 30,
                    //     },
                    //     1024: {
                    //         slidesPerView: 4,
                    //         spaceBetween: 30,
                    //     },
                    // }}
                    >
                        <SwiperSlide>
                            <div className='w-[90%] max-w-screen-lg 2xl:max-w-screen-xl h-full mx-auto flex flex-col s:flex-row justify-center bg-[#4c4c4c] text-white p-2 xxs:p-4 s:p-6 md:p-7 gap-[2rem] rounded-md'>
                                <div className='flex flex-col justify-center gap-2'>
                                    <img className='h-[300px] s:h-[85%] object-contain' src={require("../assets/About/testimonial-01.png")} />
                                    <div className='h-fit flex flex-col'>
                                        <h1 className='text-[0.79] xxs:text-[0.9rem]'>RICHAND SCOTT</h1>
                                        <span className='font-[200] text-[10px] xxs:text-[12px]'>Chairman</span>
                                    </div>
                                </div>
                                <div className='md:w-[30rem] font-[200] text-[0.79] xxs:text-[0.9rem] 2xl:text-[1.5rem] text-center flex items-center justify-center'>
                                    <p>“I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.”</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='w-[90%] max-w-screen-lg 2xl:max-w-screen-xl h-full mx-auto flex flex-col s:flex-row justify-center bg-[#4c4c4c] text-white p-2 xxs:p-4 s:p-6 md:p-7 gap-[2rem] rounded-md'>
                                <div className='flex flex-col justify-center gap-2'>
                                    <img className='h-[300px] s:h-[85%] object-contain' src={require("../assets/About/testimonial-01.png")} />
                                    <div className='h-fit flex flex-col'>
                                        <h1 className='text-[0.79] xxs:text-[0.9rem]'>RICHAND SCOTT</h1>
                                        <span className='font-[200] text-[10px] xxs:text-[12px]'>Chairman</span>
                                    </div>
                                </div>
                                <div className='md:w-[30rem] font-[200] text-[0.79] xxs:text-[0.9rem] 2xl:text-[1.5rem] text-center flex items-center justify-center'>
                                    <p>“I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.”</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='w-[90%] max-w-screen-lg 2xl:max-w-screen-xl h-full mx-auto flex flex-col s:flex-row justify-center bg-[#4c4c4c] text-white p-2 xxs:p-4 s:p-6 md:p-7 gap-[2rem] rounded-md'>
                                <div className='flex flex-col justify-center gap-2'>
                                    <img className='h-[300px] s:h-[85%] object-contain' src={require("../assets/About/testimonial-01.png")} />
                                    <div className='h-fit flex flex-col'>
                                        <h1 className='text-[0.79] xxs:text-[0.9rem]'>RICHAND SCOTT</h1>
                                        <span className='font-[200] text-[10px] xxs:text-[12px]'>Chairman</span>
                                    </div>
                                </div>
                                <div className='md:w-[30rem] font-[200] text-[0.79] xxs:text-[0.9rem] 2xl:text-[1.5rem] text-center flex items-center justify-center'>
                                    <p>“I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.”</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        {/* <SwiperSlide>
                            <div className='w-[90%] max-w-screen-lg 2xl:max-w-screen-xl h-full mx-auto flex justify-center bg-[#4c4c4c] text-white p-7 gap-[2rem] rounded-md'>
                                <div className='flex flex-col justify-center gap-2'>
                                    <img className='h-[85%] object-contain' src={require("../assets/About/testimonial-01.png")} />
                                    <div className='h-fit flex flex-col'>
                                        <h1 className='text-[0.9rem]'>RICHAND SCOTT</h1>
                                        <span className='font-[200] text-[12px]'>Chairman</span>
                                    </div>
                                </div>
                                <div className='w-[30rem] font-[200] text-[1.2rem] 2xl:text-[1.5rem] text-center flex items-center justify-center'>
                                    <p>“I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.”</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='w-[90%] max-w-screen-lg 2xl:max-w-screen-xl h-full mx-auto flex justify-center bg-[#4c4c4c] text-white p-7 gap-[2rem] rounded-md'>
                                <div className='flex flex-col justify-center gap-2'>
                                    <img className='h-[85%] object-contain' src={require("../assets/About/testimonial-01.png")} />
                                    <div className='h-fit flex flex-col'>
                                        <h1 className='text-[0.9rem]'>RICHAND SCOTT</h1>
                                        <span className='font-[200] text-[12px]'>Chairman</span>
                                    </div>
                                </div>
                                <div className='w-[30rem] font-[200] text-[1.2rem] 2xl:text-[1.5rem] text-center flex items-center justify-center'>
                                    <p>“I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.”</p>
                                </div>
                            </div>
                        </SwiperSlide> */}
                    </Swiper>
                    <div onClick={() => swiperRef2.current?.slideNext()} className="cursor-pointer w-10 h-10 flex items-center justify-center bg-white p-2 rounded-full shadow-sm">
                        <FaGreaterThan className="text-gray-600" />
                    </div>
                </div>
            </div>

            {/* <div className='w-full flex flex-col'>
                <div className='w-full flex'>
                    <div className='w-[75%] bg-[#0774b9] h-[10rem] px-16 text-[white] text-[1.4rem] font-[200] flex  items-center'>
                        <h1 className='w-[12rem]'>The best offers
                            from India's most{" "}
                            <span className='font-[500]'>trusted banks</span>
                        </h1>
                    </div>
                    <div className='flex ml-[-17rem] gap-[1.7rem] items-center relative  overflow-hidden w-[52rem] '>
                        <div className={`flex gap-[1.7rem] vala`} style={{
                            "--smallCaro": dd,
                        }}>
                            {arr1.map((i,index)=><div key={index} className=' px-6 py-3 bg-white rounded-md h-fit '>
                                <img className='w-[4rem]' src={i} />
                            </div>)}
                        </div>
                        <BsFillArrowRightCircleFill className='text-[#0774b9] text-[2rem] absolute right-5 ' onClick={() => { SmallCaroFunc() }} />
                    </div>
                </div>
                <div>
                    <h1 className='text-center text-[3rem] py-12'>Here's What <span className='text-[#5696d7]'>Our Customers</span> Think!</h1>
                    <div className='flex gap-[25rem] justify-center relative items-center  '>
                        <div className=' flex gap-[25rem] justify-center relative items-center vala2' style={{
                            "--bigCaro": bigcaroplus,
                        }}>
                            <div className='flex bg-[#4c4c4c] text-white p-7 w-fit gap-[2rem] rounded-md '>
                                <div className='flex flex-col gap-2'>
                                    <img className='w-[200px]' src={profile} />
                                    <div className='flex flex-col'>
                                        <h1 className='font-[0.9rem]'>RICHAND SCOTT</h1>
                                        <span className='font-[200] text-[12px]'>Chairman</span>
                                    </div>
                                </div>
                                <div className='w-[30rem] font-[200] text-[1.3rem] text-center flex items-center mt-[-1.5rem]'>
                                    <p>“I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.”</p>
                                </div>
                            </div>
                            <div className='flex bg-[#4c4c4c] text-white py-8 px-12 w-fit gap-[2rem] rounded-md '>
                                <div className='flex flex-col gap-2'>
                                    <img className='w-[200px]' src={profile} />
                                    <div className='flex flex-col'>
                                        <h1 className='font-[0.9rem]'>RICHAND SCOTT</h1>
                                        <span className='font-[200] text-[12px]'>Chairman</span>
                                    </div>
                                </div>
                                <div className='w-[30rem] font-[200] text-[1.3rem] text-center flex items-center mt-[-1.5rem]'>
                                    <p>“I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.”</p>
                                </div>
                            </div>
                            <div className='flex bg-[#4c4c4c] text-white p-7 w-fit gap-[2rem] rounded-md '>
                                <div className='flex flex-col gap-2'>
                                    <img className='w-[200px]' src={profile} />
                                    <div className='flex flex-col'>
                                        <h1 className='font-[0.9rem]'>RICHAND SCOTT</h1>
                                        <span className='font-[200] text-[12px]'>Chairman</span>
                                    </div>
                                </div>
                                <div className='w-[30rem] font-[200] text-[1.3rem] text-center flex items-center mt-[-1.5rem]'>
                                    <p>“I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.”</p>
                                </div>
                            </div>
                        </div>
                        <div className='text-[2rem] absolute flex justify-between w-full px-10'>
                            <IoIosArrowBack onClick={()=>{BigCaroFunc()}}  />
                            <IoIosArrowForward onClick={()=>{BigCaroFuncmin()}}  />
                        </div>
                    </div>
                </div>
            </div> */}


            <div className='flex  justify-center px-4 xxs:px-7 xs:px-10 s:px-14 md:px-16 py-12'>
                <div className='w-full max-w-screen-lg 2xl:max-w-screen-xl flex flex-wrap gap-[3rem] justify-center'>
                    <Link to={"/personal-loan"} className='min-w-[19rem] loan-card flex items-center gap-6 rounded-lg px-6 py-4 border-2 border-[#452979] text-[#452979] cursor-pointer'>
                        <img className='w-12 h-12' src={require("../assets/About/personal-loan.png")} />
                        <p className='flex-1 whitespace-nowrap font-bold text-lg'>Personal Loan</p>
                        <BsArrowRight size={24} />
                    </Link>
                    <Link to={"/business-loan"} className='w-[19rem] loan-card flex items-center gap-6 rounded-lg px-6 py-4 border-2 border-[#452979] text-[#452979] cursor-pointer'>
                        <img className='w-12 h-12' src={require("../assets/About/personal-loan.png")} />
                        <p className='flex-1 whitespace-nowrap font-bold text-lg'>Business loan</p>
                        <BsArrowRight size={24} />
                    </Link>
                    <Link to={"/professional-loan"} className='w-[19rem] loan-card flex items-center gap-3 rounded-lg px-6 py-4 border-2 border-[#452979] text-[#452979] cursor-pointer'>
                        <img className='w-12 h-12' src={require("../assets/About/personal-loan.png")} />
                        <p className='flex-1 whitespace-nowrap font-bold text-lg'>Professional loan</p>
                        <BsArrowRight size={24} />
                    </Link>
                    <Link to={"/home-loan"} className='w-[19rem] loan-card flex items-center gap-6 rounded-lg px-6 py-4 border-2 border-[#452979] text-[#452979] cursor-pointer'>
                        <img className='w-12 h-12' src={require("../assets/About/personal-loan.png")} />
                        <p className='flex-1 whitespace-nowrap font-bold text-lg'>Home loan</p>
                        <BsArrowRight size={24} />
                    </Link>
                    <Link to={"/loan-Against-Property"} className='w-[19rem] loan-card flex items-center gap-6 rounded-lg px-6 py-4 border-2 border-[#452979] text-[#452979] cursor-pointer'>
                        <img className='w-12 h-12' src={require("../assets/About/personal-loan.png")} />
                        <p className='flex-1 whitespace-nowrap font-bold text-lg'>Property Loan</p>
                        <BsArrowRight size={24} />
                    </Link>
                    <Link to={"/preowned-car-loan"} className='w-[19rem] loan-card flex items-center gap-6 rounded-lg px-6 py-4 border-2 border-[#452979] text-[#452979] cursor-pointer'>
                        <img className='w-12 h-12' src={require("../assets/About/personal-loan.png")} />
                        <p className='flex-1 whitespace-nowrap font-bold text-lg'>Car Loan</p>
                        <BsArrowRight size={24} />
                    </Link>
                </div>
            </div>

            <div className='flex justify-center px-4 xxs:px-7 xs:px-10 s:px-14 md:px-16 relative'>
                <Circle radius={"w-[550px] h-[550px]"} color={"rgba(7,116,185,0.6)"} top={"0%"} left={"70%"} />
                <div className='w-full max-w-screen-lg 2xl:max-w-screen-xl flex flex-col items-center bg-[rgba(0,0,0,0.7)] rounded-xl py-8 px-2 xxs:px-5 xs:px-8 s:px-10 md:px-12 relative'>
                    <h1 className='uppercase text-white text-[2.5rem] mb-6'>Get in touch</h1>
                    <div className='md:w-[60%] grid grid-cols-2 gap-x-8 gap-y-4'>
                        <input placeholder='Full Name' className='border text-sm border-white outline-none bg-transparent px-2 py-1 text-white rounded-lg placeholder:text-white' />
                        <input placeholder='Email Address' className='border text-sm border-white outline-none bg-transparent px-2 py-1 text-white rounded-lg placeholder:text-white' />
                        <input placeholder='Phone Number' className='border text-sm border-white outline-none bg-transparent px-2 py-1 text-white rounded-lg placeholder:text-white' />
                        <input placeholder='City' className='border text-sm border-white outline-none bg-transparent px-2 py-1 text-white rounded-lg placeholder:text-white' />
                        <textarea placeholder='Leave a comment' className='col-start-1 col-end-3 border text-sm border-white outline-none bg-transparent px-2 py-1 h-[160px] text-white rounded-lg placeholder:text-white' />
                        <button className='col-start-2 justify-self-end px-3 py-2 w-[max-content] bg-[#58A8F7] rounded-lg text-sm text-white'>Submit</button>
                    </div>
                    <img className='absolute bottom-0 right-0 w-[100px] object-cover z-[-1] md:z-0' src={require("../assets/About/Star.png")} />
                    <img className='absolute top-0 left-0 w-[300px] object-cover' src={require("../assets/About/Circles-contactus.png")} />
                </div>
            </div>
        </div>
    )
}
