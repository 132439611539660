import React, { useLayoutEffect, useState } from "react";
import FormTempelate from "./FormTempelate";
import { BsPerson, BsCreditCard2Front } from "react-icons/bs";
import { CiMobile3 } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbBuildingEstate } from "react-icons/tb";
import { CgCalendarDates } from "react-icons/cg";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { BsFillPersonLinesFill, BsGenderAmbiguous } from "react-icons/bs";
import { CgPassword } from "react-icons/cg";
import { FaCity, FaCarSide } from "react-icons/fa";
// import { FaMountainCity } from 'react-icons/fa';
import StepIndicator from "../Global/StepIndicator";
import SelectField from "./Fields/SelectField";
import TextField from "./Fields/TextField";
import RadioField from "./Fields/RadioField";
import { useNavigate } from "react-router";
import axios from "axios";

export const Page1 = ({ data, setData, nextPage }) => {
    const [selected, setSelected] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [errorCheck, setErrorCheck] = useState(false);

    return (
        <div>
            <div className="flex flex-col gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.
                </h1>
                <p className="text-[24px] text-[#452979]">
                    Now take the first step to fulfil your business goals.
                </p>
            </div>
            <div className="flex flex-col gap-1 py-5">
                <div className="pb-10">
                    <StepIndicator step={1} total={3} />
                </div>
            </div>
            <div className="flex justify-between w-[100%]">
                <div className=" w-[45%]">
                    <SelectField

                        errorCheck={errorCheck}
                        value={data.applicantType}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, applicantType: value };
                            });
                        }}
                        label="Applicant Type"
                        errorMessage={"Select Applicant type"}
                        options={["Individual", "Non-individual"]}
                    />
                </div>
                <div className="w-[45%]">
                    {data.applicantType && <SelectField
                        errorCheck={errorCheck}
                        value={data.profession}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, profession: value };
                            });
                        }}
                        label="Select your Profession"
                        errorMessage={"Please Select your Profession"}
                        options={["Chartered Accountant",
                            "Company Secretary",
                            "Doctor"]}
                    />}
                </div>
            </div>
            {data.applicantType && (
                <div className="flex flex-col gap-1 py-5">
                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 place-items-center">
                        {data.applicantType === "Individual" ? (
                            <TextField
                                errorCheck={errorCheck}
                                label={"First Name"}
                                value={data.firstName}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, firstName: value };
                                    });
                                }}
                                errorMessage={"Enter First Name"}
                                Icon={BsPerson}
                            />
                        ) : (
                            <TextField
                                errorCheck={errorCheck}
                                label={"Firm/Company Name"}
                                value={data.firmName}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, firmName: value };
                                    });
                                }}
                                errorMessage={"Enter Firm/Company Name"}
                                Icon={BsFillPersonLinesFill}
                            />
                        )}
                        {data.applicantType === "Individual" ? (
                            <TextField
                                errorCheck={errorCheck}
                                label={"Last Name"}
                                value={data.lastName}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, lastName: value };
                                    });
                                }}
                                errorMessage={"Enter Last Name"}
                                Icon={BsPerson}
                            />
                        ) : (
                            <SelectField
                                errorCheck={errorCheck}
                                value={data.constitution}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, constitution: value };
                                    });
                                }}
                                label="Enter Constitution"
                                errorMessage={"Enter Constitution"}
                                options={[
                                    "Individual",
                                    "Proprietorship",
                                    "Partnership",
                                    "Limited Liability Partnership",
                                    "Private Limited Company",
                                    "Public Limited Company",
                                    "Trust",
                                ]}
                            />
                        )}
                        <TextField
                            errorCheck={errorCheck}
                            type="number"
                            value={data.mobile}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, mobile: value };
                                });
                            }}
                            label={"Number"}
                            errorMessage={"Enter a valid number"}
                            Icon={CiMobile3}
                        />
                        {/* <TextField type='number' value={data.verifyOtp} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, verifyOtp: value }
                    });
                }} label={"Verify Otp"} Icon={RiQuestionAnswerLine} /> */}

                        <TextField errorCheck={errorCheck} type='number' value={data.verifyOtp} setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, verifyOtp: value }
                            });
                        }} label={"OTP"} errorMessage={"Enter a valid OTP"} Icon={CiMobile3} />

                        <TextField
                            errorCheck={errorCheck}
                            value={data.email}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, email: value };
                                });
                            }}
                            label={"Email"}
                            errorMessage={"Enter a valid email Id"}
                            Icon={AiOutlineMail}
                            type="email"
                        />
                        <TextField
                            errorCheck={errorCheck}
                            value={data.panNumber}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, panNumber: value };
                                });
                            }}
                            label={"PAN Number"}
                            errorMessage={"Enter pan as per entity selected"}
                            Icon={BsCreditCard2Front}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            type="number"
                            value={data.loanAmount}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, loanAmount: value };
                                });
                            }}
                            label={"Loan Amount"}
                            errorMessage={"Enter loan amount"}
                            Icon={GiTakeMyMoney}
                        />
                        <SelectField
                            errorCheck={errorCheck}
                            value={data.tenure}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, tenure: value };
                                });
                            }}
                            label="Tenure"
                            errorMessage={"Please select Tenure"}
                            options={[
                                "1 Year",
                                "2 Years",
                                "3 Years",
                                "4 Years",
                                "5 Years",
                            ]}
                        />




                    </div>
                    <div className="mt-10 flex gap-3 items-center">
                        <input
                            onChange={(e) => {
                                setSelected(e.target.checked);
                            }}
                            type="checkbox"
                            id="proceedingConditions"
                            className="border border-[#452979] outline-none checked:bg-[#452979] checked:after:text-white"
                        />
                        <label
                            htmlFor="proceedingConditions"
                            className="text-gray-400 text-xs"
                        >
                            By proceeding I agree to{" "}
                            <span className="text-blue-700 underline">
                                Terms and Conditions
                            </span>{" "}
                            and have read/understood{" "}
                            <span className="text-blue-700 underline">
                                approach for gradation of risk.
                            </span>
                        </label>
                    </div>
                    {termsError && (
                        <p className="font-thin text-sm text-[red]">
                            Select Agree to Terms & Conditions
                        </p>
                    )}
                    <div className="mt-10">
                        <button
                            onClick={() => {
                                if (
                                    data.applicantType === "Individual" &&
                                    data.profession !== "" &&
                                    data.firstName !== "" &&
                                    data.lastName !== "" &&
                                    data.mobile !== "" &&
                                    data.email !== "" &&
                                    data.panNumber !== "" &&
                                    data.loanAmount !== "" &&
                                    data.tenure !== ""
                                    // &&data.verifyOtp!==""

                                ) {
                                    setErrorCheck(false);
                                    if (selected) {
                                        setTermsError(false);
                                        nextPage();
                                    } else {
                                        setTermsError(true);
                                    }
                                }
                                else if (
                                    data.applicantType === "Non-individual" &&
                                    data.profession !== "" &&
                                    data.firmName !== "" &&
                                    data.constitution !== "" &&
                                    data.mobile !== "" &&
                                    data.email !== "" &&
                                    data.panNumber !== "" &&
                                    data.loanAmount !== "" &&
                                    data.tenure !== ""
                                    // &&data.verifyOtp!==""


                                ) {
                                    setErrorCheck(false);
                                    if (selected) {
                                        setTermsError(false);
                                        nextPage();

                                    } else {
                                        setTermsError(true);
                                    }
                                }
                                else {
                                    setErrorCheck(true);
                                }
                            }
                            }
                            className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};


export const Page2 = ({ data, setData, prevPage, nextPage }) => {
    const [errorCheck, setErrorCheck] = useState(false);
    return (
        <div>
            <div className="flex flex-col gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.                 </h1>
                <p className="text-[24px] text-[#452979]">
                    You are one step closer.
                </p>
            </div>
            <div className="pb-10">
                <StepIndicator step={2} total={3} />
            </div>

            {data.applicantType === "Individual" ? (
                <>
                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 pb-10 place-items-center">

                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please select a valid Date of Birth "}
                            value={data.dateOfBirth}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, dateOfBirth: value };
                                });
                            }}
                            label={"Date of Birth"}
                            Icon={CgCalendarDates}
                            type="date"
                        />
                        <SelectField
                            errorCheck={errorCheck}
                            errorMessage={"Please select a Residence Ownership Status "}
                            value={data.residenceOwnershipStatus}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, residenceOwnershipStatus: value };
                                });
                            }}
                            label="Residence Ownership Status"
                            options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]}
                        />
                        <RadioField errorCheck={errorCheck} value={data.gender} setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, gender: value }
                            });
                        }} label={"Gender"} errorMessage={"Please select your gender"} Icon={HiOutlineCurrencyRupee} options={["Male", "Female", "Transgender"]} />
                    </div>
                    <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-2 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter a valid Pincode "}
                            value={data.residentialPincode}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, residentialPincode: value };
                                });
                            }}
                            label={"Residence Pincode"}
                            Icon={CgPassword}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter City "}
                            value={data.city}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, city: value };
                                });
                            }}
                            label={"City"}
                            Icon={FaCity}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter State "}
                            value={data.state}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, state: value };
                                });
                            }}
                            label={"State"}
                            Icon={TbBuildingEstate}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 pb-10 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please select a valid Date Of Incorporation"}
                            value={data.dateOfIncorporation}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, dateOfIncorporation: value };
                                });
                            }}
                            label={"Date of Incorporation"}
                            Icon={CgCalendarDates}
                            type="date"
                        />
                        <SelectField
                            errorCheck={errorCheck}
                            errorMessage={"Please select Business Premises Ownership "}
                            value={data.businessPremisesOwnership}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, businessPremisesOwnership: value };
                                });
                            }}
                            label="Business Premises Ownership"
                            options={["Owned by Self or Spouse", "Rented"]}
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-2 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter a valid Pincode "}
                            value={data.businessPincode}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, businessPincode: value };
                                });
                            }}
                            label={"Business Pincode"}
                            Icon={CgPassword}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter City "}
                            value={data.city}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, city: value };
                                });
                            }}
                            label={"City"}
                            Icon={FaCity}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter State "}
                            value={data.state}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, state: value };
                                });
                            }}
                            label={"State"}
                            Icon={TbBuildingEstate}
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-8 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter Annual Receipt "}
                            value={data.annualReceipt}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, annualReceipt: value };
                                });
                            }}
                            label={"Annual TernOver"}
                            Icon={TbBuildingEstate}
                            type="number"
                        />
                    </div>
                </>
            )}
            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {
                        if (
                            data.applicantType === "Individual" &&
                            data.dateOfBirth !== "" &&
                            data.residenceOwnershipStatus !== "" &&
                            data.gender !== "" &&
                            data.residentialPincode !== "" &&
                            data.city !== "" &&
                            data.state !== ""
                        ) {
                            setErrorCheck(false);
                            nextPage();
                        }
                        else if (
                            data.applicantType === "Non-individual" &&
                            data.dateOfIncorporation !== "" &&
                            data.businessPremisesOwnership !== "" &&
                            data.annualReceipt !== "" &&
                            data.businessPincode !== "" &&
                            data.city !== "" &&
                            data.state !== ""
                        ) {
                            setErrorCheck(false);
                            nextPage();
                        }
                        else {
                            setErrorCheck(true);
                            
                        }
                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};
// export const Page2 = ({ data, setData, prevPage, nextPage }) => {
//     return (
//         <div>
//             <div className="flex flex-col gap-1 py-10">
//                 <h1 className="text-[28px] font-semibold text-[#452979]">
//                     Gear Up to own a car with our Pre-owned Car Loan.                 </h1>
//                 <p className="text-[24px] text-[#452979]">
//                     You are one step closer.
//                 </p>
//             </div>
//             <div className="pb-10">
//                 <StepIndicator step={2} total={4} />
//             </div>

//             {data.applicantType === "Individual" ? (
//                 <>
//                     <div className="grid grid-cols-2 gap-x-12 gap-y-9 pb-10 place-items-center">
//                         <SelectField
//                             value={data.qualification}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, qualification: value };
//                                 });
//                             }}
//                             label="Qualification"
//                             options={[
//                                 "Under Graduate",
//                                 "Graduate",
//                                 "Post Graduate",
//                                 "PhD",
//                                 "Chattered Accountant",
//                                 "Company Secretary",
//                                 "Doctor",
//                                 "Cost Accountant",
//                             ]}
//                         />
//                         <TextField
//                             value={data.dateOfBirth}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, dateOfBirth: value };
//                                 });
//                             }}
//                             label={"Date of Birth"}
//                             Icon={CgCalendarDates}
//                             type="date"
//                         />
//                         <RadioField
//                             value={data.gender}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, gender: value };
//                                 });
//                             }}
//                             label={"Gender"}
//                             Icon={BsGenderAmbiguous}
//                             options={["Male", "Female", "Transgender"]}
//                         />
//                         <SelectField
//                             value={data.residenceOwnershipStatus}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, residenceOwnershipStatus: value };
//                                 });
//                             }}
//                             label="Residence Ownership Status"
//                             options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]}
//                         />
//                     </div>
//                     <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-2 place-items-center">
//                         <TextField
//                             value={data.residentialPincode}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, residentialPincode: value };
//                                 });
//                             }}
//                             label={"Residence Pincode"}
//                             Icon={CgPassword}
//                         />
//                         <TextField
//                             value={data.city}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, city: value };
//                                 });
//                             }}
//                             label={"City"}
//                             Icon={FaCity}
//                         />
//                         <TextField
//                             value={data.state}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, state: value };
//                                 });
//                             }}
//                             label={"State"}
//                             Icon={TbBuildingEstate}
//                         />
//                     </div>
//                 </>
//             ) : (
//                 <>
//                     <div className="grid grid-cols-2 gap-x-12 gap-y-9 pb-10 place-items-center">
//                         <TextField
//                             value={data.dateOfIncorporation}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, dateOfIncorporation: value };
//                                 });
//                             }}
//                             label={"Date of Incorporation"}
//                             Icon={CgCalendarDates}
//                             type="date"
//                         />
//                         <SelectField
//                             value={data.businessPremisesOwnership}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, businessPremisesOwnership: value };
//                                 });
//                             }}
//                             label="Business Premises Ownership"
//                             options={["Owned by Self or Spouse", "Rented"]}
//                         />
//                     </div>
//                     <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-2 place-items-center">
//                         <TextField
//                             value={data.businessPincode}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, businessPincode: value };
//                                 });
//                             }}
//                             label={"Business Pincode"}
//                             Icon={CgPassword}
//                         />
//                         <TextField
//                             value={data.city}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, city: value };
//                                 });
//                             }}
//                             label={"City"}
//                             Icon={FaCity}
//                         />
//                         <TextField
//                             value={data.state}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, state: value };
//                                 });
//                             }}
//                             label={"State"}
//                             Icon={TbBuildingEstate}
//                         />
//                     </div>
//                     <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-8 place-items-center">
//                         <TextField
//                             value={data.annualTurnOver}
//                             setValue={(value) => {
//                                 setData((prev) => {
//                                     return { ...prev, annualTurnOver: value };
//                                 });
//                             }}
//                             label={"Annual TernOver"}
//                             Icon={TbBuildingEstate}
//                         />
//                     </div>
//                 </>
//             )}
//             <div className="flex gap-5 mt-10">
//                 <button
//                     onClick={prevPage}
//                     className="text-[18px] text-black underline duration-500"
//                 >
//                     Back
//                 </button>
//                 <button
//                     onClick={nextPage}
//                     className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
//                 >
//                     Next
//                 </button>
//             </div>
//         </div>
//     );
// };


export const Page3 = ({ data, setData, prevPage, nextPage, hendleSubmit }) => {
    const [errorCheck, setErrorCheck] = useState(false);
    return (
        <div>
            <div className="flex flex-col gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.
                </h1>
                <p className="text-[24px] text-[#452979]">
                    Keep going you are about to complete your journey.
                </p>
            </div>
            <div className="pb-10">
                <StepIndicator step={3} total={3} />
            </div>
            {data.applicantType === "Individual" ? (
                <div className="grid grid-cols-2 gap-x-12 gap-y-9 place-items-center">

                    <SelectField
                        errorCheck={errorCheck}
                        errorMessage={"Please select Entitiy Tpye "}
                        value={data.entityType}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, entityType: value };
                            });
                        }}
                        label="Entity Type"
                        options={["Owned by Self or Spouse", "Rented"]}
                    />
                    <SelectField
                        errorCheck={errorCheck}
                        errorMessage={"Please select Business Premises Ownership "}
                        value={data.businessPremisesOwnership}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, businessPremisesOwnership: value };
                            });
                        }}
                        label="Business Premises Ownership"
                        options={["Owned by Self or Spouse", "Rented"]}
                    />

                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"Please enter Annual Receipt "}
                        value={data.annualReceipt}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, annualReceipt: value };
                            });
                        }}
                        label={"Annual TernOver"}
                        Icon={TbBuildingEstate}
                        type="number"
                    />

                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"Please enter a valid Practicing Date "}
                        value={data.practicingSince}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, practicingSince: value };
                            });
                        }}
                        label={"Practicing Since"}
                        Icon={TbBuildingEstate}
                    />






                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"Enter a valid Membership Number"}
                        value={data.membershipNumber}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, membershipNumber: value };
                            });
                        }}
                        label={"Number of Membership Number"}
                        Icon={CiMobile3}
                    />
                </div>
            ) : (
                <div className="grid grid-cols-2 gap-x-12 gap-y-9 place-items-center">


                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"please enter First Name of Proprietor"}
                        label={"First Name of Proprietor"}
                        type="number"
                        value={data.firstName}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, firstName: value };
                            });
                        }}
                        Icon={BsPerson}
                    />


                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"please enter Last Name of Proprietor"}
                        value={data.lastName}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, lastName: value };
                            });
                        }}
                        label={"Last Name of Proprietor"}
                        Icon={CgCalendarDates}
                    />

                    {/* Date of Birth of Proprietor */}

                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"please Select valid Date of Birth"}
                        value={data.dateOfBirth}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, dateOfBirth: value };
                            });
                        }}
                        label={"Date of Birth of Proprietor"}
                        Icon={CgCalendarDates}
                        type="date"
                    />

                    <RadioField
                        errorCheck={errorCheck}
                        errorMessage={"please Select Gender"}
                        value={data.gender}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, gender: value };
                            });
                        }}
                        label={"Gender"}
                        Icon={BsGenderAmbiguous}
                        options={["Male", "Female", "Transgender"]}
                    />
                    <SelectField
                        errorCheck={errorCheck}
                        errorMessage={"please Select Residence Ownership Status"}
                        value={data.residenceOwnershipStatus}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, residenceOwnershipStatus: value };
                            });
                        }}
                        label="Residence Ownership Status"
                        options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]}
                    />

                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"Enter a valid Membership Number"}
                        value={data.membershipNumber}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, membershipNumber: value };
                            });
                        }}
                        label={"Number of Membership Number"}
                        Icon={CiMobile3}
                    />
                </div>
            )}

            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {
                        if (
                            data.applicantType === "Individual" &&
                            data.entityType !== "" &&
                            data.businessPremisesOwnership !== "" &&
                            data.annualReceipt !== "" &&
                            data.practicingSince !== "" &&
                            data.membershipNumber !== ""

                        ) {
                            setErrorCheck(false);
                            nextPage();
                            hendleSubmit(data);
                        }
                        else if (
                            data.applicantType === "Non-individual" &&
                            data.firstName !== "" &&
                            data.lastName !== "" &&
                            data.dateOfBirth !== "" &&
                            data.gender !== "" &&
                            data.residenceOwnershipStatus !== "" &&
                            data.membershipNumber !== ""
                        ) {
                            setErrorCheck(false);
                            nextPage();
                            hendleSubmit(data);
                        }
                        else {
                            setErrorCheck(true);

                        }
                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

const ProfessionalLaon = () => {
    let navigation = useNavigate();
    const [data, setData] = useState({
        applicantType: "",

        profession: "",

        firstName: "",
        firmName: "",
        lastName: "",
        constitution: "",
        mobile: "",
        verifyOtp: "",
        email: "",
        panNumber: "",
        loanAmount: 0,
        tenure: "",

        //page 3
        // for individual
        entityType: "",
        annualReceipt: 0,
        practicingSince: "",
        numberOfPartners: "",

        //for firm/compony
        // residenceOwnershipStatus: "",




        loanPurpose: "",
        usage: "",
        residentialPincode: "",
        businessPincode: "",
        city: "",
        state: "",
        dateOfBirth: "",
        gender: "",
        residenceOwnershipStatus: "",
        employmentType: "",
        businessType: "",
        businessVintage: "",
        occupation: "",
        employerName: "",
        qualification: "",
        workExperience: "",
        subOccupation: "",
        professionType: "",
        businessPremisesOwnership: "",
        dateOfIncorporation: "",
        annualTurnOver: "",



        membershipNumber: ""
    });
    const [page, setPage] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loader, setLoader] = useState(false);
    const nextPage = () => {
        if (page < Pages.length) {
            setPage(page + 1);
        } else {
            // handleSubmit();
            console.log("not")
        }
    };
    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    // const [loader,setLoader]=useState(true);

    const navigate=useNavigate()


    const hendleSubmit = (data) => {
        setLoader(true);
        if (data.applicantType) {
            let proObj = {}
            if (data.applicantType === "Individual") {
                proObj["applicantType"] = data.applicantType
                proObj["selectProfession"] = data.profession
                proObj["firstName"] = data.firstName
                proObj["lastName"] = data.lastName
                proObj["mobile"] = data.mobile
                proObj["opt"] = data.verifyOtp
                proObj["email"] = data.email
                proObj["panNumber"] = data.panNumber
                proObj["loanAmount"] = Number(data.loanAmount)
                proObj["tenure"] = data.tenure
                proObj["dateOfBirth"] = data.dateOfBirth
                proObj["residenceOwnershipStatus"] = data.residenceOwnershipStatus
                proObj["gender"] = data.gender
                proObj["pincode"] = data.residentialPincode
                proObj["city"] = data.city
                proObj["state"] = data.state
                proObj["entityType"] = data.entityType
                proObj["businessPremisesOwnership"] = data.businessPremisesOwnership
                proObj["annualReceipt"] = Number(data.annualReceipt)
                proObj["parcticingScine"] = data.practicingSince



                axios.post("https://activ-paisa-server.vercel.app/api/loans/professional-application", {
                    ...proObj
                }).then(() => {
                    // console.log(proObj);
                    setLoader(false);
                    navigate("/thankyou");
                }).catch((error) => {
                    console.log(error);
                })

            }
            else if (data.applicantType === "Non-individual") {
                proObj["applicantType"] = data.applicantType
                proObj["selectProfession"] = data.profession
                proObj["companyName"] = data.firmName
                proObj["constitution"] = data.constitution
                proObj["mobile"] = data.mobile
                proObj["opt"] = data.verifyOtp
                proObj["email"] = data.email
                proObj["panNumber"] = data.panNumber
                proObj["loanAmount"] = 20000
                proObj["tenure"] = 24
                proObj["dateOfIncorporation"] = data.dateOfIncorporation
                proObj["businessPremisesOwnership"] = data.businessPremisesOwnership
                proObj["pincode"] = data.businessPincode
                proObj["city"] = data.city
                proObj["state"] = data.state
                proObj["firstName"] = data.firstName
                proObj["lastName"] = data.lastName
                proObj["dateOfBirth"] = data.dateOfBirth
                proObj["gender"] = data.gender
                proObj["residenceOwnershipStatus"] = data.residenceOwnershipStatus
                proObj["membershipNumber"] = data.membershipNumber



                axios.post("https://activ-paisa-server.vercel.app/api/loans/professional-application", {
                    ...proObj
                }).then(() => {
                    // console.log(proObj, "sent");
                    setLoader(false);
                    navigate("/thankyou");
                }).catch((error) => {
                    console.log(error);
                    setLoader(false)
                })
            }
            else {
                console.log("no")
                setLoader(false);
            }
        }
        else {
            console.log("type");
            setLoader(false)

        }
    }


    const Pages = [
        <Page1 data={data} setData={setData} nextPage={nextPage} />,

        <Page2
            data={data}
            setData={setData}
            prevPage={prevPage}
            nextPage={nextPage}
        />,
        <Page3
            data={data}
            setData={setData}
            prevPage={prevPage}
            nextPage={nextPage}
            hendleSubmit={hendleSubmit}
        />,

    ];

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // console.log(data)
    return <FormTempelate isSubmitted={isSubmitted} image={require("../../assets/Loans/carBanner.jpg")}>{Pages[page - 1]}</FormTempelate>;
}


export default ProfessionalLaon