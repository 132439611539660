import React, { useEffect, useState } from 'react'

const RadioField = ({ label, Icon, options = [], value = "", setValue = () => { }, errorCheck, errorMessage, validationMessage }) => {
    const [error, setError] = useState(false);
    
    useEffect(() => {
        if (errorCheck && value === "") {
            setError(true);
        } else {
            setError(false);
        }
    }, [errorCheck]);

    return (
        <div className='w-full relative flex gap-2 items-center'>
            {Icon({
                size: 30,
                className: "text-gray-500"
                // className: `${style ? "text-[#452979]" : "text-gray-400"}`
            })}
            <div className='flex gap-3 justify-between items-center'>
                <label className='text-gray-500 text-[18px] font-thin'>
                    {label}:
                </label>
                {
                    options.map((item, index) => {
                        return (
                            <div key={index} className='flex gap-1 items-center'>
                                <input type='radio' checked={String(value).toLowerCase() === String(item).toLowerCase()} onChange={(e) => {
                                    if (e.target.checked) {
                                        setValue(String(item).toLowerCase());
                                    }
                                }} name={label} id={item} />
                                <label className='text-[#452979] text-[18px] font-thin' htmlFor={item}>{item}</label>
                            </div>
                        )
                    })
                }
            </div>
            <div className=" absolute text-xs mt-1 top-8 flex gap-4 h-fit justify-between">
                <p className="text-[red]  ml-2 font-thin">{error && errorMessage}</p>
                <p className="text-[#452979]">{validationMessage}</p>
            </div>
        </div>
    )
}

export default RadioField;