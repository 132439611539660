import React, { useEffect, useLayoutEffect, useState } from 'react'
import FormTempelate from './FormTempelate'
import { BsPerson, BsCreditCard2Front } from 'react-icons/bs';
import { CiMobile3 } from 'react-icons/ci';
import { RiQuestionAnswerLine } from 'react-icons/ri';
import { AiOutlineMail } from 'react-icons/ai';
import { GiTakeMyMoney } from 'react-icons/gi';
import { CgCalendarDates } from 'react-icons/cg';
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import { BsBuilding } from 'react-icons/bs';
import { CgPassword } from 'react-icons/cg';
import { FaCity } from 'react-icons/fa';
// import { FaMountainCity } from 'react-icons/fa';
import StepIndicator from '../Global/StepIndicator';
import SelectField from './Fields/SelectField';
import TextField from './Fields/TextField';
import RadioField from './Fields/RadioField';
import axios from 'axios';
import { useNavigate } from 'react-router';
// import RadioField from './Fields/RadioField';



export const Page1 = ({ data, setData, nextPage }) => {

    const [selected, setSelected] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [errorCheck, setErrorCheck] = useState(false);

    return (
        <div>
            <div className='flex flex-col gap-1 py-10'>
                <h1 className='text-[28px] font-semibold text-[#452979]'>Great choice! Now soar new heights with our Business Loan.</h1>
                <p className='text-[24px] text-[#452979]'>Now take the first step to fulfil your business goals.</p>
            </div>
            <div className='pb-10'>
                <StepIndicator step={1} total={3} />
            </div>
            <div className='grid grid-cols-2 gap-x-12 gap-y-12 place-items-center'>
                <TextField errorCheck={errorCheck} label={"Business Name"} value={data.businessName} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, businessName: value }
                    });
                }} errorMessage={"Enter a Business Name"} Icon={BsPerson} />
                <SelectField errorCheck={errorCheck} value={data.entityType} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, entityType: value }
                    });
                }} label="Entity Type" errorMessage={"Select business type"} options={["Proprietorship", "Partnership", "Limited Liability Partnership", "Private Limited Company", "Public Limited Company", "One Person Company"]} />
                <TextField errorCheck={errorCheck} type='number' value={data.number} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, number: value }
                    });
                }} label={"Number"} errorMessage={"Enter a valid number"} Icon={CiMobile3} />
                {/* <TextField type='number' value={data.verifyOtp} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, verifyOtp: value }
                    });
                }} label={"Verify Otp"} Icon={RiQuestionAnswerLine} /> */}
                <TextField errorCheck={errorCheck} value={data.email} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, email: value }
                    });
                }} label={"Email"} errorMessage={"Enter a valid email Id"} Icon={AiOutlineMail} />
                <TextField errorCheck={errorCheck} value={data.businessPanNumber} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, businessPanNumber: value }
                    });
                }} label={"PAN Number"} type='email' errorMessage={"Enter pan as per entity selected"} Icon={BsCreditCard2Front} />
                <TextField errorCheck={errorCheck} type='number' value={data.loanAmount} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, loanAmount: value }
                    });
                }} label={"Loan Amount"} errorMessage={"Enter loan amount"} Icon={GiTakeMyMoney} />
                <SelectField errorCheck={errorCheck} value={data.tenure} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, tenure: value }
                    });
                }} label="Tenure" errorMessage={"Please select tenure"} options={["1 Year", "2 Years", "3 Years","4 Years","5 Years"]} />
            </div>
            <div className='mt-10 flex gap-3 items-center'>
                <input onChange={(e) => {
                    setSelected(e.target.checked);
                }} type='checkbox' id='proceedingConditions' className='border border-[#452979] outline-none checked:bg-[#452979] checked:after:text-white' />
                <label htmlFor='proceedingConditions' className='text-gray-400 text-xs'>By proceeding I agree to <span className='text-blue-700 underline'>Terms and Conditions</span> and have read/understood <span className='text-blue-700 underline'>approach for gradation of risk.</span></label>
            </div>
            {termsError && <p className='font-thin text-sm text-[red]'>Select Agree to Terms & Conditions</p>}
            <div className='mt-10'>
                <button onClick={() => {
                    if (data.businessName !== "" && data.entityType !== "" && data.number !== "" && data.email !== "" && data.businessPanNumber !== "" && data.loanAmount !== "" && data.tenure !== "") {
                        setErrorCheck(false);
                        if (selected) {
                            setTermsError(false);
                            nextPage();
                        } else {
                            setTermsError(true);
                        }
                    } else {
                        setErrorCheck(true);
                    }
                }} className='rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500'>Next</button>
            </div>
        </div>
    );
}
export const Page2 = ({ data, setData, prevPage, nextPage }) => {

    console.log(data.businessPincode);
    useEffect(()=>{

        if(data.businessPincode=="134003"){
            setData((prev=>{
                return{...prev,city:"Ambala"}
            }))

            setData((pre)=>{
                return{...pre,state:"Haryana"}
            })
        }
        
        

        if(data.businessPincode==="400001"){
            setData((prev=>{
                return{...prev,city:"Mumbai"}
            }))

            setData((pre)=>{
                return{...pre,state:"Maharashtra"}
            })
        }
        


        // axios.get("https://www.postpincode.in/api/getCityName.php?pincode=144001")
        // .then((res)=>{
        //     console.log(res);
        // })
        // .catch((e)=>{
        //     console.log(e);
        // })
    },[data.businessPincode])

    const [errorCheck, setErrorCheck] = useState(false);

    return (
        <div>
            <div className='flex flex-col gap-1 py-10'>
                <h1 className='text-[28px] font-semibold text-[#452979]'>Great choice! Now soar new heights with our Business Loan.</h1>
                <p className='text-[24px] text-[#452979]'>Keep going you are almost there.</p>
            </div>
            <div className='pb-10'>
                <StepIndicator step={2} total={3} />
            </div>

            <div className='grid grid-cols-2 gap-x-12 gap-y-12 pb-10 place-items-center'>
                <SelectField errorCheck={errorCheck} value={data.natureOfBusiness} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, natureOfBusiness: value }
                    });
                }} label="Nature Of Business" errorMessage={"Please select Nature of Business"} options={["Manufacturing", "Service", "Trading"]} />
                <TextField errorCheck={errorCheck} value={data.dateOfIncorporation} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, dateOfIncorporation: value }
                    });
                }} label={"Date of Incorporation"} errorMessage={"Please select date of icorporation"} Icon={CgCalendarDates} type='date' />
                <SelectField errorCheck={errorCheck} value={data.businessPremisesOwnership} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, businessPremisesOwnership: value }
                    });
                }} Icon={BsBuilding} label="Business Premises Ownership" errorMessage={"Please select Business Premises ownership"} options={["Owned", "Rented"]} />
                <TextField type='number' errorCheck={errorCheck} value={data.annualTurnover} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, annualTurnover: value }
                    });
                }} label={"Annual Turnover"} errorMessage={"Please tell annual turnover"} Icon={HiOutlineCurrencyRupee} />
            </div>
            <div className='grid grid-cols-3 gap-x-12 gap-y-12 mt-8 place-items-center'>
                <TextField errorCheck={errorCheck} value={data.businessPincode} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, businessPincode: value }
                    });
                }} label={"Business Pincode"} errorMessage={"Please enter business pincode"} Icon={CgPassword} />
                <TextField errorCheck={errorCheck} value={data.city} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, city: value }
                    });
                }} label={"City"} errorMessage={"Enter city"} Icon={FaCity} />
                <TextField errorCheck={errorCheck} value={data.state} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, state: value }
                    });
                }} label={"State"} errorMessage={"Enter state"} Icon={CiMobile3} />
            </div>
            <div className='flex gap-5 mt-10'>
                <button onClick={prevPage} className='text-[18px] text-black underline duration-500'>Back</button>
                <button onClick={() => {
                    if (data.natureOfBusiness !== "" && data.dateOfIncorporation !== "" && data.businessPremisesOwnership !== "" && data.annualTurnover !== "" && data.businessPincode !== "" && data.city !== "" && data.state !== "") {
                        setErrorCheck(false);
                        nextPage();
                    } else {
                        setErrorCheck(true);
                    }
                }} className='rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500'>Next</button>
            </div>
        </div>
    );
}
export const Page3 = ({ data, setData, prevPage, nextPage, apiClick }) => {

    const [errorCheck, setErrorCheck] = useState(false);

    return (
        <div>
            <div className='flex flex-col gap-1 py-10'>
                <h1 className='text-[28px] font-semibold text-[#452979]'>Great choice! Now soar new heights with our Business Loan.</h1>
                <p className='text-[24px] text-[#452979]'>Enter your Personal details & you're done!.</p>
            </div>
            <div className='pb-10'>
                <StepIndicator step={3} total={3} />
            </div>

            <div className='grid grid-cols-2 gap-x-12 gap-y-12'>
                <TextField errorCheck={errorCheck} value={data.userfirstName} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, userfirstName: value }
                    });
                }} label={"First Name"} errorMessage={"Enter Firstname"} Icon={BsPerson} />
                <TextField errorCheck={errorCheck} value={data.lastName} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, lastName: value }
                    });
                }} label={"Last Name"} errorMessage={"Enter Lastname"} Icon={BsPerson} />
                <TextField errorCheck={errorCheck} value={data.dateOfBirth} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, dateOfBirth: value }
                    });
                }} label={"Date of Birth"} errorMessage={"Enter your date of birth"} Icon={CgCalendarDates} type='date' />
                <TextField errorCheck={errorCheck} value={data.userpanNumber} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, userpanNumber: value }
                    });
                }} label={"PAN Number"} errorMessage={"Enter Pan Number"} Icon={HiOutlineCurrencyRupee} />
                <RadioField errorCheck={errorCheck} value={data.gender} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, gender: value }
                    });
                }} label={"Gender"} errorMessage={"Please select your gender"} Icon={HiOutlineCurrencyRupee} options={["Male", "Female", "Transgender"]} />
                <SelectField errorCheck={errorCheck} value={data.residenceOwnershipStatus} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, residenceOwnershipStatus: value }
                    });
                }} errorMessage={"Please select Residence Ownership Status"} label={"Residence Ownership Status"} options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]} />
            </div>
            <div className='flex gap-5 mt-10'>
                <button onClick={prevPage} className='text-[18px] text-black underline duration-500'>Back</button>
                <button onClick={() => {
                    if (data.userfirstName !== "" && data.lastName !== "" && data.dateOfBirth !== "" && data.userpanNumber !== "" && data.gender !== "" && data.residenceOwnershipStatus !== "") {
                        setErrorCheck(false);
                        nextPage();
                        apiClick(data);
                    } else {
                        setErrorCheck(true);
                    }
                }} className='rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500'>Submit</button>
            </div>
        </div>
    );
}


export default function BusinesLoan() {

    // let navigation = useNavigate();
    const [data, setData] = useState({
        businessName: '',
        entityType: '',
        number: '',
        verifyOtp: '',
        businessPanNumber: '',
        loanAmount: '',
        tenure: '',
        natureOfBusiness: '',
        dateOfIncorporation: '',
        businessPremisesOwnership: '',
        annualTurnover: '',
        businessPincode: '',
        city: '',
        state: '',
        userfirstName: '',
        lastName: '',
        dateOfBirth: '',
        userpanNumber: '',
        gender: '',
        residenceOwnershipStatus: ''
    });
    const [page, setPage] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loader, setLoader] = useState(false);
    const nextPage = () => {
        if (page < Pages.length) {
            setPage(page + 1);
        }
    }
    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    // const handleSubmit = () => {
    //     const scriptUrl = "https://script.google.com/macros/s/AKfycbwC6QWkHqGU3MzdJezOmOCtTvfKHAJcZvG00pe5cDWhl_CQqlBRxWWdqF1xjYtGdiN6/exec";
    //     const formData = new FormData();

    //     if (!loader) {
    //         setLoader(true);
    //         for (let key in data) {
    //             formData.append(key, data[key]);
    //         }

    //         console.log(data);

    //         fetch(scriptUrl, { method: 'POST', body: formData })
    //             .then(response => {
    //                 setIsSubmitted(true);
    //                 setLoader(false);
    //             })
    //             .catch(error => {
    //                 setLoader(false);
    //             })
    //     }
    // }

    const navigate=useNavigate();

    const apiClick = (data) => {
        setLoader(true);
        let proObj = {};
        // if (loader) {
            proObj["businessName"] = data.businessName;
            proObj["entityType"] = data.entityType;
            proObj["mobile"] = Number(data.number)
            proObj["otp"] = 0;
            proObj["email"] = data.email;
            proObj["panNumber"] = data.businessPanNumber;
            proObj["loanAmount"] = 0
            proObj["tenure"] = 0
            proObj["natureOfBusiness"] = data.natureOfBusiness;
            proObj["dateOfIncorporation"] = data.dateOfIncorporation;
            proObj["businessPremisesOwnership"] = data.businessPremisesOwnership;
            proObj["annualTurnover"] = 0
            proObj["pincode"] = 0
            proObj["city"] = data.city;
            proObj["state"] = data.state;
            proObj["firstName"] = data.userfirstName;
            proObj["lastName"] = data.lastName;
            proObj["dateOfBirth"] = data.dateOfBirth;
            proObj["gender"] = data.gender;
            proObj["residenceOwnershipStatus"] = data.residenceOwnershipStatus;

            // console.log(proObj);


            axios.post("https://activ-paisa-server.vercel.app/api/loans/business-application", {
                ...proObj
            }).then(() => {
                setLoader(false);
                // console.log(proObj);
                navigate("/thankyou")

            }).catch((error) => {
                console.log(error);
                setLoader(false);
            })
        // }

        // else {
        //     console.log("aaiyna")
        //     setLoader(false);
        // }


    }




    const Pages = [
        <Page1 data={data} setData={setData} nextPage={nextPage} />,
        <Page2 data={data} setData={setData} prevPage={prevPage} nextPage={nextPage} />,
        <Page3 data={data} setData={setData} prevPage={prevPage} nextPage={nextPage}  apiClick={apiClick} />
    ];

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FormTempelate isSubmitted={isSubmitted} image={require("../../assets/Loans/business-loan.jpg")}>
            {Pages[page - 1]}
        </FormTempelate>
    );
} 