import React, { useEffect, useLayoutEffect, useState } from 'react'
import FormTempelate from './FormTempelate'
import { BsPerson, BsCreditCard2Front } from 'react-icons/bs';
import { CiMobile3 } from 'react-icons/ci';
import { RiQuestionAnswerLine } from 'react-icons/ri';
import { AiOutlineMail } from 'react-icons/ai';
import { GiTakeMyMoney } from 'react-icons/gi';
import StepIndicator from '../Global/StepIndicator';
import SelectField from './Fields/SelectField';
import TextField from './Fields/TextField';
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import RadioField from './Fields/RadioField';
import axios from 'axios';
import { useNavigate } from 'react-router';

function Page1({ nextPage, data, setData }) {
    const [selected, setSelected] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [errorCheck, setErrorCheck] = useState(false);
    return (
        <div>
            <div className='flex flex-col gap-1 py-10'>
                <h1 className='text-[28px] font-semibold text-[#452979]'>Great choice! Now soar new heights with our Personal Loan.</h1>
                <p className='text-[24px] text-[#452979]'>Now take the first step to fulfil your Personal goals.</p>
            </div>
            <div className='pb-10'>
                <StepIndicator step={1} total={4} />
            </div>
            <div className='grid grid-cols-2 place-items-center gap-x-8 gap-y-12'>

                <TextField
                    label={"First Name"}
                    errorCheck={errorCheck}
                    value={data.firstName}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, firstName: value };
                        });
                    }}
                    errorMessage={"Enter First Name"}
                    Icon={BsPerson}
                />


                <TextField label={"Last Name"} errorCheck={errorCheck} value={data.lastName}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, lastName: value }
                        });
                    }} errorMessage={"Enter a valid Last Name"} Icon={BsPerson} />


                <TextField errorCheck={errorCheck} type='number' value={data.number} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, number: value }
                    });
                }} label={"Moblie Number"} errorMessage={"Enter a valid Mobile Number"} Icon={CiMobile3} />


                <TextField errorCheck={errorCheck} type='number' value={data.verifyOtp} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, verifyOtp: value }
                    });
                }} label={"OTP"} errorMessage={"Enter a valid OTP"} Icon={CiMobile3} />


                <TextField errorCheck={errorCheck} value={data.email} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, email: value }
                    });
                }} label={"Email"} errorMessage={"Enter a valid email Id"} Icon={AiOutlineMail} />


                <TextField errorCheck={errorCheck} value={data.panNumber} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, panNumber: value }
                    });
                }} label={"PAN Number"} type='email' errorMessage={"Enter pan as per entity selected"} Icon={BsCreditCard2Front} />

                <TextField errorCheck={errorCheck} type='number' value={data.loanAmount} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, loanAmount: value }
                    });
                }} label={"Loan Amount"} errorMessage={"Enter loan amount"} Icon={GiTakeMyMoney} />

                <SelectField errorCheck={errorCheck} value={data.tenure} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, tenure: value }
                    });
                }} label="Tenure" errorMessage={"Please select tenure"} options={["1 Year", "2 Years", "3 Years","4 Years","5 Years"]} />
            </div>
            <div className='mt-10 flex gap-3 items-center'>
                <input onChange={(e) => {
                    setSelected(e.target.checked);
                }} type='checkbox' id='proceedingConditions' className='border border-[#452979] outline-none checked:bg-[#452979] checked:after:text-white' />
                <label htmlFor='proceedingConditions' className='text-gray-400 text-xs'>By proceeding I agree to <span className='text-blue-700 underline'>Terms and Conditions</span> and have read/understood <span className='text-blue-700 underline'>approach for gradation of risk.</span></label>
            </div>
            <div className='mt-10'>
                <button onClick={() => {
                    if (data.firstName !== "" &&
                        data.lastName !== "" &&
                        data.number !== "" &&
                        data.verifyOtp !== "" &&
                        data.email !== "" &&
                        data.panNumber !== "" &&
                        data.loanAmount !== "" &&
                        data.tenure !== ""
                    ) {
                        setErrorCheck(false);
                        if (selected) {
                            setTermsError(false);
                            nextPage()
                        } else {
                            setTermsError(true);
                        }
                    } else {
                        setErrorCheck(true);
                        console.log("sda3")
                    }
                }} className='rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500'>Next</button>
            </div>
        </div>
    );
}
function Page2({ data, setData, nextPage, prevPage }) {
    const [errorCheck, setErrorCheck] = useState(false);
    return (
        <div>
            <div className='flex flex-col gap-1 py-10'>
                <h1 className='text-[28px] font-semibold text-[#452979]'>Congratulations! You've chosen the best Personal Loan.</h1>
                <p className='text-[24px] text-[#452979]'>Keep going! You're almost there</p>
            </div>
            <div className='pb-10'>
                <StepIndicator step={2} total={4} />
            </div>
            <div className='grid grid-cols-2 place-items-center gap-x-12 gap-y-12 pb-10'>
                <SelectField label={"Qualification"} Icon={BsPerson} options={["Under graduate", "Graduate", "Post Graduate", "PhD", "Chartered Accountant", "Company Secretary", "Doctor"]}
                    errorCheck={errorCheck}
                    value={data.qualification}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, qualification: value };
                        });
                    }}
                    errorMessage={"Please Select a Qualification"}
                />
                <TextField label={"Date of Birth"} Icon={BsPerson}
                    errorCheck={errorCheck}
                    value={data.dateOfBirth}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, dateOfBirth: value };
                        });
                    }}
                    errorMessage={"Enter a valid dateOfBirth"}
                    type='date'
                />
                <RadioField errorCheck={errorCheck} value={data.gender} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, gender: value }
                    });
                }} label={"Gender"} errorMessage={"Please select your gender"} Icon={HiOutlineCurrencyRupee} options={["Male", "Female", "Transgender"]} />
            </div>
            <div className='grid grid-cols-2 place-items-center gap-x-12 gap-y-12 pb-10'>
                <TextField label={"Address"} Icon={BsPerson}
                    errorCheck={errorCheck}
                    value={data.address}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, address: value };
                        });
                    }}
                    errorMessage={"Enter Address"} />
                <SelectField label={"Residence Status"} options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]}
                    errorCheck={errorCheck}
                    value={data.residenceStatus}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, residenceStatus: value };
                        });
                    }}
                    errorMessage={"Plese Select Residence Status"}
                />
            </div>
            <div className='grid grid-cols-3 place-items-center gap-x-12 gap-y-12'>
                <TextField label={"Pincode"} Icon={BsPerson} errorCheck={errorCheck}
                    value={data.pincode}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, pincode: value };
                        });
                    }}
                    errorMessage={"Please enter a valid Pincode"} />
                <TextField label={"City"} Icon={BsPerson}
                    errorCheck={errorCheck}
                    value={data.city}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, city: value };
                        });
                    }}
                    errorMessage={"Please enter City"} />
                <TextField label={"State"} Icon={BsPerson} errorCheck={errorCheck}
                    value={data.state}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, state: value };
                        });
                    }}
                    errorMessage={"Please enter State"} />
            </div>
            {/* <div className='mt-10 flex gap-4 items-center'>
                <input type='checkbox' id='proceedingConditions' className='border border-[#452979] outline-none checked:bg-[#452979] checked:after:text-white' />
                <label htmlFor='proceedingConditions' className='text-gray-400'>By proceeding I agree to Terms and Conditions and have read/understood approach for gradation of risk.</label>
            </div> */}
            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {

                        if (
                            data.qualification !== "" &&
                            data.dateOfBirth !== "" &&
                            data.gender !== "" &&
                            data.address !== "" &&
                            data.residenceStatus !== "" &&
                            data.pincode !== "" &&
                            data.city !== "" &&
                            data.state !== ""

                        ) {
                            setErrorCheck(false);
                            nextPage();
                            console.log(data);
                        }
                        else {
                            setErrorCheck(true);
                        }
                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

function Page3({ data, setData, prevPage, nextPage }) {
    const [errorCheck, setErrorCheck] = useState(false);
    return (
        <div>
            <div className='flex flex-col gap-1 py-10'>
                <h1 className='text-[28px] font-semibold text-[#452979]'>Great choice! Now soar new heights with our Business Loan.</h1>
                <p className='text-[24px] text-[#452979]'>Now take the first step to fulfil your business goals.</p>
            </div>
            <div className='pb-10'>
                <StepIndicator step={3} total={4} />
            </div>
            <div className='grid grid-cols-2 place-items-center gap-x-8 gap-y-12'>
                <TextField label={"Enter Employer Name"} Icon={BsPerson}
                    errorCheck={errorCheck}
                    value={data.employerName}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, employerName: value };
                        });
                    }}
                    errorMessage={"Please enter Employer Name"}
                />
                <TextField type='number' label={"Total Experience"} Icon={BsPerson} validationMessage={"(Min 2 year)"}
                    errorCheck={errorCheck}
                    value={data.totalExperience}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, totalExperience: value };
                        });
                    }}
                    errorMessage={"Please ente your Total Experience"} />
                <TextField type='number' label={"Net Monthly Salary"} Icon={BsPerson} validationMessage={"(Minimum Salary ₹30000)"}
                    errorCheck={errorCheck}
                    value={data.netMonthlySalary}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, netMonthlySalary: value };
                        });
                    }}
                    errorMessage={"Please enter Net Monthly Salary"} />
            </div>
            {/* <div className='mt-10 flex gap-4 items-center'>
                <input type='checkbox' id='proceedingConditions' className='border border-[#452979] outline-none checked:bg-[#452979] checked:after:text-white' />
                <label htmlFor='proceedingConditions' className='text-gray-400'>By proceeding I agree to Terms and Conditions and have read/understood approach for gradation of risk.</label>
            </div> */}
            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {

                        if (
                            data.employerName !== "" &&
                            data.totalExperience !== "" &&
                            data.netMonthlySalary !== ""

                        ) {
                            setErrorCheck(false);
                            nextPage();
                            console.log(data);
                        }
                        else {
                            setErrorCheck(true);
                        }
                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

function Page4({ data, prevPage, setData, nextPage,hendleSubmit }) {
    return (
        <div>
            <div className='flex flex-col gap-1 py-10'>
                <h1 className='text-[28px] font-semibold text-[#452979]'>Great choice! Now soar new heights with our Business Loan.</h1>
                <p className='text-[24px] text-[#452979]'>Now take the first step to fulfil your business goals.</p>
            </div>
            <div className='pb-10'>
                <StepIndicator step={4} total={4} />
            </div>
            <div>
                <h1>Comming Soon...</h1>
            </div>
            {/* <div className='grid grid-cols-2 place-items-center gap-x-8 gap-y-12'>
                <TextField label={"Enter Employer Name"} Icon={BsPerson} />
                <TextField label={"Total Experience"} Icon={BsPerson} />
                <TextField label={"Net Monthly Salary"} Icon={BsPerson} />
            </div> */}
            {/* <div className='mt-10 flex gap-4 items-center'>
                <input type='checkbox' id='proceedingConditions' className='border border-[#452979] outline-none checked:bg-[#452979] checked:after:text-white' />
                <label htmlFor='proceedingConditions' className='text-gray-400'>By proceeding I agree to Terms and Conditions and have read/understood approach for gradation of risk.</label>
            </div> */}
            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {
                        // console.log(data);
                        hendleSubmit(data);

                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Submit
                </button>
            </div>
        </div>
    );
}

export default function PersonalLoan() {

    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        number: '',
        verifyOtp: '',
        email: '',
        panNumber: '',
        loanAmount: '',
        tenure: '',

        qualification: "",
        dateOfBirth: "",
        gender: "",
        address: "",
        residenceStatus: "",
        pincode: "",
        city: "",
        state: "",

        employerName: "",
        otherEmployerName: "",
        totalExperience: "",
        netMonthlySalary: "",

    });

    const [page, setPage] = useState(1);


    const nextPage = () => {
        if (page < Pages.length) {
            setPage(page + 1);
        } else {
            // handleSubmit();  
            console.log("hi")
        }
    }
    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    // const [loader,setLoader]=useState(true);

    const navigate=useNavigate();


    const hendleSubmit=(data)=>{
        let proObj={}

        proObj["firstName"]=data.firstName;
        proObj["lastName"]=data.lastName;
        proObj["mobile"]=data.number;
        proObj["otp"]=data.verifyOtp;
        proObj["email"]=data.email;
        proObj["panNumber"]=data.panNumber;
        proObj["loanAmount"]=Number(data.loanAmount);
        proObj["tenure"]=data.tenure;
        
        proObj["qualification"]=data.qualification;
        proObj["dateOfBirth"]=data.dateOfBirth;
        proObj["gender"]=data.gender;
        proObj["address"]=data.address;
        proObj["residenceStatus"]=data.residenceStatus;
        proObj["pincode"]=data.pincode;
        proObj["city"]=data.city;
        proObj["state"]=data.state;

        proObj["employerName"]=data.employerName;
        proObj["totalExperience"]=Number(data.totalExperience);
        proObj["netMonthlySalary"]=Number(data.netMonthlySalary);

        
        
        
        axios.post("https://activ-paisa-server.vercel.app/api/loans/personal-application",{
            ...proObj
        }).then(()=>{
            // console.log("done")
            // console.log(proObj);
            navigate("/thankyou");
        }).catch((e)=>{
            console.log(e);
        })



    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const Pages = [
        <Page1 data={data} setData={setData} nextPage={nextPage} />,
        <Page2 data={data} setData={setData} prevPage={prevPage} nextPage={nextPage} />,
        <Page3 data={data} setData={setData} prevPage={prevPage} nextPage={nextPage} />,
        <Page4 data={data} setData={setData} prevPage={prevPage} nextPage={nextPage} hendleSubmit={hendleSubmit} />
    ];

    return (
        <FormTempelate image={require("../../assets/Loans/business-loan.jpg")}>
            {Pages[page - 1]}
        </FormTempelate>
        // <FormTempelate>
        //     <Page1/>
        // </FormTempelate>
    );
}