import React, { useEffect, useState } from 'react';

export default function StepIndicator({ step, total = 1 }) {

    const [stepCount, setStepCount] = useState(0);

    useEffect(() => {
        if (step) {
            setStepCount(step);
        }
    }, [step]);

    return (
        <div className='flex w-full'>
            {
                [...Array(total).keys()].map((item, index) => (
                    <div className='flex items-center w-[max-content]'>
                        <div className={`w-9 h-9 text-center rounded-full border-[4px] grid place-items-center ${index + 1 <= stepCount ? "border-[#452979] text-[#452979]" : "text-gray-200"}`}>
                            <p className='w-[max-content] text-[16px] font-semibold h-[max-content]'>
                                {item + 1}
                            </p>
                        </div>
                        {
                            (index + 1 !== total) && <div className={`h-1 w-[70px] ${index + 2 <= stepCount ? "bg-[#452979]" : "bg-gray-200"}`}></div>
                        }
                    </div>
                ))
            }
        </div>
    )
}
