import React, { useEffect, useLayoutEffect, useState } from "react";
import FormTempelate from "./FormTempelate";
import { BsPerson, BsCreditCard2Front } from "react-icons/bs";
import { CiMobile3 } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbBuildingEstate } from "react-icons/tb";
import { CgCalendarDates } from "react-icons/cg";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { BsFillPersonLinesFill, BsGenderAmbiguous } from "react-icons/bs";
import { CgPassword } from "react-icons/cg";
import { FaCity, FaCarSide } from "react-icons/fa";
// import { FaMountainCity } from 'react-icons/fa';
import StepIndicator from "../Global/StepIndicator";
import SelectField from "./Fields/SelectField";
import TextField from "./Fields/TextField";
import RadioField from "./Fields/RadioField";
import { useNavigate } from "react-router";
import axios from "axios";

export const Page1 = ({ data, setData, nextPage }) => {
    const [selected, setSelected] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [errorCheck, setErrorCheck] = useState(false);

    return (
        <div>
            <div className="flex flex-col gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.
                </h1>
                <p className="text-[24px] text-[#452979]">
                    Now take the first step to fulfil your business goals.
                </p>
            </div>
            <div className="w-[50%]">
                <SelectField
                    errorCheck={errorCheck}
                    value={data.applicantType}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, applicantType: value };
                        });
                    }}
                    label="Applicant Type"
                    errorMessage={"Select Applicant type"}
                    options={["Individual", "Non-individual"]}
                />
            </div>
            {data.applicantType && (
                <div className="flex flex-col gap-1 py-5">
                    <div className="pb-10">
                        <StepIndicator step={1} total={4} />
                    </div>
                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 place-items-center">
                        {data.applicantType === "Individual" ? (
                            <TextField
                                errorCheck={errorCheck}
                                label={"First Name"}
                                value={data.firstName}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, firstName: value };
                                    });
                                }}
                                errorMessage={"Enter First Name"}
                                Icon={BsPerson}
                            />
                        ) : (
                            <TextField
                                errorCheck={errorCheck}
                                label={"Firm/Company Name"}
                                value={data.firmName}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, firmName: value };
                                    });
                                }}
                                errorMessage={"Enter Firm/Company Name"}
                                Icon={BsFillPersonLinesFill}
                            />
                        )}
                        {data.applicantType === "Individual" ? (
                            <TextField
                                errorCheck={errorCheck}
                                label={"Last Name"}
                                value={data.lastName}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, lastName: value };
                                    });
                                }}
                                errorMessage={"Enter Last Name"}
                                Icon={BsPerson}
                            />
                        ) : (
                            <SelectField
                                errorCheck={errorCheck}
                                value={data.constitution}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, constitution: value };
                                    });
                                }}
                                label="Enter Constitution"
                                errorMessage={"Enter Constitution"}
                                options={[
                                    "Individual",
                                    "Proprietorship",
                                    "Partnership",
                                    "Limited Liability Partnership",
                                    "Private Limited Company",
                                    "Public Limited Company",
                                    "Trust or Society",
                                    "Association of Person",
                                    "Government",
                                    "HUF",
                                    "Society",
                                    "Not Known",
                                ]}
                            />
                        )}
                        <TextField
                            errorCheck={errorCheck}
                            type="number"
                            value={data.number}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, number: value };
                                });
                            }}
                            label={"Number"}
                            errorMessage={"Enter a valid number"}
                            Icon={CiMobile3}
                        />
                        {/* <TextField type='number' value={data.verifyOtp} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, verifyOtp: value }
                    });
                }} label={"Verify Otp"} Icon={RiQuestionAnswerLine} /> */}
                        <TextField
                            errorCheck={errorCheck}
                            value={data.email}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, email: value };
                                });
                            }}
                            label={"Email"}
                            errorMessage={"Enter a valid email Id"}
                            Icon={AiOutlineMail}
                            type="email"
                        />
                        <TextField
                            errorCheck={errorCheck}
                            value={data.businessPanNumber}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, businessPanNumber: value };
                                });
                            }}
                            label={"PAN Number"}
                            errorMessage={"Enter pan as per entity selected"}
                            Icon={BsCreditCard2Front}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            type="number"
                            value={data.loanAmount}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, loanAmount: value };
                                });
                            }}
                            label={"Loan Amount"}
                            errorMessage={"Enter loan amount"}
                            Icon={GiTakeMyMoney}
                        />
                        <SelectField
                            errorCheck={errorCheck}
                            value={data.tenure}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, tenure: value };
                                });
                            }}
                            label="Tenure"
                            errorMessage={"Please select Tenure"}
                            options={[
                                "1 Year",
                                "2 Years",
                                "3 Years",
                                "4 Years",
                                "5 Years",
                            ]}
                        />
                        <SelectField
                            errorCheck={errorCheck}
                            value={data.usage}

                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, usage: value };
                                });
                            }}

                            label="Usage"
                            errorMessage={"Please select Usage"}
                            options={
                                data.applicantType == "Individual"
                                    ? ["Personal", "Commercial"]
                                    : ["Commercial"]
                            }
                        />
                        <SelectField
                            errorCheck={errorCheck}
                            value={data.loanPurpose}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, loanPurpose: value };
                                });
                            }}
                            label="Purpose of Loan"
                            errorMessage={"Please select Purpose of Loan"}
                            options={["Refinance", "Repurchase"]}
                        />
                    </div>
                    <div className="mt-10 flex gap-3 items-center">
                        <input
                            onChange={(e) => {
                                setSelected(e.target.checked);
                            }}
                            type="checkbox"
                            id="proceedingConditions"
                            className="border border-[#452979] outline-none checked:bg-[#452979] checked:after:text-white"
                        />
                        <label
                            htmlFor="proceedingConditions"
                            className="text-gray-400 text-xs"
                        >
                            By proceeding I agree to{" "}
                            <span className="text-blue-700 underline">
                                Terms and Conditions
                            </span>{" "}
                            and have read/understood{" "}
                            <span className="text-blue-700 underline">
                                approach for gradation of risk.
                            </span>
                        </label>
                    </div>
                    {termsError && (
                        <p className="font-thin text-sm text-[red]">
                            Select Agree to Terms & Conditions
                        </p>
                    )}
                    <div className="mt-10">
                        <button
                            onClick={() => {
                                if (
                                    data.businessName !== "" &&
                                    data.entityType !== "" &&
                                    data.number !== "" &&
                                    data.email !== "" &&
                                    data.businessPanNumber !== "" &&
                                    data.loanAmount !== "" &&
                                    data.tenure !== ""
                                ) {
                                    setErrorCheck(false);
                                    if (selected) {
                                        setTermsError(false);
                                        nextPage();
                                    } else {
                                        setTermsError(true);
                                    }
                                } else {
                                    setErrorCheck(true);
                                }
                            }}
                            className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};


export const Page2 = ({ data, setData, prevPage, nextPage }) => {
    const [errorCheck, setErrorCheck] = useState(false);
    // console.log(errorCheck);

    return (
        <div>
            <div className="flex flex-col gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.                 </h1>
                <p className="text-[24px] text-[#452979]">
                    You are one step closer.
                </p>
            </div>
            <div className="pb-10">
                <StepIndicator step={2} total={4} />
            </div>

            {data.applicantType === "Individual" ? (
                <>
                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 pb-10 place-items-center">
                        <SelectField
                            errorCheck={errorCheck}
                            errorMessage={"Please select a qualification"}
                            value={data.qualification}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, qualification: value };
                                });
                            }}
                            label="Qualification"
                            options={[
                                "Under Graduate",
                                "Graduate",
                                "Post Graduate",
                                "PhD",
                                "Chattered Accountant",
                                "Company Secretary",
                                "Doctor",
                                "Cost Accountant",
                            ]}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please select a valid Date of Birth "}
                            value={data.dateOfBirth}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, dateOfBirth: value };
                                });
                            }}
                            label={"Date of Birth"}
                            Icon={CgCalendarDates}
                            type="date"
                        />
                        <RadioField errorCheck={errorCheck} value={data.gender} setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, gender: value }
                            });
                        }} label={"Gender"} errorMessage={"Please select your gender"} Icon={HiOutlineCurrencyRupee} options={["Male", "Female", "Transgender"]} />

                        <SelectField
                            errorCheck={errorCheck}
                            errorMessage={"Please select a Residence Ownership Status "}
                            value={data.residenceOwnershipStatus}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, residenceOwnershipStatus: value };
                                });
                            }}
                            label="Residence Ownership Status"
                            options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]}
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-2 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter a valid Pincode "}
                            value={data.residentialPincode}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, residentialPincode: value };
                                });
                            }}
                            label={"Residence Pincode"}
                            Icon={CgPassword}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter City "}
                            value={data.city}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, city: value };
                                });
                            }}
                            label={"City"}
                            Icon={FaCity}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter State "}
                            value={data.state}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, state: value };
                                });
                            }}
                            label={"State"}
                            Icon={TbBuildingEstate}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 pb-10 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please select a valid Date Of Incorporation"}
                            value={data.dateOfIncorporation}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, dateOfIncorporation: value };
                                });
                            }}
                            label={"Date of Incorporation"}
                            Icon={CgCalendarDates}
                            type="date"
                        />
                        <SelectField
                            errorCheck={errorCheck}
                            errorMessage={"Please select Business Premises Ownership "}
                            value={data.businessPremisesOwnership}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, businessPremisesOwnership: value };
                                });
                            }}
                            label="Business Premises Ownership"
                            options={["Owned by Self or Spouse", "Rented"]}
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 pb-10 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            value={data.annualTurnOver}
                            errorMessage={"Please enter Annual TurnOver"}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, annualTurnOver: value };
                                });
                            }}
                            label={"Annual TurnOver"}
                            Icon={CgCalendarDates}
                            type="number"
                        />

                    </div>

                    <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-2 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter a valid Business Pincode"}
                            value={data.businessPincode}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, businessPincode: value };
                                });
                            }}
                            label={"Business Pincode"}
                            Icon={CgPassword}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter City"}
                            value={data.city}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, city: value };
                                });
                            }}
                            label={"City"}
                            Icon={FaCity}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            errorMessage={"Please enter State "}
                            value={data.state}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, state: value };
                                });
                            }}
                            label={"State"}
                            Icon={TbBuildingEstate}
                        />
                    </div>
                </>
            )}
            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {

                        if (data.applicantType) {
                            if (data.applicantType === "Individual" &&
                                data.qualification !== "" &&
                                data.dateOfBirth !== "" &&
                                data.gender !== "" &&
                                data.residenceOwnershipStatus !== "" &&
                                data.residentialPincode !== "" &&
                                data.city !== "" &&
                                data.state !== ""
                            ) {
                                setErrorCheck(false);
                                nextPage();
                                
                            }
                            else if (
                                data.applicantType === "Non-individual" &&
                                data.dateOfIncorporation !== "" &&
                                data.businessPremisesOwnership !== "" &&
                                data.annualTurnOver !== "" &&
                                data.businessPincode !== "" &&
                                data.city !== "" &&
                                data.state !== ""
                            ) {
                                setErrorCheck(false);
                                nextPage();
                                console.log(data.state);

                            }
                            else {
                                setErrorCheck(true);
                                console.log("yes")
                            }
                        }
                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};


// export const Page3 = ({ data, setData, prevPage, nextPage }) => {
//     const [errorCheck, setErrorCheck] = useState(false);
//     return (
//         <div>
//             <div className="flex flex-col gap-1 py-10">
//                 <h1 className="text-[28px] font-semibold text-[#452979]">
//                     Gear Up to own a car with our Pre-owned Car Loan.
//                 </h1>
//                 <p className="text-[24px] text-[#452979]">
//                     Keep going you are about to complete your journey.
//                 </p>
//             </div>
//             <div className="pb-10">
//                 <StepIndicator step={3} total={4} />
//             </div>
//             {data.applicantType === "Individual " ? (
//                 <div className="grid grid-cols-2 py-5 gap-x-12 gap-y-9">
//                     <SelectField
//                         options={["Self Employed", "Salaried"]}
//                         value={data.employmentType}
//                         setValue={(value) => {
//                             setData((prev) => {
//                                 return { ...prev, employmentType: value };
//                             });
//                         }}
//                         label={"Employment Type"}
//                     />
//                     {data.employmentType && (
//                         <>
//                             {data.employmentType === "Self Employed" && <SelectField
//                                 options={[
//                                     "Proprietorship",
//                                     "Partnership",
//                                     "Limited Liability Partnership",
//                                     "Private Limited Company",
//                                     "Public Limited Company",
//                                 ]}
//                                 value={data.businessType}
//                                 setValue={(value) => {
//                                     setData((prev) => {
//                                         return { ...prev, businessType: value };
//                                     });
//                                 }}
//                                 label={"Business Type"}
//                             />}
//                             <TextField
//                                 value={data.businessVintage}
//                                 setValue={(value) => {
//                                     setData((prev) => {
//                                         return { ...prev, businessVintage: value };
//                                     });
//                                 }}
//                                 label={"Business Vintage"}
//                                 Icon={BsPerson}
//                                 type="date"
//                             />
//                             <TextField
//                                 value={data.annualTurnOver}
//                                 setValue={(value) => {
//                                     setData((prev) => {
//                                         return { ...prev, annualTurnOver: value };
//                                     });
//                                 }}
//                                 label={"Annual Turnover"}
//                                 Icon={HiOutlineCurrencyRupee}
//                             />{" "}
//                         </>
//                     )}
//                 </div>
//             ) : (
//                 <div className="grid grid-cols-2 gap-x-12 gap-y-9 place-items-center">
//                     <TextField
//                         errorCheck={errorCheck}
//                         label={"First Name"}
//                         value={data.firstName}
//                         setValue={(value) => {
//                             setData((prev) => {
//                                 return { ...prev, firstName: value };
//                             });
//                         }}
//                         errorMessage={"Enter First Name"}
//                         Icon={BsPerson}
//                     />
//                     <TextField
//                         errorCheck={errorCheck}
//                         label={"Last Name"}
//                         value={data.lastName}
//                         setValue={(value) => {
//                             setData((prev) => {
//                                 return { ...prev, lastName: value };
//                             });
//                         }}
//                         errorMessage={"Enter Last Name"}
//                         Icon={BsPerson}
//                     />
//                     <TextField
//                         value={data.dateOfBirth}
//                         setValue={(value) => {
//                             setData((prev) => {
//                                 return { ...prev, dateOfBirth: value };
//                             });
//                         }}
//                         label={"Date of Birth"}
//                         Icon={CgCalendarDates}
//                         type="date"
//                     />
//                     <RadioField
//                         value={data.gender}
//                         setValue={(value) => {
//                             setData((prev) => {
//                                 return { ...prev, gender: value };
//                             });
//                         }}
//                         label={"Gender"}
//                         Icon={BsGenderAmbiguous}
//                         options={["Male", "Female", "Transgender"]}
//                     />
//                     <SelectField
//                         value={data.residenceOwnershipStatus}
//                         setValue={(value) => {
//                             setData((prev) => {
//                                 return { ...prev, residenceOwnershipStatus: value };
//                             });
//                         }}
//                         label="Residence Ownership Status"
//                         options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]}
//                     />
//                 </div>
//             )}

//             <div className="flex gap-5 mt-10">
//                 <button
//                     onClick={prevPage}
//                     className="text-[18px] text-black underline duration-500"
//                 >
//                     Back
//                 </button>
//                 <button
//                     onClick={nextPage}
//                     className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
//                 >
//                     Next
//                 </button>
//             </div>
//         </div>
//     );
// };


export const Page3 = ({ data, setData, prevPage, nextPage }) => {

    // const [which, setWhich] = useState("");


    const [errorCheck, setErrorCheck] = useState(false);

    // console.log(data.applicantType);


    return (
        <div>
            <div className="flex flex-col gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.
                </h1>
                <p className="text-[24px] text-[#452979]">
                    Keep going you are about to complete your journey.
                </p>
            </div>
            <div className="pb-10">
                <StepIndicator step={3} total={4} />
            </div>
            {data.applicantType === "Individual" ? (
                <div className="grid grid-cols-2 py-5 gap-x-12 gap-y-9">
                    <SelectField
                        options={["Self Employed", "Salaried"]}
                        value={data.employmentType}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, employmentType: value };
                            });
                        }}
                        label={"Employment Type"}
                    />
                    {data.employmentType && (
                        <>
                            {data.employmentType === "Self Employed" ? (<> <SelectField
                                options={[
                                    "Proprietorship",
                                    "Partnership",
                                    "Limited Liability Partnership",
                                    "Private Limited Company",
                                    "Public Limited Company",
                                ]}
                                errorCheck={errorCheck}
                                errorMessage={"Please select Business Type "}
                                value={data.businessType}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, businessType: value };
                                    });
                                }}
                                label={"Business Type"}

                            />

                                <TextField
                                    errorCheck={errorCheck}
                                    errorMessage={"Please select Business Vintage "}
                                    value={data.businessVintage}
                                    setValue={(value) => {
                                        setData((prev) => {
                                            return { ...prev, businessVintage: value };
                                        });
                                    }}
                                    label={"Business Vintage"}
                                    Icon={BsPerson}
                                    type="date"
                                />
                                <TextField
                                    errorCheck={errorCheck}
                                    errorMessage={"Please enter Annual TurnOver "}
                                    value={data.annualTurnOver}
                                    setValue={(value) => {
                                        setData((prev) => {
                                            return { ...prev, annualTurnOver: value };
                                        });
                                    }}
                                    label={"Annual Turnover"}
                                    Icon={HiOutlineCurrencyRupee}
                                />{" "}
                            </>) : (
                                <>
                                    <TextField
                                        errorCheck={errorCheck}
                                        errorMessage={"Please enter Employer Name"}
                                        value={data.employerName}
                                        setValue={(value) => {
                                            setData((prev) => {
                                                return { ...prev, employerName: value };
                                            });
                                        }}
                                        label={"Employer Name"}
                                        Icon={BsPerson}
                                    // type="" 
                                    />
                                    <TextField
                                        errorCheck={errorCheck}
                                        errorMessage={"Please enter Total Experience"}
                                        value={data.totalExperience}
                                        setValue={(value) => {
                                            setData((prev) => {
                                                return { ...prev, totalExperience: value };
                                            });
                                        }}
                                        label={"Total Experience"}
                                        Icon={BsPerson}
                                        type="number"

                                    />

                                    <TextField
                                        errorCheck={errorCheck}
                                        errorMessage={"Please enter Net Monthly Salary"}
                                        value={data.netMonthlySalary}
                                        setValue={(value) => {
                                            setData((prev) => {
                                                return { ...prev, netMonthlySalary: value };
                                            });
                                        }}
                                        label={"Net Monthly Salary"}
                                        Icon={BsPerson}
                                        type="number"
                                    />

                                </>)

                            }

                        </>
                    )}
                </div>
            ) : (
                <div className="grid grid-cols-2 gap-x-12 gap-y-9 place-items-center">
                    <TextField
                        errorCheck={errorCheck}

                        label={"First Name"}
                        value={data.firstName}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, firstName: value };
                            });
                        }}
                        errorMessage={"Enter First Name"}
                        Icon={BsPerson}
                    />
                    <TextField
                        errorCheck={errorCheck}
                        label={"Last Name"}
                        value={data.lastName}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, lastName: value };
                            });
                        }}
                        errorMessage={"Enter Last Name"}
                        Icon={BsPerson}
                    />
                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"Please select a valid Date of Birth "}
                        value={data.dateOfBirth}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, dateOfBirth: value };
                            });
                        }}
                        label={"Date of Birth"}
                        Icon={CgCalendarDates}
                        type="date"
                    />
                    <RadioField
                        errorCheck={errorCheck}
                        errorMessage={"Please select gender"}
                        value={data.gender}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, gender: value };
                            });
                        }}
                        label={"Gender"}
                        Icon={BsGenderAmbiguous}
                        options={["Male", "Female", "Transgender"]}
                    />
                    <SelectField
                        errorCheck={errorCheck}
                        errorMessage={"Please select Residence Ownership Status"}
                        value={data.residenceOwnershipStatus}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, residenceOwnershipStatus: value };
                            });
                        }}
                        label="Residence Ownership Status"
                        options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]}
                    />
                </div>
            )}

            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {
                        if (data.applicantType) {
                            if (data.applicantType === "Individual" && data.employmentType === "Salaried" &&

                                data.employerName !== "" &&
                                data.totalExperience !== "" &&
                                data.netMonthlySalary !== ""
                            ) {
                                setErrorCheck(false);
                                nextPage();
                            }
                            else if (data.applicantType === "Individual" && data.employmentType === "Self Employed" &&
                                data.businessType !== "" &&
                                data.businessVintage !== "" &&
                                data.annualTurnOver !== ""
                            ) {
                                setErrorCheck(false);
                                nextPage();

                            }
                            else if (
                                data.applicantType === "Non-individual" &&
                                data.firstName !== "" &&
                                data.lastName !== "" &&
                                data.dateOfBirth !== "" &&
                                data.gender !== "" &&
                                data.residenceOwnershipStatus !== ""
                            ) {
                                setErrorCheck(false);
                                nextPage();
                                console.log(data.gender);

                            }
                            else {
                                setErrorCheck(true);
                            }
                        }
                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};





export const Page4 = ({ data, setData, prevPage, nextPage, hendleSubmit1 }) => {
    const [errorCheck, setErrorCheck] = useState(false);
    return (
        <div>
            <div className="flex flex-col w-full gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.
                </h1>
                <p className="text-[24px] text-[#452979]">
                    One Last Step & you're done!.
                </p>
            </div>
            <div className="pb-10">
                <StepIndicator step={4} total={4} />
            </div>

            <div className="w-[60%] flex justify-between gap-x-12 items-center py-8">
                <p className="text-[22px] flex items-center gap-2 text-[#452979]">
                    <FaCarSide /> Finalised your car
                </p>
                <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1">
                        <input defaultChecked name="finalisedCar" onChange={(e) => setData((prev) => {
                            return { ...prev, finalisedCar: e.target.checked ? "Yes" : "No" };
                        })} className="" id="yes" type="radio" />
                        <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="flex items-center gap-1">
                        <input name="finalisedCar" onChange={(e) => setData((prev) => {
                            return { ...prev, finalisedCar: e.target.checked ? "No" : "Yes" };
                        })} className="" id="No" type="radio" />
                        <label htmlFor="No">No</label>
                    </div>
                </div>
            </div>

            {data.finalisedCar === "No" ? (
                <p className="text-[#452979] text-2xl font-bold">
                    No worries, You can finalise your car post Car Loan approval.
                </p>
            ) : (
                <div className="grid grid-cols-2 py-5 gap-x-12 gap-y-9">
                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"Please enter Manufacturer Name"}
                        value={data.manufacturer}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, manufacturer: value };
                            });
                        }}
                        label={"Manufacturer"}
                        Icon={HiOutlineCurrencyRupee}
                    />
                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"Please enter Model"}
                        value={data.model}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, model: value };
                            });
                        }}
                        label={"Model"}
                        Icon={HiOutlineCurrencyRupee}
                    />
                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"Please enter variant"}
                        value={data.variant}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, variant: value };
                            });
                        }}
                        label={"Variant"}
                        Icon={HiOutlineCurrencyRupee}
                    />
                    <TextField
                        errorCheck={errorCheck}
                        errorMessage={"Please enter Year Of Manufacture"}
                        value={data.yearOfManufacture}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, yearOfManufacture: value };
                            });
                        }}
                        label={"Manufacturing Year"}
                        Icon={HiOutlineCurrencyRupee}
                        type="number"
                    />
                </div>
            )}
            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {
                        if (data.finalisedCar === "Yes" &&
                            data.manufacturer !== "" &&
                            data.model !== "" &&
                            data.variant !== "" &&
                            data.yearOfManufacture !== ""
                        ) {
                            setErrorCheck(false);
                            // nextPage();
                            // console.log(data);
                            hendleSubmit1(data)

                        }
                        else if (data.finalisedCar === "No") {
                            nextPage();

                            hendleSubmit1(data)

                        }
                        else {
                            setErrorCheck(true);
                            console.log("kay")
                        }
                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default function CarLoan() {
    let navigate = useNavigate();
    const [data, setData] = useState({

        // individual:-

        // --- page1 -----

        firstName: "",
        lastName: "",

        // --- page2 -----

        qualification: "",
        dateOfBirth: "",
        gender: "",
        residenceOwnershipStatus: "",
        residentialPincode: "",

        // --- page3 -----

        employmentType: "",

        // ------ Salaried --------

        employerName: "",
        totalExperience: "",
        netMonthlySalary: "",

        // ------ Self Employed --------

        businessType: "",
        businessVintage: "",

        // ----page4-----

        // (common in both)



        //________________________ non-individual__________________:-

        // --- page1 ----- 


        firmName: "",
        constitution: "",

        // ----page2-----

        dateOfIncorporation: "",
        businessPremisesOwnership: "",
        businessPincode: "",

        //------page 3 and 4(have commen fileds from individual)-----





        // __________common_______________________:-
        applicantType: "",
        number: "",
        verifyOtp: "000",
        email: "",
        businessPanNumber: "",
        loanAmount: "",
        tenure: "",
        loanPurpose: "",
        usage: "",
        city: "",
        state: "",

        annualTurnOver: "",





        // --- page4 -----

        finalisedCar: "Yes",
        yearOfManufacture: '',
        variant: '',
        model: '',
        manufacturer: '',


        




    });

    // useEffect(() => {
    //     // Define the URL of the API endpoint you want to fetch data from
    //     // const apiUrl = 'https://activ-paisa-server.vercel.app/api/loans/car-applicants';

    //     // Use Axios to make a GET request to the API
    //     axios.get("https://activ-paisa-server.vercel.app/api/loans/car-applicants")
    //       .then((res)=>{
    //         console.log(res);
    //       })
    //       .catch(error => {
    //         console.error('Error fetching data:', error);
    //       });
    //   }, []);






    const [page, setPage] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loader, setLoader] = useState(false);
    const nextPage = () => {
        if (page < Pages.length) {
            setPage(page + 1);
        }
    };
    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    


    const hendleSubmit1 = (data) => {
        Number(data.applicantType);
        setLoader(true);
        if (data.applicantType) {
            let proObj = {}
            if (data.applicantType === "Individual") {


                proObj["applicantType"]= data.applicantType
                proObj["firstName"]= data.firstName
                proObj["lastName"]= data.lastName
                proObj["mobile"]= data.number
                proObj["otp"]= data.verifyOtp
                proObj["email"]= data.email
                proObj["panNumber"]= data.businessPanNumber
                proObj["loanAmount"]= Number(data.loanAmount)
                proObj["tenure"]= data.tenure
                proObj["usage"]= data.usage
                proObj["purposeOfLoan"]= data.loanPurpose
                proObj["qualification"]= data.qualification
                proObj["dateOfBirth"]= data.dateOfBirth
                proObj["gender"]= data.gender
                proObj["residenceOwnershipStatus"]= data.residenceOwnershipStatus
                proObj["pincode"]= data.residentialPincode
                proObj["city"]= data.city
                proObj["state"]= data.state
                proObj["employmentType"]= data.employmentType
                proObj["enterEmployerName"]= data.employerName
                proObj["totalExperience"]= Number(data.totalExperience)
                proObj["netMonthlySalary"]= Number(data.netMonthlySalary)
                proObj["businessType"]= data.businessType
                proObj["businessVintage"]= data.businessVintage
                proObj["annualTurnover"]= Number(data.annualTurnOver)
                proObj ["finalisedYourCar"]= data.finalisedCar
                proObj ["manufacturer"]= data.manufacturer
                proObj ["model"]= data.model
                proObj["variant"]= data.variant
                proObj ["manufacturingYear"]= Number(data.manufacturingYear)


                axios.post("https://activ-paisa-server.vercel.app/api/loans/pre-owned-car-application", {
                    ...proObj
                }).then(() => {
                    // console.log(proObj);
                    setLoader(false);
                    navigate("/thankyou")
                }).catch((error) => {
                    console.log(error);
                })

            }
            else if (data.applicantType === "Non-individual") {

            
                  

                proObj["applicantType"]= data.applicantType
                proObj["companyName"]= data.firmName
                proObj["constitution"]= data.constitution
                proObj["mobile"]= data.number
                proObj["otp"]= data.verifyOtp
                proObj["email"]= data.email
                proObj["panNumber"]= data.businessPanNumber
                proObj["loanAmount"]=Number(data.loanAmount)
                proObj["tenure"]= data.tenure
                proObj["usage"]= data.usage
                proObj["purposeOfLoan"]= data.loanPurpose
                proObj["qualification"]= data.qualification
                proObj["dateOfIncorporation"]= data.dateOfIncorporation
                proObj["businessPremisesOwnership"]= data.businessPremisesOwnership
                proObj["annualTurnover"]= Number(data.annualTurnOver)
                proObj["pincode"]= data.businessPincode
                proObj["city"]= data.city
                proObj["state"]= data.state
                proObj["firstName"]= data.firstName
                proObj["lastName"]= data.lastName
                proObj["dateOfBirth"]= data.dateOfBirth
                proObj["gender"]= data.gender
                proObj["residenceOwnershipStatus"]= data.residenceOwnershipStatus
                proObj["finalisedYourCar"]= data.finalisedCar
                proObj["manufacturer"]= data.manufacturer
                proObj["model"]= data.model
                proObj["variant"]= data.variant
                proObj["manufacturingYear"]= Number(data.yearOfManufacture)

                // console.log(proObj)



                axios.post("https://activ-paisa-server.vercel.app/api/loans/pre-owned-car-application", {
                    ...proObj
                }).then(() => {
                    // console.log(proObj, "sent");
                    setLoader(false);
                    navigate("/thankyou")
                }).catch((error) => {
                    console.log(error);
                    setLoader(false)
                })
            }
            else {
                console.log("no")
                setLoader(false);
            }
        }
        else {
            console.log("type");
            setLoader(false)

        }
    }

    const Pages = [
        <Page1 data={data} setData={setData} nextPage={nextPage} />,
        <Page2
            data={data}
            setData={setData}
            prevPage={prevPage}
            nextPage={nextPage}
        />,
        <Page3
            data={data}
            setData={setData}
            prevPage={prevPage}
            nextPage={nextPage}
        />,
        <Page4
            data={data}
            setData={setData}
            prevPage={prevPage}
            nextPage={nextPage}
            hendleSubmit1={hendleSubmit1}
        />,
    ];

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <FormTempelate isSubmitted={isSubmitted} image={require("../../assets/Loans/carBanner.jpg")}>{Pages[page - 1]}</FormTempelate>;
}
