import { useEffect, useState } from "react";


export default function TextField({ label, type = "text", value = "", setValue, Icon, errorMessage, validationMessage, errorCheck }) {

    const [style, setStyle] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (type === "date") {
            setStyle(true);
        }
    }, [type]);

    useEffect(() => {
        if(value !== ""){
            setStyle(true);
        }
    }, [value]);

    useEffect(() => {
        if (errorCheck && value === "") {
            setError(true);
        } else {
            setError(false);
        }
    }, [errorCheck]);


    return (
        <div className="mb-3 w-full relative">
            <div className='w-full flex gap-2 items-center px-[6px]'>
                {Icon({
                    size: 30,
                    className: `${style ? "text-[#452979]" : "text-gray-400"}`
                })}
                {/* <BsFillPersonLinesFill size={30} className= /> */}
                <div className='relative flex-1 hover:cursor-text'>
                    <input
                        value={value}
                        onFocus={() => {
                            setStyle(true);
                        }}
                        onBlur={() => {
                            if (value === "") {
                                setError(true);
                                setStyle(false);
                            }
                        }}
                        onChange={(e) => {
                            let result = e.target.value;
                            if (result === "") {
                                setError(true);
                            } else {
                                setError(false);
                            }
                            setValue(result);
                        }}
                        type={type}
                        placeholder=""
                        className='w-full bg-transparent border-none outline-none z-[13] hover:cursor-text text-[#452979] p-2'
                        id="exampleFormControlInputText"
                    />
                    <label
                        htmlFor="exampleFormControlInputText"
                        className={`absolute ${style ? "top-[-12px] text-[13px]" : "top-[18%] text-[18px]"} text-gray font-thin left-3 w-full duration-200 z-[-1] hover:cursor-text`}
                    >
                        {label}
                    </label>
                </div>
            </div>
            <div className='mt-[6px]'>
                <div className='relative w-full h-[2px] rounded-full overflow-hidden bg-gray-400'>
                    <div className={`${style ? "w-full" : "w-0"} h-full top-0 left-0 duration-200 bg-[#452979]`}></div>
                </div>
            </div>
            <div className="absolute text-xs mt-1 flex gap-4 w-full justify-between">
                <p className="text-[red] font-thin">{error && errorMessage}</p>
                <p className="text-[#452979]">{validationMessage}</p>
            </div>
        </div>
    );
}