import React from "react";
import fintech from "../.././assets/newLanding/fintech.svg";
import fintechDots from "../.././assets/newLanding/fintechDots.svg";
import india from "../.././assets/newLanding/india.svg";
import indiaDots from "../.././assets/newLanding/indiaDots.svg";
import fraud from "../.././assets/newLanding/fraud.svg";
import fraudDots from "../.././assets/newLanding/fraudDots.svg";

export default function Benefits({ dark }) {
    return (
        <section className="py-16 h-full flex items-center justify-center">
            <div className="w-full max-w-screen-2xl mx-auto mt-[1rem] h-full flex items-center flex-col gap-8 py-4 justify-center">
                <div className="w-full h-full flex p-1 xs:py-2 sm:px-6 items-center justify-center">
                    <div className="w-full h-full p-1 xs:py-2 sm:px-4 flex items-center justify-between flex-row">
                        <div className="flex flex-col items-start w-full gap-10">
                            <div className="w-full items-center flex font-medium  gap-2">
                                <h1 className={" " + (dark ? "text-white" : "text-black")}>KEEPING YOU FINANCIALLY HEALTHY AND SAFE, ALWAYS </h1>
                                <div className={"w-[20%] h-[2px] " + (dark ? "bg-white" : "bg-black")}></div>
                            </div>
                            <div className="w-full flex xl:gap-10 lg:gap-8 gap-5 xl:h-auto lg:h-[90px] h-[70px] items-center ">
                                <div className="bg-[#7a7a7a] overflow-hidden flex rounded-lg relative items-center justify-between p-0 m-0 gap-1 h-full w-full">
                                    <div className="h-full lg:w-[35%] w-[30%]">
                                        <img
                                            src={fintech}
                                            alt="fintech"
                                            className="h-full w-full object-cover"
                                        />
                                    </div>
                                    <div className="lg:w-[65%] w-[70%] h-full justify-center items-center flex lg:pr-2 pr-1">
                                        <h1 className="text-white font-normal xl:text-sm text-center lg:text-xs text-[9px]">
                                            <span className="font-bold">{`“Best Leading Fintech”`}</span>{" "}
                                            at BFS 2023 &{" "}
                                            <span className="font-bold">{`“Best Fintech Consumer
                                            Lender”`}</span>{" "}
                                            at IFTA 2022
                                        </h1>
                                    </div>
                                    <div className="absolute top-0 right-[10%]">
                                        <img src={fintechDots} alt="dots" className="xl:h-[15px] lg:h-[12px] h-[10px]" />
                                    </div>
                                </div>
                                <div className="bg-[#7a7a7a] overflow-hidden flex rounded-lg relative items-center justify-between gap-1 min-h-full h-full w-full">
                                    <div className="h-full lg:w-[35%] w-[30%]">
                                        <img src={india} alt="fintech" className="h-full object-cover w-full" />
                                    </div>
                                    <div className="lg:w-[65%] w-[70%] h-full justify-center items-center flex lg:pr-2 pr-1">
                                        <h1 className="text-white font-normal text-center xl:text-sm lg:text-xs text-[9px]">
                                            Proudly made in <span className="font-bold">India</span>,
                                            for Indians around the world
                                        </h1>
                                    </div>
                                    <div className="absolute bottom-0 right-[10%]">
                                        <img src={indiaDots} alt="dots" className="xl:h-[15px] lg:h-[12px] h-[10px]" />
                                    </div>
                                </div>
                                <div className="bg-[#7a7a7a] overflow-hidden flex rounded-lg relative items-center justify-between gap-1 h-full w-full">
                                    <div className="h-full lg:w-[35%] w-[30%]">
                                        <img src={fraud} alt="fintech" className="h-full object-cover w-full" />
                                    </div>
                                    <div className="lg:w-[65%] w-[70%] h-full justify-center items-center flex flex-col lg:pr-2 pr-1">
                                        <h1 className="text-white font-normal text-center xl:text-sm lg:text-xs text-[9px]">
                                            Beware from Fraudsters
                                        </h1>
                                        <p className="text-white underline font-normal xl:text-sm lg:text-xs text-[9px]">
                                            Check Fraud Detection
                                        </p>
                                    </div>
                                    <div className="absolute top-0 right-[10%]">
                                        <img src={fraudDots} alt="dots" className="xl:h-[15px] lg:h-[12px] h-[10px]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
