import React, { useEffect, useState } from "react";
import logo1 from "../../assets/logos/1.png";
import logo2 from "../../assets/logos/2.png";
import logo3 from "../../assets/logos/3.png";
import logo4 from "../../assets/logos/4.png";
import logo5 from "../../assets/logos/5.png";
import logo6 from "../../assets/logos/6.png";
import logo7 from "../../assets/logos/7.png";
import logo8 from "../../assets/logos/8.png";
import logo9 from "../../assets/logos/9.png";
import logo10 from "../../assets/logos/10.png";
import logo11 from "../../assets/logos/11.png";
import logo12 from "../../assets/logos/12.png";
import { SwitchCircleLight } from "../Global/Circle";

const obj = [
  {
    name: "1",
    catigory: "us",
    link: logo1,
  },
  {
    name: "2",
    catigory: "us",
    link: logo2,
  },
  {
    name: "3",
    catigory: "us",
    link: logo3,
  },
  {
    name: "4",
    catigory: "us",
    link: logo4,
  },
  {
    name: "5",
    catigory: "us",
    link: logo5,
  },
  {
    name: "6",
    catigory: "cc",
    link: logo6,
  },
  {
    name: "7",
    catigory: "cc",
    link: logo7,
  },
  {
    name: "8",
    catigory: "cc",
    link: logo8,
  },
  {
    name: "9",
    catigory: "hl",
    link: logo9,
  },
  {
    name: "10",
    catigory: "hl",
    link: logo10,
  },
]

const CollaborationSection = (mode) => {
  const [loanTitle, setLoanTitle] = useState("All");

  const [item, setItem] = useState(obj);

  // const usItem=obj.filter(i=>i.catigory=="us");
  // setItem(usItem);




  // const onus =()=>{
  //   const usItem=obj.filter(i=>i.catigory=="us");
  // // setItem(usItem);
  // console.log(usItem);
  // }

  // useEffect(()=>{
  //   if(loanTitle==="Unsecured Loan"){
  //     setItem(obj.filter(i=>i.catigory=="us"));
  //   }
  //   else if(loanTitle==="Credit Card"){
  //     setItem(obj.filter(i=>i.catigory=="cc"));
  //   }
  //   else if(loanTitle==="Home Loan"){
  //     setItem(obj.filter(i=>i.catigory=="hl"));
  //   }
  //   else{
  //     setItem(obj);
  //   }

  //   console.log(item)

  // })

  console.log(item);


  return (
    <section className="w-screen h-full flex items-center justify-center relative">
      <SwitchCircleLight radius={"w-[450px] h-[450px]"} top={"10%"} left={"-10%"} />
      <div className="w-full max-w-screen-2xl mx-auto mt-[1rem] h-full flex items-center justify-center flex-col gap-12 relative">
        <SwitchCircleLight radius={"w-[450px] h-[450px]"} top={"0px"} left={"80%"} />
        <SwitchCircleLight radius={"w-[350px] h-[350px]"} top={"80%"} left={"85%"} />
        <div className="flex flex-col justify-center w-full max-w-screen-xl mx-auto xl:px-0 sm:px-10 h-full gap-2">
          <h1 className="text-[2.5rem] font-[550] self-center mb-[2rem] text-center  p-2" style={{ color: mode.dark ? "white" : "black" }}>
            <span className="text-[#2e88fe]">Our partners</span>  from across the industry
          </h1>
          <div className="flex items-start justify-center md:justify-start gap-2 w-full max-w-screen-xl mx-auto ml-4 xl:px-0 sm:px-8">
            <div className="flex items-center justify-start w-full h-full md:flex-nowrap flex-wrap gap-2 flex-row">
              <div
                onClick={() => {
                  setLoanTitle("All");
                }}
                className={`flex items-center flex-row justify-center px-2 py-2 gap-3 rounded-lg  ${loanTitle === "All" ? "bg-[#2457C5] text-white" : "bg-[#ffffff]"} hover:bg-[#2457C5] hover:text-white border border-[#000000] hover:cursor-pointer   hover:border-2 hover:border-[#125BBA] group transition-all`}
              >

                <div className="flex items-center justify-start" onClick={() => { setLoanTitle("All"); setItem(obj); }}>
                  <p className="text-[0.5rem] lg:text-xs font-semibold">
                    All
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  setLoanTitle("Unsecured Loan");
                  setItem(obj.filter(i => i.catigory == "us"));
                }}
                className={`flex items-center flex-row justify-center  px-2 py-2 gap-3 rounded-lg ${loanTitle === "Unsecured Loan" ? "bg-[#2457C5] text-white" : "bg-[#ffffff]"} hover:bg-[#2457C5] hover:text-white border border-[#000000] hover:cursor-pointer   hover:border-2 hover:border-[#125BBA] group transition-all`}
              >

                <div className="flex items-center justify-start">
                  <p className="text-[0.5rem]  lg:text-xs font-semibold">
                    Unsecured Loan
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  setLoanTitle("Credit Card");
                  setItem(obj.filter(i => i.catigory == "cc"));
                }}
                className={`flex items-center flex-row justify-center px-2 py-2 gap-3 rounded-lg ${loanTitle === "Credit Card" ? "bg-[#2457C5] text-white" : "bg-[#ffffff]"} hover:bg-[#2457C5] hover:text-white border border-[#000000] hover:cursor-pointer   hover:border-2 hover:border-[#125BBA] group transition-all`}
              >

                <div className="flex items-center justify-start">
                  <p className="text-[0.5rem]  lg:text-xs font-semibold">
                    Credit Card
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  setLoanTitle("Home Loan");
                  setItem(obj.filter(i => i.catigory == "hl"));
                }}
                className={`flex items-center flex-row justify-center  px-2 py-2 gap-3 rounded-lg ${loanTitle === "Home Loan" ? "bg-[#2457C5] text-white" : "bg-[#ffffff]"} hover:bg-[#2457C5] hover:text-white border border-[#000000] hover:cursor-pointer   hover:border-2 hover:border-[#125BBA] group transition-all`}
              >

                <div className="flex items-center justify-start">
                  <p className="text-[0.5rem]  lg:text-xs font-semibold">
                    Home Loan
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center py-8 xl:px-2 px-10">
            <div className="grid xl:grid-cols-7 md:grid-cols-5 sm:grid-cols-3 max-w-screen-xl grid-cols-2 justify-center gap-6 p-2 w-full">
              {item.map((i) => (
                <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                  <img src={i.link} alt="21" className="xl:w-44 w-40 rounded-lg" />
                </div>
              ))}
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo1} alt="1" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo2} alt="2" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo3} alt="3" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo4} alt="4" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo5} alt="5" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo6} alt="6" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo7} alt="7" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo8} alt="8" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo9} alt="9" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo10} alt="10" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo11} alt="11" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={logo12} alt="12" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/13.png")} alt="13" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/14.png")} alt="14" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/15.png")} alt="15" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/16.png")} alt="16" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/17.png")} alt="17" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/18.png")} alt="18" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/19.png")} alt="19" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/20.png")} alt="20" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/21.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/22.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/23.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/24.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/25.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/26.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/27.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/49.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/28.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/29.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/30.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/31.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/32.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/33.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/50.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/34.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/35.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/36.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/37.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/38.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/39.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/40.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/41.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/42.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/43.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/44.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/45.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/46.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/47.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/48.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/51.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 bg-white shadow-md rounded-md p-2 gap-1">
                <img src={require("../../assets/logos/52.png")} alt="21" className="xl:w-44 w-40 rounded-lg" />
              </div>



            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CollaborationSection;