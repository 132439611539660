import React, { useLayoutEffect } from 'react'
import { BsPhone } from 'react-icons/bs'
import { FiMail } from 'react-icons/fi'
import { IoIosArrowForward } from 'react-icons/io'

// import playstore from "../assets/LoginPage/PlayStore.png"
import playstore from "../assets/LoginPage/PlayStore.png"
import Appstore from "../assets/LoginPage/AppStore.png"
import LoneMen from "../assets/LoginPage/LoneMen.png"

import bajaj from "../assets/LoginPage/bajaj.png"
import axis from "../assets/LoginPage/axis.png"
import icic from "../assets/LoginPage/icic.png"
import kotak from "../assets/LoginPage/kotak.png"

import PersonalLone from "../assets/LoginPage/PersonalLone.png"
import HomeLone from "../assets/LoginPage/HomeLone.png"
import propertyLone from "../assets/LoginPage/propertyLone.png"
import businessLone from "../assets/LoginPage/businessLone.png"
import PreOwnedCarLone from "../assets/LoginPage/PreOwnedCarLone.png"
import ProfecnalLone from "../assets/LoginPage/ProfecnalLone.png"
import Support from '../components/LandingPage/Support'

import Cfy from '../assets/LoginPage/Cfy.png'

import ZeroInvestment from "../assets/LoginPage/ZeroInvestment.png"
import QuickPayout from "../assets/LoginPage/QuickPayout.png"
import LimitlessEarnings from "../assets/LoginPage/LimitlessEarnings.png"
import TrainingUpskilling from "../assets/LoginPage/TrainingUpskilling.png"
import CustomerSupport from "../assets/LoginPage/CustomerSupport.png"
import TopProducts from "../assets/LoginPage/TopProducts.png"

import JoinUs from "../assets/LoginPage/JoinUs.png"
import TestimonialsSection from '../components/LandingPage/TestimonialsSection'
import { Link } from 'react-router-dom'


const PartnerUsPage = () => {

    useLayoutEffect(()=>{
        window.scrollTo(0,0);
        
    },[])
    return (
        <div className='overflow-hidden'>
            <div id='new' className="min-h-[100vh] w-full flex flex-col justify-center items-center LoginPageBack py-60" >
                <div className='flex w-full relative top-[-30%] md:top-0'>
                    <div className='flex bg-[#e6f1fe] w-full py-10 md:px-6 xl:px-24 relative justify-center md:justify-start '>
                        <div className='flex flex-col gap-7'>
                            <div className=" text-center md:text-start md:text-[1.1rem] lg:text-[1.6rem] xl:text-[1.8rem] font-[700]">
                                <h1>Become a Digital Loan  Advisor and </h1>
                                <h1 className='font-[700] text-[#2351ff]'>Earn Rs. 1 Lakh/Month</h1>
                            </div>
                            <div>
                                <ul className="list-disc font-[400] text-[1.03rem] ml-7">
                                    <li> No Investement required</li>
                                    <li> Simple To use</li>
                                    <li> Finacial Planning</li>
                                    <li> Marketing and Brading</li>
                                </ul>
                            </div>
                        </div>
                        <img src={LoneMen} className='w-[30rem] self-center absolute left-[33%] mb-8 hidden xl:block' />
                    </div>
                </div>
                <div className='w-[300px] xxs:w-[25rem]  bg-[white] absolute rounded-2xl shadow-2xl flex flex-col px-8 py-10 justify-center  md:right-[3%] xl:right-[10%] gap-5 bh'>
                    <div className='flex flex-col w-full'>
                        <h1 className="text-[1.4rem] font-[600]">Login To Your Account</h1>
                        <p className='font-[300] text-[1rem] text-[#838283] leading-2'>if you have an account, sign in with your mobile number</p>
                    </div>
                    <div className='flex flex-col w-full'>
                        <div class="coolinput relative">
                            <label for="input" class="text">Name</label>
                            <input type="text" placeholder="Write here..." name="input" class="input" />

                        </div>
                        <div class="coolinput relative">
                            <label for="input" class="text">Email</label>
                            <input type="text" placeholder="Write here..." name="input" class="input" />
                            <FiMail className='absolute text-[2rem] text-[#2351ff] right-10 mt-7' />
                        </div>
                        <div class="coolinput relative">
                            <label for="input" class="text">Mobile Number</label>
                            <input type="text" placeholder="Write here..." name="input" class="input" />
                            <BsPhone className='absolute text-[2rem] text-[#2351ff] right-10 mt-7' />
                        </div>
                    </div>
                    <button className='w-full p-4 bg-[#2351ff] font-[600] text-[1.1rem] text-white rounded-xl'>
                        Send OTP
                    </button>
                    <div className='flex flex-col items-center xxs:items-start gap-6 xxs:gap-0 xxs:flex-row w-full justify-between'>
                        <img src={playstore} className='w-[10rem] cursor-pointer'></img>
                        <img src={Appstore} className='w-[10rem]'></img>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col gap-7 my-28'>
                <div className='w-full flex-col items-center justify-center flex'>
                    <h1 className='text-[1.6rem] font-[600]  text-center xxs:text-start'>Top Brands on<span className='text-[#3a76cf] font-[700]'> ActivPaisa </span></h1>
                    <p className='text-center md:text-start'>We are trusted by the leading brands in the industry</p>
                </div>
                <div className='flex flex-col md:flex-row items-center gap-7 md:gap-0 md:items-start md:justify-around flex-wrap '>
                    <img src={kotak} className='w-[17rem]'></img>
                    <img src={axis} className='w-[17rem]'></img>
                    <img src={bajaj} className='w-[17rem]'></img>
                    <img src={icic} className='w-[17rem]'></img>
                </div>
            </div>


            <div className='w-full flex flex-col '>
                <div className='w-full flex-col items-center justify-center flex'>
                    <h1 className='text-[1.6rem] font-[600] text-center xxs:text-start'>Services on<span className='text-[#3a76cf] font-[700]'> ActivPaisa </span></h1>
                    <p className='text-center md:text-start'>Popular Services categories on ActivPaisa</p>
                </div>
            </div>
            <div className='flex flex-wrap gap-24 justify-center p-2 xxs:p-10 md:p-24'>
                <Link to={"/personal-loan"} className='bg-[#f0f0f0] w-[18rem] h-[20rem] rounded-xl shadow-lg flex flex-col justify-between py-6 px-5 relative'>
                    <div className='text-[1.2rem] text-[#d65b6b] flex flex-col'>
                        <h1>Personal</h1>
                        <h1>Loan</h1>
                        <div className='w-[5rem] rounded-3xl bg-[#d65b6b] h-[3px]'></div>
                    </div>
                    <p className='text-[#777777] w-[9rem] mt-[-3rem]'>Paperless process
                        at low rate
                    </p>
                    <div className='w-full absolute left-0 bottom-16'>
                        <div className='bg-[#d65b6b] px-5 py-2 w-fit rounded-r-[11px] text-white'>10.49%</div>
                    </div>

                    <img src={PersonalLone} className='absolute w-[8.5rem] right-[-15px] top-[4.7rem]'></img>

                    <div className='flex items-center text-[#474ebb]'>
                        <h1>Check Eligibility </h1>
                        <IoIosArrowForward className='mt-[3px]' />
                    </div>
                </Link>
                <Link to={"/home-loan"} className='bg-[#f0f0f0] w-[18rem] h-[20rem] rounded-xl shadow-lg flex flex-col justify-between py-6 px-5 relative'>
                    <div className='text-[1.2rem] text-[#0000ee] flex flex-col'>
                        <h1>Home</h1>
                        <h1>Loan</h1>
                        <div className='w-[5rem] rounded-3xl bg-[#5457ca] h-[3px]'></div>
                    </div>
                    <p className='text-[#0a0808] w-[9rem] mt-[-3rem]'>Paperless process
                        at low rate
                    </p>
                    <div className='w-full absolute left-0 bottom-16'>
                        <div className='bg-[#5457ca] px-5 py-2 w-fit rounded-r-[11px] text-white'>8.35%</div>
                    </div>

                    <img src={HomeLone} className='absolute w-[8.5rem] right-[-15px] top-[4.7rem]'></img>

                    <div className='flex items-center text-[#474ebb]'>
                        <h1>Check Eligibility </h1>
                        <IoIosArrowForward className='mt-[3px]' />
                    </div>
                </Link>

                <Link to={"/loan-Against-Property"} className='bg-[#f0f0f0] w-[18rem] h-[20rem] rounded-xl shadow-lg flex flex-col justify-between py-6 px-5 relative'>
                    <div className='text-[1.2rem] text-[#4f94c6] flex flex-col'>
                        <h1>Loan Against</h1>
                        <h1>Property</h1>
                        <div className='w-[5rem] rounded-3xl bg-[#4f94c6] h-[3px]'></div>
                    </div>
                    <p className='text-[#777777] w-[9rem] mt-[-3rem]'>Paperless process
                        at low rate
                    </p>
                    <div className='w-full absolute left-0 bottom-16'>
                        <div className='bg-[#4f94c6] px-5 py-2 w-fit rounded-r-[11px] text-white'>9.2%</div>
                    </div>

                    <img src={propertyLone} className='absolute w-[8.5rem] right-[-15px] top-[4.7rem]'></img>

                    <div className='flex items-center text-[#474ebb]'>
                        <h1>Check Eligibility </h1>
                        <IoIosArrowForward className='mt-[3px]' />
                    </div>
                </Link>
                <Link to={"/business-loan"} className='bg-[#f0f0f0] w-[18rem] h-[20rem] rounded-xl shadow-lg flex flex-col justify-between py-6 px-5 relative'>
                    <div className='text-[1.2rem] text-[#9293c4] flex flex-col'>
                        <h1>Business</h1>
                        <h1>Loan</h1>
                        <div className='w-[5rem] rounded-3xl bg-[#9293c4] h-[3px]'></div>
                    </div>
                    <p className='text-[#777777] w-[9rem] mt-[-3rem]'>Paperless process
                        at low rate
                    </p>
                    <div className='w-full absolute left-0 bottom-16'>
                        <div className='bg-[#9293c4] px-5 py-2 w-fit rounded-r-[11px] text-white'>14%</div>
                    </div>

                    <img src={businessLone} className='absolute w-[8.5rem] right-[-15px] top-[4.7rem]'></img>

                    <div className='flex items-center text-[#474ebb]'>
                        <h1>Check Eligibility </h1>
                        <IoIosArrowForward className='mt-[3px]' />
                    </div>
                </Link>
                <Link to={"/preowned-car-loan"} className='bg-[#f0f0f0] w-[18rem] h-[20rem] rounded-xl shadow-lg flex flex-col justify-between py-6 px-5 relative'>
                    <div className='text-[1.2rem] text-[#4f94c6] flex flex-col'>
                        <h1>Pre Owned</h1>
                        <h1>Car Loan</h1>
                        <div className='w-[5rem] rounded-3xl bg-[#4f94c6] h-[3px]'></div>
                    </div>
                    <p className='text-[#777777] w-[9rem] mt-[-3rem]'>Paperless process
                        at low rate
                    </p>
                    <div className='w-full absolute left-0 bottom-16'>
                        <div className='bg-[#4f94c6] px-5 py-2 w-fit rounded-r-[11px] text-white'>9.2%</div>
                    </div>

                    <img src={PreOwnedCarLone} className='absolute w-[8.5rem] right-[-15px] top-[4.7rem]'></img>

                    <div className='flex items-center text-[#474ebb]'>
                        <h1>Check Eligibility </h1>
                        <IoIosArrowForward className='mt-[3px]' />
                    </div>
                </Link>

                <Link to={"/professional-loan"} className='bg-[#f0f0f0] w-[18rem] h-[20rem] rounded-xl shadow-lg flex flex-col justify-between py-6 px-5 relative'>
                    <div className='text-[1.2rem] text-[#9293c4] flex flex-col'>
                        <h1>Professional</h1>
                        <h1>Loan</h1>
                        <div className='w-[5rem] rounded-3xl bg-[#9293c4] h-[3px]'></div>
                    </div>
                    <p className='text-[#777777] w-[9rem] mt-[-3rem]'>Paperless process
                        at low rate
                    </p>
                    <div className='w-full absolute left-0 bottom-16'>
                        <div className='bg-[#9293c4] px-5 py-2 w-fit rounded-r-[11px] text-white'>14%</div>
                    </div>

                    <img src={ProfecnalLone} className='absolute w-[8.5rem] right-[-15px] top-[4.7rem]'></img>

                    <div className='flex items-center text-[#474ebb]'>
                        <h1>Check Eligibility </h1>
                        <IoIosArrowForward className='mt-[3px]' />
                    </div>
                </Link>
            </div>
            <Support mode={"dark"} />
            <div className='flex flex-col md:flex-row w-full gap-12 md:gap-0'>
                <div className='md:w-[40%] flex flex-col justify-center items-center '>
                    <div className='text-[1.2rem] font-[700] text-center'>
                        <h1>Why is <span className='text-[#3a76cf] font-[700]'>ActivPaisa</span> the Right</h1>
                        <h1>choice for You?</h1>
                    </div>
                    <div>
                        <img src={Cfy} alt="cfy" className='w-[18rem]' />
                    </div>
                </div>
                <div className='md:w-[60%] flex flex-wrap gap-10 justify-center'>
                    <div className='flex flex-col gap-6 items-center md:items-start'>
                        <div className='w-[5rem] h-[5rem] bg-[#eff6ff] p-2 rounded-xl'>
                            <img src={ZeroInvestment} />
                        </div>
                        <div>
                            <h1 className='font-[700] tracking-wider text-[1.07rem]'>Zero Investment</h1>
                            <p className='font-[300] w-[15rem]'>Build your business without any
                                investment
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-6 items-center md:items-start'>
                        <div className='w-[5rem] h-[5rem] bg-[#eff6ff] p-2 rounded-xl'>
                            <img src={QuickPayout} ></img>
                        </div>
                        <div>
                            <h1 className='font-[700] tracking-wider text-[1.07rem]'>Quick Payout</h1>
                            <p className='font-[300] w-[15rem]'>Receive payout directly in your bank
                                account in shortest time
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-6 items-center md:items-start'>
                        <div className='w-[5rem] h-[5rem] bg-[#eff6ff] p-2 rounded-xl'>
                            <img src={LimitlessEarnings} ></img>
                        </div>
                        <div>
                            <h1 className='font-[700] tracking-wider text-[1.07rem]'>Limitless Earnings</h1>
                            <p className='font-[300] w-[15rem]'>No cap on your earnings & growth
                                opportunity
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-6 items-center md:items-start'>
                        <div className='w-[5rem] h-[5rem] bg-[#eff6ff] p-2 rounded-xl'>
                            <img src={TrainingUpskilling} ></img>
                        </div>
                        <div>
                            <h1 className='font-[700] tracking-wider text-[1.07rem]'>Training & Upskilling</h1>
                            <p className='font-[300] w-[15rem]'>Get trained by finance and sales
                                experts for free
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-6 items-center md:items-start'>
                        <div className='w-[5rem] h-[5rem] bg-[#eff6ff] p-2 rounded-xl'>
                            <img src={CustomerSupport} ></img>
                        </div>
                        <div>
                            <h1 className='font-[700] tracking-wider text-[1.07rem]'>Customer Support</h1>
                            <p className='font-[300] w-[15rem]'>Access tools and content to build
                                relationship
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-6 items-center md:items-start'>
                        <div className='w-[5rem] h-[5rem] bg-[#eff6ff] p-2 rounded-xl'>
                            <img src={TopProducts} ></img>
                        </div>
                        <div>
                            <h1 className='font-[700] tracking-wider text-[1.07rem]'>Top Products</h1>
                            <p className='font-[300] w-[15rem]'>Trustworthy and high-rated financial
                                products & categories to choose
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex items-center flex-col py-16'>
                <h1 className='my-[1rem] text-[1.3rem] font-[600]'>Join Us. We're Growing.</h1>
                <img src={JoinUs} className='w-[50%]'></img>
            </div>

            {/* <div className='w-full flex-col flex '>
                <h1 className='my-[1rem] text-[1.3rem] font-[600]'>Join Us. We're Growing.</h1>
                
            </div> */}
            <TestimonialsSection/>
        </div>
    )
}

export default PartnerUsPage;