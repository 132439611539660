import React, { useContext, useEffect, useState } from 'react'
import { DarkModeContext } from '../../context/DarkModeState';

export function Circle({ radius, top, left, color }) {
  return (
    <div style={{
      top,
      left,
      background: `radial-gradient(50% 50.00% at 50% 50.00%, ${color} 0%, rgba(217, 217, 217, 0.00) 100%)`
    }} className={`absolute ${radius} rounded-full z-[-3]`}></div>
  );
}

export function SwitchCircleLight({ radius, top, left, center }) {
  let darkMode = useContext(DarkModeContext);
  const [color, setColor] = useState({
    color1: "rgba(0, 0, 0, 0.55)",
    color2: "rgba(0, 0, 0, 0.00)"
  });
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (center) {
      setStyle({
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      })
    } else {
      setStyle({
        top,
        left,
      })
    }
  }, [center]);
  useEffect(() => {
    if (darkMode.darkMode) {
      setColor({
        color1: "rgba(241, 241, 241, 0.55)",
        color2: "rgba(255, 255, 255, 0.00)"
      });
    } else {
      setColor({
        color1: "rgba(0, 0, 0, 0.55)",
        color2: "rgba(0, 0, 0, 0.00)"
      });
    }
  }, [darkMode.darkMode]);

  return (
    <div style={{
      ...style,
      background: `radial-gradient(50% 50.00% at 50% 50.00%, ${color.color1} 0%, ${color.color2} 100%)`
    }} className={`absolute ${radius} rounded-full z-[-3]`}></div>
  );
}

export default function SwitchCircle({ radius, top, left, center }) {
  let darkMode = useContext(DarkModeContext);
  const [color, setColor] = useState({
    color1: "#000000",
    color2: "rgba(0, 0, 0, 0.00)"
  });
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (center) {
      setStyle({
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      })
    } else {
      setStyle({
        top,
        left,
      })
    }
  }, [center]);
  useEffect(() => {
    if (darkMode.darkMode) {
      setColor({
        color1: "#D9D9D9",
        color2: "rgba(255, 255, 255, 0.00)"
      });
    } else {
      setColor({
        color1: "#000000",
        color2: "rgba(0, 0, 0, 0.00)"
      });
    }
  }, [darkMode.darkMode]);


  return (
    <div style={{
      ...style,
      background: `radial-gradient(50% 50.00% at 50% 50.00%, ${color.color1} 0%, ${color.color2} 100%)`
    }} className={`absolute ${radius} rounded-full z-[-2]`}></div>
  );
}