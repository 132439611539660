import React from 'react';
import SwitchCircle from '../Global/Circle';

export default function CardsSection({dark}) {
    return (
        <div className='min-h-screen flex justify-center items-center relative'>
            <SwitchCircle left={"80%"} radius={"w-[500px] h-[500px]"} top={"-5%"} />
            <SwitchCircle left={"0%"} radius={"w-[500px] h-[500px]"} top={"70%"} />
            <div className='w-full max-w-screen-2xl flex flex-col items-center gap-10 px-8 py-16'>
                <div className={`w-[95%] flex flex-col gap-1 ${dark ? "text-white" : "text-black"}`}>
                    <h1 className='font-semibold xl:text-[3rem] text-[40px]'>Swipe Like A Pro - Credit Card on the Go!</h1>
                    <p className={`xl:text-base text-sm ${dark ? "text-[rgba(255,255,255,0.6)]" : "text-black"}`}>Activpaisa is your one-stop solution for informed consultations pertaining to your next online credit card purchase. Not only does a Credit Card improve your credit score, but it also allows you to purchase high-end commodities. However, with a massive range of credit cards, we have hand-picked some of the best credit cards across various banks. Conveniently apply online today!</p>
                </div>
                <div className='w-[90%]'>
                    <div className='grid grid-cols-4 gap-10 place-items-center'>
                        <img className="w-full h-full cursor-pointer" src={require("../../assets/card/yesBank.png")} />
                        <a href='https://www.idfcfirstbank.com/credit-card/ntb-diy/apply' target='_blank'>
                            <img className="w-full h-full cursor-pointer" src={require("../../assets/card/idfcBank.png")} />
                        </a>
                        <a href='https://cconboarding.aubank.in/auccself/#/landing' target='_blank'>
                            <img className="w-full h-full cursor-pointer" src={require("../../assets/card/auBank.png")} />
                        </a>
                        <img className="w-full h-full cursor-pointer" src={require("../../assets/card/axisBank.png")} />
                    </div>
                </div>
            </div>
        </div>
    )
}
