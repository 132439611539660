import React from 'react'
import GroupRings from "../../assets/newLanding/GroupRings.png";
import GroupRings2 from "../../assets/newLanding/GroupRings2.png";
import StarBlack from "../../assets/newLanding/StarBlack.png";
import StarWhitepng from "../../assets/newLanding/StarWhitepng.png";
import CompititiveIntersetRate from "../../assets/newLanding/CompititiveIntersetRate.png";
import PresonalLcaSol from "../../assets/newLanding/PresonalLcaSol.png";
import commandCustomerSupport from "../../assets/newLanding/commandCustomerSupport.png";
import SwitchCircle from '../Global/Circle'

export default function Support({ mode }) {
    return (
        <section className='w-screen min-h-screen h-full flex flex-col items-center gap-12 justify-center'>
            <div className="flex items-center justify-center p-4 max-w-[1350px] mx-auto xs:p-6 w-full">
                <div className="h-full w-full flex justify-center items-center relative p-4">
                    <div className="absolute -top-[9rem] left-0">
                        <img src={`${!mode.dark ? GroupRings : GroupRings2}`} alt="group rings" />
                    </div>
                    <div className="py-12 px-8 w-full bg-[#373737] rounded-3xl flex justify-center items-center relative" style={{ backgroundColor: mode.dark ? "#cbcccf" : "#373737" }}>
                        <SwitchCircle radius={"w-[700px] h-[700px]"} center={true} />
                        <div className="flex justify-between items-center lg:gap-10 gap-18 flex-col lg:flex-row" style={{ color: mode.dark ? "black" : "white" }}>
                            <div className=" xl:w-[20rem] w-[17rem] flex flex-col justify-center items-center gap-3">
                                <div>
                                    <img src={CompititiveIntersetRate} alt="compitive"></img>
                                </div>
                                <h1 className="text-[21px] font-500 w-[60%] flex-1 text-center" >
                                    Competitive Interest Rates
                                </h1>
                                <p className="text-[12px] w-[13.5rem] flex-1 text-center">
                                    Guarantee that you have access to inexpensive borrowing
                                    choices, we provide competitive interest rates.
                                </p>
                            </div>
                            <div className=" xl:w-[20rem] w-[17rem]  flex flex-col justify-center items-center gap-3">
                                <div>
                                    <img src={PresonalLcaSol} alt="presonal"></img>
                                </div>
                                <h1 className="text-[21px] font-500 w-[60%] flex-1 text-center">
                                    Personalized Loan Solutions
                                </h1>
                                <p className="text-[12px] w-[13.5rem] text-center flex-1">
                                    {" "}
                                    Recognizing that every borrower is different, we provide
                                    personalized loan options catered to your particular financial
                                    situation.
                                </p>
                            </div>
                            <div className=" xl:w-[20rem] w-[17rem] flex flex-col justify-center items-center gap-3">
                                <div>
                                    <img src={commandCustomerSupport} alt="command"></img>
                                </div>
                                <h1 className="text-[21px] font-500 w-[12.5rem] flex-1 text-center">
                                    Committed<br></br> Customer Support
                                </h1>
                                <p className="text-[12px] w-[13.5rem] flex-1 text-center">
                                    Our experienced customer support is here to guide you through
                                    the loan application process and address any concerns you may
                                    have.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="absolute right-[2rem] bottom-[2rem]">
                        <img src={StarWhitepng} alt="starwhite" />
                    </div>
                    <div className="absolute right-[2rem] -bottom-[2rem]">
                        <img src={StarBlack} alt="starblack" />
                    </div>
                </div>
            </div>
        </section>

    )
}
