import React from 'react';

export default function Loader() {
    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center gap-[1rem] z-[50]">
            <div className="flex justify-center items-center">
                <div className="w-[6.25rem] aspect-[1] rounded-[50%] spinner-3"></div>
            </div>
            <div className="flex justify-center items-center gap-8">
                <p className="text-3xl font-[bold] text-[#083378]">Loading</p>
                <div className="loader"></div>
            </div>
        </div>
    )
}
