import React from "react";
import download from "../../assets/newLanding/download.png";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import qr from "../../assets/newLanding/qr.png";
import google from "../../assets/newLanding/google.png";
import apple from "../../assets/newLanding/apple.png";
import { SwitchCircleLight } from "../Global/Circle";

const DownloadSection = ({ dark }) => {
  return (
    <section className="w-full min-h-screen h-full flex items-center justify-center">
      <div className="w-full max-w-screen-2xl mx-auto h-full flex items-center justify-center">
        <div className="w-full h-full flex items-center py-4 px-4 justify-center gap-4 flex-col-reverse md:flex-row relative">
          <SwitchCircleLight radius={"w-[550px] h-[550px]"} top={"0px"} left={"-25%"} />
          <div className="w-full h-full flex flex-col gap-4 md:flex-row justify-center items-center py-2 px-6">
            <div className="w-full md:w-[60%] flex flex-col justify-start items-start gap-3">
              <div className="w-full flex items-start flex-col justify-start">
                <div className="w-full flex items-start flex-col justify-start">
                  <h1 style={{
                    color: dark ? "#ffffff" : "#000000"
                  }} className="text-4xl sm:text-5xl  md:text-4xl leading-[1.5] md:leading-[1.4] xl:leading-[1.2] font-[550]">
                    Download the
                    <span className="text-[#2281ff] text-3xl sm:text-4xl  font-[500]">
                      {" "}
                      ActivPaisa{" "}
                    </span>{" "}
                    <span className="font-[550]">Mobile App</span>
                  </h1>
                </div>
              </div>
              <div className="flex items-start w-full flex-col gap-1 justify-start">
                <div className="flex items-center justify-center flex-row gap-2">
                  <IoCheckmarkDoneCircleOutline className="text-red-700 text-sm" />
                  <p className="text-[#626262] text-base  py-2 font-[300]">
                    Track your credit score all the time and stay financially
                    healthy
                  </p>
                </div>
                <div className="flex items-center justify-center flex-row gap-2">
                  <IoCheckmarkDoneCircleOutline className="text-red-700 text-sm" />
                  <p className="text-[#626262] text-base  py-2 font-[300]">
                    Get exclusive Loans and Credit Card offers
                  </p>
                </div>
                <div className="flex items-center justify-center flex-row gap-2">
                  <IoCheckmarkDoneCircleOutline className="text-red-700 text-sm" />
                  <p className="text-[#626262] text-base  py-2 font-[300]">
                    Enjoy a seamless experience
                  </p>
                </div>
              </div>
              <div className="flex items-start justify-start flex-col gap-2">
                <h2 className="text-sm text-[#626262]">
                  Scan or click to Download App on your mobile
                </h2>
                <div className="flex items-center justify-center flex-row gap-2">
                  <img src={qr} alt="qr" className="" />
                  <div className="text-[#000000] text-sm">-Or-</div>
                  <div className="flex items-center flex-col justify-center gap-2">
                    <img src={google} alt="app" />
                    <img src={apple} alt="app" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-full md:w-[40%] flex items-center justify-center">
              <div className="flex h-[20rem] md:h-[24rem] lg:h-[30rem] w-auto items-center relative justify-center">
                <img
                  className="flex w-full h-full object-contain items-center justify-center"
                  src={download}
                  alt="banner Img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default DownloadSection;
