import { useEffect, useState } from "react";
import { BsFillPersonLinesFill } from "react-icons/bs";

export default function SelectField({ label, options = [], Icon, value = "", setValue, errorMessage, validationMessage, errorCheck }) {

    const [style, setStyle] = useState(false);
    const [error, setError] = useState(false);

    // useEffect(() => {
    //     if (value !== "") {
    //         setStyle(true);
    //     } else {
    //         setStyle(false);
    //     }
    // }, [value]);

    useEffect(() => {
        if (errorCheck && value === "") {
            setError(true);
        } else {
            setError(false);
        }
    }, [errorCheck]);

    return (
        <div className="mb-3 w-full relative">
            <div className='w-full flex gap-2 items-center px-[6px]'>
                <BsFillPersonLinesFill size={30} className={`${style ? "text-[#452979]" : "text-gray-400"}`} />
                <div className='relative flex-1 hover:cursor-text'>
                    <select
                        value={value}
                        onChange={(e) => {
                            let result = e.target.value;
                            if (result === "") {
                                setError(true);
                            } else {
                                setError(false);
                            }
                            setValue(result);
                        }}
                        onClick={() => {
                            setStyle(true);
                        }}
                        onBlur={() => {
                            if (value === "") {
                                setError(true);
                                setStyle(false);
                            }
                        }}
                        type="text"
                        className={`w-full bg-transparent border-0 outline-0 outline-none z-[13] hover:cursor-text ${value !== "" ? "text-[#452979] font-normal" : "text-gray font-thin"} p-2`}
                        id="exampleFormControlInputText"
                    >
                        <option value={""} disabled>{label}</option>
                        {
                            options.map((item, index) => (
                                <option key={label + "_" + index}>{item}</option>
                            ))
                        }
                    </select>
                    <label
                        htmlFor="exampleFormControlInputText"
                        className={`absolute ${style ? "top-[-12px] text-[13px] opacity-100" : "top-[18%] text-[18px] opacity-0"} text-gray font-thin left-3 w-full duration-200 z-[-1] hover:cursor-text`}
                    >
                        {label}
                    </label>
                </div>
            </div>
            <div className='mt-[6px]'>
                <div className='relative w-full h-[2px] rounded-full overflow-hidden bg-gray-400'>
                    <div className={`${style ? "w-full" : "w-0"} h-full top-0 left-0 duration-200 bg-[#452979]`}></div>
                </div>
            </div>
            {error && <div className="absolute text-xs mt-1 flex gap-4 w-full justify-between">
                <p className="text-[red] font-thin">{error && errorMessage}</p>
                <p className="text-[#452979]">{validationMessage}</p>
            </div>}
        </div>
    );
}