import React, { useEffect } from 'react'
import Lottie from "lottie-react";
import animationData from '../../assets/coming-soon.json';
import Global from './Global';
const CommingSoon = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Global>
      <section className="w-full min-h-screen h-full flex items-center justify-center p-4">
          <Lottie animationData={animationData} height={500} width={500} />
      </section>
    </Global>
  )
}

export default CommingSoon