import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Thankyou from './Thankyou';

const dataArr = [
    {
        image: "",
        body: "Avail Loans at",
        heading: "Attractive Interest Rate",
    },
    {
        image: "",
        body: "Enjoy Transparency with",
        heading: "No Hidden Charges",
    },
    {
        image: "",
        body: "Get instant funds with a",
        heading: "Quick Approval",
    },
    {
        image: "",
        body: "Hassle-free process with",
        heading: "Minimum Documentation",
    },
]

export default function FormTempelate({ children, image, isSubmitted }) {
    let navigation = useNavigate();
    const location = useLocation();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
        setIsFormSubmitted(isSubmitted);
    }, [isSubmitted]);

    if(isFormSubmitted){
        return <Thankyou/>
    }

    return (
        <div className='min-h-screen h-full flex justify-center'>
            <div className='max-w-[1800px] h-full flex flex-col w-full'>
                <div className='flex min-h-screen w-full h-full'>
                    <div className='w-[70%] p-8 flex flex-col'>
                        <img onClick={() => {
                            navigation('/');
                        }} className='w-[220px] cursor-pointer' src={require("../../assets/logo.png")} />
                        {children}
                    </div>
                    <div className={"w-[30%] "}>
                            <img className='w-full h-full object-cover' src={image} />
                    </div>
                </div>
                <div>
                    <div className='flex justify-center px-16 py-16 bg-gray-300'>
                        <div className='w-[max-content] flex flex-col gap-8'>
                            <h1 className='text-[28px] font-semibold text-[#452979]'>Achieve your every business goal with best-in-industry benifits.</h1>
                            <div className='grid grid-cols-4 gap-20'>
                                {
                                    dataArr.map((item, index) => (
                                        <div key={`benifit_${index}`} className='w-full flex gap-4 items-center'>
                                            {/* <img/> */}
                                            <div className='border-2 border-dashed animate-spin-slow duration-700 rounded-full p-1 border-[#452979]'>
                                                <div className='w-[60px] h-[60px] bg-[#452979] rounded-full'></div>
                                            </div>
                                            <div>
                                                <p className='text-[#452979] font-normal'>{item.body}</p>
                                                <p className='text-[18px] font-semibold text-[#452979]'>{item.heading}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center px-16 pt-16 pb-6'>
                        <div className='grid grid-cols-3 gap-20'>
                            <div className='flex flex-col items-center gap-2'>
                                <p className='text-[22px] font-semibold text-center'>Interest Rate</p>
                                <p className='text-center w-[90%] text-[17px] text-gray-500'>APR ranging from 15% to 18% | One time processing Fee starting from 2%</p>
                            </div>
                            <div className='flex flex-col items-center gap-2 text-center'>
                                <p className='text-[22px] font-semibold'>Repayment</p>
                                <p className='text-center w-[90%] text-[17px] text-gray-500'>Flexible repayment tenure from 1 year to 3 years.</p>
                            </div>
                            <div className='flex flex-col items-center gap-2 text-center'>
                                <p className='text-[22px] font-semibold'>Example</p>
                                <p className='text-center w-[90%] text-[17px] text-gray-500'>Loan Amount Rs.10 lakh, Interest Rate: 15%, Tenure: 3 years, equates to EMI of Rs.34,665 per month.</p>
                            </div>
                        </div>
                    </div>
                    <p className='px-16 py-8 text-[17px] text-gray-500'>
                        This rate of interest is prone to fluctuation basis of our internal credit and risk policy, and as per an algorithmic variate and score card.
                    </p>
                    <p className='px-16 pb-8 text-right text-[22px] font-bold'>
                        *T&C Apply
                    </p>
                </div>
            </div>
        </div >
    );
}
