import React, { useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react';
import { publicAxios } from '../Api';
import Loader from '../components/Global/Loader';

export const UserContext = createContext();

export default function UserState({ children }) {

    const [data, setData] = useState();
    const [loader, setLoader] = useState(true);

    const fetchUser = async () => {
        setLoader(true);
        publicAxios.get("/api/user").then((res) => {
            if(res.data){
                setData(res.data);
            }
            setLoader(false);
        }).catch((err) => {
            setLoader(false);
        })
    }

    const logoutUser = async () => {
        publicAxios.get("/auth/google/logout").then(() => {
            setData();
        }).catch(() => {

        });
    }

    useEffect(() => {
        fetchUser();
    }, []);

    if(loader){
        return <Loader/>
    }

    return (
        <UserContext.Provider value={{ data, loader, setData, fetchUser, logoutUser }}>
            {children}
        </UserContext.Provider>
    )
}
