import React, { useState } from "react";
import hero from "../../assets/hero.png";
import Button from "../../components/Global/Button";
import MainImg from "../../assets/newLanding/Iphones.png";
import MainPhoneRings from "../../assets/newLanding/GroupIphoneRings.png";
import MainPhoneRingsWhite from "../../assets/newLanding/MainPhoneRingsWhite.png";
import MainPhoneRingsBlack from "../../assets/newLanding/MainPhoneRingsBlack.png";
import SwitchCircle, { Circle } from "../../components/Global/Circle";

const HeroSection = (mode) => {

  return (
    <section className="min-h-screen bg-transparent h-full flex items-center justify-center relative" >
      {/* Circle 1 */}
      <Circle radius={"w-[300px] h-[300px]"} top={"-80px"} left={"-70px"} color={"#452979"} />

      {/* Content */}
      <div className="w-full max-w-screen-2xl mx-auto mt-[1rem] h-full flex items-center justify-center">
        <div className="w-full h-full flex items-center pt-[4rem] justify-center">
          <div className="w-full h-full flex items-center py-4 px-4 justify-center gap-4 flex-col-reverse md:flex-row">
            <div className="w-full h-full flex flex-col md:flex-row justify-center items-center py-2 px-6">
              <div className="w-full md:w-[60%] flex flex-col justify-start items-start gap-3">
                <div className="w-full flex items-start flex-col justify-start">
                  <div className="w-full flex items-start flex-col justify-start">
                    <h1 className="text-4xl sm:text-5xl  md:text-4xl  lg:text-5xl xl:text-7xl leading-[1.5] md:leading-[1.4] xl:leading-[1.2] font-[550]" style={{ color: mode.dark ? "white" : "black" }}>
                      Strengthening{" "}<br />
                      <span className="text-[#2281ff] text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-[500]">
                        The foundations for your{" "}
                      </span>{" "}<br />
                      <span className=" font-[550]">
                        Financial stability
                      </span>
                    </h1>
                  </div>
                </div>
                <div className="flex items-start w-full justify-start">
                  <p className="text-[#626262] text-base lg:text-lg py-2 font-[550]">
                    Discover the best solution with comprehensive tools,
                    empowering you to overcome financial challenges and unlock
                    the potential for a brighter, more prosperous future.
                  </p>
                </div>
                <Button text="Start Now" />
              </div>
              <div className="w-full h-full md:w-[40%] flex items-center justify-end">
                <div className="flex h-[20rem] md:h-[24rem] lg:h-[30rem] xl:h-[36rem] items-center relative justify-center">
                  <img
                    src={MainImg}
                    alt="banner Img"
                    className="absolute top-0 bottom-0 left-0 right-0 h-full w-full object-contain"
                  />
                  <img
                    className="flex w-full h-full items-center justify-center transition-all"
                    src={`${!mode.dark ? MainPhoneRingsBlack : MainPhoneRingsWhite}`}
                    alt="banner Img"
                  />

                  {/* Circle 2 */}
                  <SwitchCircle radius={"w-[800px] h-[800px]"} center={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default HeroSection;
