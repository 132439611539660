import React from "react";
import img2 from "../../assets/newLanding/image1.png";
import { Circle } from "../Global/Circle";

const LoanSection = (mode) => {
  
  
  return (
    <section className="w-screen min-h-screen h-full flex flex-col items-center gap-12 justify-center" >
      <div className="w-full max-w-screen-2xl mx-auto mt-[1rem] h-full flex items-center justify-center">
        <div className="w-full h-full flex items-center justify-center">
          <div className="h-full w-full flex relative md:flex-row flex-col items-center justify-center">
            <div className="h-full w-full md:w-[40%] flex flex-col justify-center items-center relative">
              <img
                className="w-[20rem] h-[20rem] lg:w-[30rem] lg:h-[30rem] xl:h-[40rem] xl:w-[40rem]"
                src={img2}
                alt="logo"
              />
              <Circle radius={"w-[300px] h-[300px]"} top={"0px"} left={"0px"} color={"#2181FF"} />
            </div>
            <div className="md:w-[60%] w-full flex flex-col justify-center items-center gap-10">
              <div className="leading-[1.5] md:leading-[1.4] xl:leading-[1.2] text-center">
                <h1 className="text-[2.5rem] md:text-[3rem] lg:text-[4rem] xl:text-[4.89rem] text-[#2181ff]">
                  Embrace Financial
                </h1>
                <h1 className="text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] xl:text-[2.87rem] md:text-left " style={{color:mode.dark?"white":"black"}}>
                  Transformation with activpaisa
                </h1>
              </div>
              <div className="flex flex-col justify-center items-center">
                <ul className="ml-[1.5rem] list-disc text-[#626262] text-base lg:text-lg w-[70%]">
                  <li>
                    With the help of our complete set of solutions, we aim at
                    providing a streamlined and all-inclusive platform that
                    makes it easier for you to find loan options
                  </li>
                  <li>
                    With the right tools, knowledge, and assistance you require,
                    Activpaisa can help you manage your assets, plan for the
                    future, or get individualised financial advice
                  </li>
                  <li>
                    With more than 100 experts on board, we guarantee you the
                    best loan offer based on your requirements
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoanSection;
