import React, { useContext, useEffect } from 'react'
import { DarkModeContext } from '../../context/DarkModeState';
import thankyou from "../../assets/Loans/thankyouPage.svg";
import { HiArrowNarrowRight } from 'react-icons/hi'
import { useNavigate } from 'react-router';
// import { Header } from 'antd/es/layout/layout';
import Header from '../Global/Header';

export default function Thankyou() {
    const { darkMode } = useContext(DarkModeContext);
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        let timer = setTimeout(() => {
            navigate('/');
        }, 5000);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <>
            {/* <Header/> */}
            <Header visible={true} />
            <main className={"h-screen w-screen flex flex-col gap-5 items-center justify-center " + (darkMode ? "bg-black" : "bg-white")}>
                <div className='h-96 w-96'>
                    <img src={thankyou} alt='thankyou' className='h-full w-full' />
                </div>
                <h2 className={'font-medium text-xl ' + (darkMode ? "text-white" : "text-black")}>We will notify you, Our Main website will be ready soon! 😃</h2>
                <button onClick={() => {
                    navigate('/');
                }} className='bg-[#452979] border-2 border-[#452979] hover:bg-white hover:text-[#452979] duration-300 text-white rounded-full px-4 text-lg py-2 flex justify-between items-center gap-3'>Return to Home <HiArrowNarrowRight className='font-bold' /></button>
            </main>
        </>
    )
}
