import React from "react";
import testimonial1 from "../../assets/newLanding/testimonial-01.jpg.png";
import testimonial2 from "../../assets/newLanding/testimonial-new-02.jpg.png";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
// import { SwitchCircleLight } from "../Global/Circle";

const TestimonialsSection = (mode) => {
  return (
    <section className="w-screen min-h-screen h-full flex items-center justify-center">
      <div className="w-full max-w-screen-2xl mx-auto  h-full flex items-center justify-center">
        <div className="w-full h-full flex items-center p-4 justify-center">
          <div className="w-full h-full flex items-center justify-center flex-col lg:px-0 px-8 gap-8">
            <div className="flex w-full flex-col justify-center gap-16 h-[50vh] relative">
              {/* <SwitchCircleLight radius={"w-[400px] h-[400px]"} top={"0px"} left={"95%"} />
              <SwitchCircleLight radius={"w-[300px] h-[300px]"} top={"100%"} left={"95%"} /> */}
              <div className="text-[2rem] tracking-tight leading-[1.5] md:leading-[1.4] xl:leading-[1.2] text-center font-semibold">
                <h1 className="text-[2.5rem] md:text-[3rem] lg:text-[3.5rem] 2xl:text-[4rem] text-[#2e88fe]">
                  Voices of Trust:
                </h1>
                <h1 className="text-[2rem] lg:text-[2.5rem] xl:text-[3rem] text-black" style={{ color: mode.dark ? "white" : "black" }}>
                  Hear What Our Clients have to Say
                </h1>
              </div>
              <div className="flex text-white justify-center gap-24 items-center ">
                <div className="w-[1.8rem] h-[9.8rem] bg-[#606060] rounded-r-2xl "></div>
                <div className="flex items-center justify-center gap-4 w-full h-full">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    className="h-full  gap-4 w-full"
                    loop={true}
                    autoplay={{ delay: 2500 }}
                    centeredSlides={true}
                    initialSlide={1}
                    breakpoints={{
                      300: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },

                    }}
                  >
                    <SwiperSlide className="flex items-center justify-center lg:px-6 px-0">
                      <div className={`flex w-full flex-col ${mode.dark ? "bg-[#d9d9d9] text-black" : "bg-[#626262]"}  xl:p-6 p-4 gap-5 rounded-2xl`}>
                        <div className="flex">
                          <div className="flex h-fit justify-between  w-full items-center ">
                            <img src={testimonial2} alt="logo" className="xl:w-[80px] xl:h-[80px] lg:w-[70px] lg:h-[70px]  h-[60px] w-[60px]" ></img>
                            <div className="flex xl:flex-row flex-col gap-2 justify-center">
                              <div className="flex flex-col">
                                <h1>Richard Scott</h1>
                                <p className="text-[10px]">Managing Director</p>
                              </div>
                              <div className="flex xl:text-[1.4rem] text-lg">
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiOutlineStar />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="xl:text-[15px] text-xs text-center">
                          <p>
                            “I loved the customer service you guys provided me. That
                            was very nice and patient with questions I had. I would
                            really like definitely come back here. Thank you for yours
                            service.”
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="flex items-center justify-center lg:px-6 px-0">
                      <div className={`flex w-full flex-col ${mode.dark ? "bg-[#d9d9d9] text-black" : "bg-[#626262]"}  xl:p-6 p-4 gap-5 rounded-2xl`}>
                        <div className="flex">
                          <div className="flex h-fit justify-between  w-full items-center ">
                          <img src={testimonial1} alt="logo" className="xl:w-[80px] xl:h-[80px] lg:w-[70px] lg:h-[70px]  h-[60px] w-[60px]" ></img>
                            <div className="flex xl:flex-row flex-col gap-2 justify-center">
                              <div className="flex flex-col">
                                <h1>Richard Scott</h1>
                                <p className="text-[10px]">Managing Director</p>
                              </div> 
                              
                              <div className="flex xl:text-[1.4rem] text-lg">
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiOutlineStar />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="xl:text-[15px] text-xs text-center">
                          <p>
                          “We came out of their offices very happy with their service. They treated us very kind. Definite will come back. The waiting time was very appropriate. The representative helping was fantastic.”
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="flex items-center justify-center lg:px-6 px-0">
                      <div className={`flex w-full flex-col ${mode.dark ? "bg-[#d9d9d9] text-black" : "bg-[#626262]"}  xl:p-6 p-4 gap-5 rounded-2xl`}>
                        <div className="flex">
                          <div className="flex h-fit justify-between  w-full items-center ">
                          <img src={testimonial2} alt="logo" className="xl:w-[80px] xl:h-[80px] lg:w-[70px] lg:h-[70px]  h-[60px] w-[60px]" ></img>
                            <div className="flex xl:flex-row flex-col gap-2 justify-center">
                              <div className="flex flex-col">
                                <h1>Richard Scott</h1>
                                <p className="text-[10px]">Managing Director</p>
                              </div>
                              <div className="flex xl:text-[1.4rem] text-lg">
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiOutlineStar />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="xl:text-[15px] text-xs text-center">
                          <p>
                            “I loved the customer service you guys provided me. That
                            was very nice and patient with questions I had. I would
                            really like definitely come back here. Thank you for yours
                            service.”
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="flex items-center justify-center lg:px-6 px-0">
                      <div className={`flex w-full flex-col ${mode.dark ? "bg-[#d9d9d9] text-black" : "bg-[#626262]"}  p-6 gap-5 rounded-2xl`}>
                        <div className="flex">
                          <div className="flex h-fit justify-between w-full items-center ">
                            <div className="flex gap-2">
                              <img src={testimonial1} alt="testimonial"></img>
                              <div className="flex flex-col gap-1 justify-center">
                                <h1>Richard Scott</h1>
                                <p className="text-[10px]">Managing Director</p>

                              </div>
                            </div>
                            <div className="flex xl:text-[1.4rem] text-lg">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiOutlineStar />
                            </div>
                          </div>
                        </div>
                        <div className="xl:text-[15px] text-xs text-center">
                          <p>
                            “I loved the customer service you guys provided me. That
                            was very nice and patient with questions I had. I would
                            really like definitely come back here. Thank you for yours
                            service.”
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="flex items-center justify-center lg:px-6 px-0">
                      <div className={`flex w-full flex-col ${mode.dark ? "bg-[#d9d9d9] text-black" : "bg-[#626262]"}  p-6 gap-5 rounded-2xl`}>
                        <div className="flex">
                          <div className="flex h-fit justify-between w-full items-center ">
                            <div className="flex gap-2">
                              <img src={testimonial1} alt="testimonial"></img>
                              <div className="flex flex-col gap-1 justify-center">
                                <h1>Richard Scott</h1>
                                <p className="text-[10px]">Managing Director</p>
                              </div>
                            </div>
                            <div className="flex xl:text-[1.4rem] text-lg">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiOutlineStar />
                            </div>
                          </div>
                        </div>
                        <div className="xl:text-[15px] text-xs text-center">
                          <p>
                            “I loved the customer service you guys provided me. That
                            was very nice and patient with questions I had. I would
                            really like definitely come back here. Thank you for yours
                            service.”
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  
                </div>
                <div className="w-[1.8rem] h-[9.8rem] bg-[#606060] rounded-l-2xl "></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
