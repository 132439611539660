import React from "react";
import platfrom from "../../assets/platform.png";
import Button from "../../components/Global/Button";
import BusinessGrowth from "../../assets/newLanding/BusinessGrowth.png";
import SwitchCircle from "../Global/Circle";


const PlatformSection = (mode) => {
  return (
    <section className="w-screen min-h-screen h-full flex items-center justify-center">
      <div className="w-full max-w-screen-2xl mx-auto h-full flex items-center justify-center">
        <div className='flex h-full w-full items-center md:flex-row flex-col justify-center gap-1'>
          <div className='w-full md:w-[40%] flex items-center justify-center'>
            <img className='w-[20rem] h-[20rem] lg:w-[30rem] lg:h-[30rem] xl:h-[36rem] xl:w-[36rem]' src={BusinessGrowth} alt="logo" />
          </div>
          <div className='w-full md:w-[60%] flex flex-col justify-center items-center gap-10 relative'>
            <SwitchCircle radius={"w-[700px] h-[700px]"} center={true} />
            <div className='leading-[1.5] md:leading-[1.4] xl:leading-[1.2] flex p-1'>
              <h1 className='text-[1.8rem] md:text-[2.4rem] lg:text-[3rem] xl:text-[3.8rem]' style={{ color: mode.dark ? "white" : "black" }}>Unlock{" "}<span className='text-[#2181ff]'>Your Potential</span>{" "}<br />as an activpaisa Agent</h1>
            </div>
            <div className='flex flex-col justify-center items-center w-[70%] gap-6 py-2'>
              <div className='p-3 text-sm xl:text-base  font-[500] rounded-2xl' style={{ color: mode.dark ? "black" : "white", backgroundColor: mode.dark ? "#cbcccf" : "#373737" }}>
                <p className=''>Activpaisa offers loan management agents personalized perks and tools to thrive in their careers. With a user-friendly platform, comprehensive solutions, and professional support, agents can optimize workflows, enhance productivity, and excel in loan management.</p>
              </div>
              <div className=' p-3 text-sm xl:text-base font-[500] rounded-2xl' style={{ color: mode.dark ? "black" : "white", backgroundColor: mode.dark ? "#cbcccf" : "#373737" }}>
                <p>Activpaisa's platform supports both experienced and new loan management agents, providing them with the resources and guidance to excel in their careers. By joining the agent community, individuals can explore opportunities for career advancement and achieve remarkable success in the dynamic loan management industry.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlatformSection;
