import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import HeroSection from '../components/LandingPage/HeroSection';
import LoanSection from '../components/LandingPage/LoanSection';
import PlatformSection from '../components/LandingPage/PlatformSection';
import OfferSection from '../components/LandingPage/OfferSection';
import CalculateSection from '../components/LandingPage/CalculateSection';
import ApplicationSection from '../components/LandingPage/ApplicationSection';
import BackingSection from '../components/LandingPage/BackingSection';
import TeamsSection from '../components/LandingPage/TeamsSection';
import TestimonialsSection from '../components/LandingPage/TestimonialsSection';
import DownloadSection from '../components/LandingPage/DownloadSection';
import CollaborationSection from '../components/LandingPage/CollaborationSection';
import { DarkModeContext } from '../context/DarkModeState';
import { BsFillSunFill, BsMoonFill } from 'react-icons/bs';
import Support from '../components/LandingPage/Support';
import Benefits from '../components/Global/Benefits';
import CardsSection from '../components/LandingPage/CardsSection';
import { IoCloudyNight, IoCloudyNightOutline } from 'react-icons/io5';
import { WiDayHaze } from "react-icons/wi";

const LandingPage = () => {
  let darkMode = useContext(DarkModeContext);
  const [dark, setDark] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setDark(darkMode.darkMode);
  }, [darkMode.darkMode]);

  return (
    <main className='relative bg-transparent overflow-x-hidden'>
      <div className={`absolute top-0 left-0 w-full h-full ${dark ? "bg-black" : "bg-white"} z-[-5]`}></div>
      <div className={`absolute top-0 left-0 w-full h-full ${dark ? "bg-[#000B28]" : "bg-[#F8F8F8]"} opacity-70 blur-[50px] z-[-1]`}></div>
      <HeroSection dark={dark} />
      <LoanSection dark={dark} />
      <OfferSection dark={dark} />
      <PlatformSection dark={dark} />
      <Benefits dark={dark} />
      <CalculateSection dark={dark} />
      <CardsSection dark={dark} />
      <ApplicationSection dark={dark} />
      <Support mode={dark} />
      <BackingSection dark={dark} />
      <TeamsSection dark={dark} />
      <CollaborationSection dark={dark} />
      <TestimonialsSection dark={dark} />
      <DownloadSection dark={dark} />

      <div className='fixed top-[1.16rem] left-[9.6rem] xxs:top-8 md:left-[14rem] xxs:left-[12rem] z-[1000] hover:cursor-pointer text-[1.2rem]' onClick={() => {
          darkMode.setDarkMode(!darkMode.darkMode);
        }} >
        {!dark?<IoCloudyNight />:<WiDayHaze className='text-[white] text-[1.6rem]'   />}
      </div>
      {/* <div className={`fixed bottom-3 right-0 border-l  border-t border-b ${dark ? "border-white" : "border-black"} rounded-s-lg  p-2 z-[13] flex items-center gap-3`}>
        {dark ? <BsMoonFill size={22} color='white' /> : <BsFillSunFill size={25} />}
        <div onClick={() => {
          darkMode.setDarkMode(!darkMode.darkMode);
        }} className={`w-[60px] border cursor-pointer rounded-full p-[1px] flex ${dark ? "justify-end border-white" : "justify-start border-black"}`}>
          <div className={`w-[25px] h-[25px] ${dark ? "bg-white" : "bg-black"} rounded-full`}></div>
        </div>
      </div> */}
    </main>
  )
}

export default LandingPage