import React from "react";
// import logo1 from "../../assets/logos/1.png";
// import logo2 from "../../assets/logos/2.png";
// import logo3 from "../../assets/logos/3.png";
// import logo4 from "../../assets/logos/4.png";
// import logo5 from "../../assets/logos/5.png";
// import logo6 from "../../assets/logos/6.png";
// import logo7 from "../../assets/logos/7.png";
// import logo8 from "../../assets/logos/8.png";
// import logo9 from "../../assets/logos/9.png";
// import logo10 from "../../assets/logos/10.png";
// import logo11 from "../../assets/logos/11.png";
// import logo12 from "../../assets/logos/12.png";
// import join from "../../assets/join.png";
import Button from "../../components/Global/Button";
import unsplash from "../../assets/newLanding/unsplash_UpsEF48wAgk.png";
import SwitchCircle, { SwitchCircleLight } from "../Global/Circle";

const BackingSection = (mode) => {
  return (
    <section className="w-screen min-h-screen h-full flex items-center justify-center">
      <div className="w-full mx-auto mt-[1rem] h-full flex items-center justify-center flex-col gap-12 relative">
        <SwitchCircle radius={"w-[300px] h-[300px]"} top={"-90px"} left={"-100px"} />
        {/* <div className="flex flex-col justify-center w-full h-full gap-2 relative">
          <SwitchCircle radius={"w-[300px] h-[300px]"} top={"40%"} left={"85%"} />
          <h1 className="text-[2.5rem] font-[550] self-center mb-[2rem] p-2" style={{ color: mode.dark ? "white" : "black" }}>
            Powerful <span className="text-[#2e88fe]">Collaborations</span> for
            Better Solutions
          </h1>
          <div className={`flex flex-col justify-center items-center ${mode.dark ? "bg-[#f1f1f1]" : "bg-[#3e3e3e]"}  py-8 gap-[2rem] `} >
            <div className="grid xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-3 max-w-screen-xl mx-auto grid-cols-2 justify-center gap-4 p-2 w-full">
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo1} alt="1" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo2} alt="2" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo3} alt="3" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo4} alt="4" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo5} alt="5" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo6} alt="6" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo7} alt="7" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo8} alt="8" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo9} alt="9" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo10} alt="10" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo11} alt="11" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
              <div className="flex items-center justify-center grid-cols-1 ">
                <img src={logo12} alt="12" className="w-44 rounded-lg border-[2px] border-dashed border-[#ababab]" />
              </div>
            </div>
          </div>
        </div> */}

        <div className="w-full max-w-screen-xl h-full flex items-center justify-center">
          <SwitchCircleLight radius={"w-[55vw] h-[55vw]"} top={"20vw"} left={"-20vw"} />
          <div className="w-full h-full flex items-center justify-center">
            <div className="flex md:flex-row flex-col md:justify-between xl:px-0 px-12 justify-center items-center p-2 gap-2 w-full h-full">
              <div className="w-full md:w-[50%] flex justify-start items-center">
                <img className="2xl:w-[28rem] w-[30vw]" src={unsplash} alt="unsplash"></img>
              </div>
              <div className="w-full md:w-[50%] flex justify-center items-center flex-col gap-[1.8rem]">
                <div className="font-[550] leading-[4rem] text-center">
                  <h1 className="lg:text-[3.5rem] text-[2.5rem] xl:text-[4rem] 2xl:text-[4.7rem]" style={{ color: mode.dark ? "white" : "black" }}>Join Our Team!</h1>
                  <h1 className="lg:text-[1.8rem] text-2xl xl:text-[2rem] 2xl:text-[2.5rem]" style={{ color: mode.dark ? "white" : "black" }}>
                    Be a part of{" "}
                    <span className="text-[#2e88fe]">Our Success Story</span>
                  </h1>
                </div>
                <p className={`w-full xl:w-[70%] text-[400] ${mode.dark ? "text-[#8c8d92]" : "text-[#626262]"}  xl:text-[15.5px] text-sm text-center`}>
                  Activpaisa empowers agents to excel and succeed. We provide
                  comprehensive tools, resources, and support to navigate the
                  evolving marketplace. Our platform offers advanced technology,
                  industry expertise, and a collaborative community for tangible
                  outcomes. Together, we elevate real estate excellence and
                  agent prosperity.
                </p>
                <div className="flex items-center justify-center gap-2 lg:gap-[4.5rem] w-full lg:flex-row flex-col">
                  <input
                    className=" w-[17rem] xl:text-base text-sm rounded-lg p-[8px] h-fit"
                    placeholder="Email.."
                    type="text"
                  />
                  <Button text="Get Started" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BackingSection;
