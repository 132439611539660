import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import facebook from '../../assets/socials/facebook.png'
import instagram from '../../assets/socials/instagram.png'
import twitter from '../../assets/socials/twitter.png'
import pinterest from '../../assets/socials/pinterest.png'
import { Link } from "react-router-dom";
import { BsArrowRight } from 'react-icons/bs'
import { DarkModeContext } from "../../context/DarkModeState";
import SwitchCircle, { SwitchCircleLight } from "./Circle";

const Footer = () => {
  let darkmode = useContext(DarkModeContext);
  const [dark, setDark] = useState(false);

  useEffect(() => {
    setDark(darkmode.darkMode);
  }, [darkmode.darkMode]);
  return (
    <footer className={`w-full h-full flex items-center bg-transparent relative justify-center backdrop-blur-3xl overflow-x-hidden`}>
      <div className={`absolute top-0 left-0 w-full h-full ${dark ? "bg-black" : "bg-white"} z-[-5]`}></div>
      <div className={`absolute top-0 left-0 w-full h-full ${dark ? "bg-[#000B28]" : "bg-[#F8F8F8]"} opacity-70 blur-[50px] z-[-2]`}></div>
      <SwitchCircleLight radius={"w-[500px] h-[500px]"} left={"0px"} top={"0px"} />
      <SwitchCircleLight radius={"w-[500px] h-[500px]"} left={"80%"} top={"0px"} />
      <div style={{
        background: dark ? "linear-gradient(180deg, rgba(0, 11, 40, 0.50) 0%, rgba(0, 11, 40, 0.00) 100%), linear-gradient(270deg, rgba(63, 15, 125, 0.50) 34.05%, rgba(18, 49, 120, 0.50) 92.99%)" : "linear-gradient(180deg, rgba(255, 255, 255, 0.50) 0%, rgba(0, 11, 40, 0.00) 100%), linear-gradient(270deg, rgba(63, 15, 125, 0.30) 0%, rgba(18, 49, 120, 0.30) 92.99%)",
        color: dark ? "#fff" : "#000"
      }} className="flex items-center justify-center w-full relative">
        <div className="flex items-center justify-center w-full flex-col p-6 gap-2">
          <div className="flex w-full  items-center justify-center border border-b-black/50 border-t-0 border-l-0 border-r-0 lg:p-6 p-4">
            <div className="w-full flex items-center justify-between flex-col md:flex-row ">
              {/* <div className="flex items-start justify-start w-full"> */}
                <img src={logo} alt="logo" className="xl:w-80 lg:w-[230px] w-48 object-fill" />
              {/* </div> */}
              <div className="grid w-full max-w-3xl md:grid-cols-3 s:grid-cols-2 grid-cols-1 items-start justify-end p-4">
                <div className="flex items-start justify-start xl:gap-4 gap-2 flex-col">
                  <div className="flex  w-full items-center justify-start xxs:px-4 py-1">
                    <h1 style={{
                      color: dark ? "#fff" : "#000"
                    }} className="xl:text-2xl text-xl font-bold  py-4 relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      Company
                    </h1>
                  </div>
                  <Link to={"/about"} className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className=" xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} About Us
                    </p>
                  </Link>
                  <div style={{
                    color: dark ? "#fff" : "#000"
                  }} className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p className=" xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Company
                    </p>
                  </div>
                  <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className="xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Press & Blog
                    </p>
                  </div>
                  <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className=" xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Privacy Policy
                    </p>
                  </div>
                </div>
                <div className="flex items-start justify-start xl:gap-4 gap-2 flex-col">
                  <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <h1 style={{
                      color: dark ? "#fff" : "#000"
                    }} className=" xl:text-2xl text-xl font-bold  py-4 relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      About
                    </h1>
                  </div>
                  <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className=" xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Business Insurance
                    </p>
                  </div>
                  <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className="xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Fund Management
                    </p>
                  </div>
                  <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className=" xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Stock Custodian
                    </p>
                  </div>
                  <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className="xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Business Management
                    </p>
                  </div>
                </div>
                <div className="flex items-start justify-start xl:gap-4 gap-2 flex-col">
                  <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <h1 style={{
                      color: dark ? "#fff" : "#000"
                    }} className="xl:text-2xl text-xl font-bold  py-4 relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      Resources
                    </h1>
                  </div>
                  <Link to={"/personal-loan"} className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className="xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Personal Loan
                    </p>
                  </Link>
                  <Link to={"/home-loan"} className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className="xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Home Loan
                    </p>
                  </Link>
                  <Link to={"/preowned-car-loan"} className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className="xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Car Loan
                    </p>
                  </Link>
                  <Link to={"/business-loan"} className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                    <p style={{
                      color: dark ? "#fff" : "#000"
                    }} className="xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#ffaa01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                      {">"} Business Loan
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start p-6 w-full">
            <div className="grid lg:grid-cols-4 md:grid-cols-3 w-full s:grid-cols-2 grid-cols-1 items-start justify-center xl:gap-4 gap-2">
              <div className="flex items-center justify-start w-full xs:px-4">
                <p style={{
                  color: dark ? "#fff" : "#000"
                }} className="xl:text-sm text-xs">Copyright © 2019 All Rights Reserved.</p>
              </div>
              <div className="flex items-center justify-center w-full flex-col lg:gap-3 gap-2">
                <div className="flex  w-full  items-center justify-start xs:px-4 ">
                  <h1 style={{
                    color: dark ? "#fff" : "#000"
                  }} className="xl:text-2xl text-xl font-bold   relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#FFAA01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                    Contact Us
                  </h1>
                </div>
                <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                  <p style={{
                    color: dark ? "#fff" : "#000"
                  }} className="xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#FFAA01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                    {">"} Work With us
                  </p>
                </div>
                <div className="flex  w-full  items-center justify-start xxs:px-4 py-1">
                  <p style={{
                    color: dark ? "#fff" : "#000"
                  }} className="whitespace-nowrap xl:text-base lg:text-sm text-xs relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#FFAA01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                    {">"} needhelp@mentry.com
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center w-full flex-col gap-3">
                <div className="flex  w-full  items-center justify-start xs:px-4 ">
                  <h1 style={{
                    color: dark ? "#fff" : "#000"
                  }} className="xl:text-2xl text-xl font-bold   relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#FFAA01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                    Follow Us
                  </h1>
                </div>
                <div className="flex  w-full flex-row gap-2 items-center justify-start xxs:px-4 py-1">
                  <Link to=""><img src={facebook} alt="Facebook" /></Link>
                  <Link to=""><img src={twitter} alt="twitter" /></Link>
                  <Link to=""><img src={instagram} alt="instagram" /></Link>
                  <Link to=""><img src={pinterest} alt="pinterest" /></Link>
                </div>
              </div>
              <div className="flex items-center justify-center w-full flex-col gap-3">
                <div className="flex  w-full  items-center justify-start xs:px-4 ">
                  <h1 style={{
                    color: dark ? "#fff" : "#000"
                  }} className="xl:text-2xl text-xl font-bold   relative block after:block after:content-[''] after:absolute after:h-[2px] cursor-pointer after:bg-[#FFAA01] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                    Subscribe Us
                  </h1>
                </div>
                <div className="flex  w-full flex-row gap-2 items-center justify-start xxs:px-4 py-1 relative">
                  <input type="text" placeholder="Your email address" value="" name="email" className="bg-white w-full xl:p-4 p-2 xl:text-base text-sm" />
                  <BsArrowRight className="absolute right-8 w-6 h-6 xs:flex hidden" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
