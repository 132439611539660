import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Global = ({children}) => {
  return (
    <>
    <Header />
      {children}
    <Footer/> 
    </> 
  )
}

export default Global