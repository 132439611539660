import React from 'react'
import { useNavigate } from 'react-router';

const Button = ({ text, handleClick=()=>{}, target, href, link = false }) => {
  let navigate = useNavigate();
  return (
    <div className="flex items-center justify-center p-1 ">
      {
        link ? <a onClick={() => {
          handleClick();
        }} target={target} href={href} className='text-white font-bold text-xs xl:text-sm bg-gradient-to-l from-[#003478] to-[#452979] hover:bg-gradient-to-l hover:from-white hover:to-white hover:text-[#003478] border border-transparent hover:border hover:border-[#452979] py-2 xl:px-8 px-6 2xl:px-10 rounded-full'>{text}</a> :
          <button onClick={() => {
            handleClick();
            navigate("/comingsoon");
          }} className="text-white font-bold text-xs xl:text-sm bg-gradient-to-l from-[#003478] to-[#452979] hover:bg-gradient-to-l hover:from-white hover:to-white hover:text-[#003478] border border-transparent hover:border hover:border-[#452979] py-2 xl:px-8 px-6 2xl:px-10 rounded-full">{text}</button>
      }
    </div>
  )
}

export default Button