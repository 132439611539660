import React, { useState } from "react";
import calculate from "../../assets/newLanding/gg.png";
import { AiFillHome, AiFillCar } from "react-icons/ai";
import { MdBusinessCenter } from "react-icons/md";
import { CiLink } from "react-icons/ci";
import { Progress } from "antd";

import SwitchCircle from "../Global/Circle";
import { useNavigate } from "react-router";

function LoanCalculator(mode) {
  const [loneAmount, setLoanAmount] = useState(0)
  const [InterestRate, setInterestRate] = useState(0)
  const [loneTime, setLoanTime] = useState(0)

  // const LoneAmountHandler = (e) => {
  //   setLoanAmount(e.target.value);
  // }
  // const InterestRateHandler = (e) => {
  //   setInterestRate(e.target.value);
  // }
  // const loneTimeHandler = (e) => {
  //   setLoanTime(e.target.value);
  // }

  let navigate = useNavigate();



  function calculateEMI(principal, annualInterestRate, tenureInYears) {
    const monthlyInterestRate = (annualInterestRate / 12) / 100;
    // const tenureInMonths = tenureInYears * 12;
    const emi = (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, tenureInYears)) /
      (Math.pow(1 + monthlyInterestRate, tenureInYears) - 1);
    return emi;
  }

  // const [emi,setEmi]=useState(0);

    

    const emi = calculateEMI(loneAmount, InterestRate, loneTime);
    const roundedemi = emi.toFixed(2)
  
  
  
    const tol = emi.toFixed(0) * loneTime;
    // const ToalAmountRounded = emi.toFixed(2) * loneTime;
  


  const princileForGraph = (loneAmount / tol) * 100;
  const princileForGraphInpercent = Math.round(princileForGraph);



  // console.log(princileForGraphInpercent)

  console.log(mode.loneUrl);

  return (
    <div className="flex flex-col w-full">
    <div className="flex flex-col sm:flex-row px-6 items-center justify-center w-full">
      <div className="w-full h-full relative -top-4  pt-12 pb-8 px-4 xxs:px-8 rounded-lg z-[10]">
        <p className="text-[22px] text-[#003478]" style={{ color: !mode.dark ? "#003478" : "white" }}>EMI Calculator for</p>
        <p className="text-[22px] font-semibold text-[#003478]" style={{ color: !mode.dark ? "#003478" : "white" }}>{mode.title}</p>
        <div className="py-6 flex flex-col gap-3">
          <div>
            <div className="flex justify-between" style={{ color: !mode.dark ? "black" : "white" }}>
              <p>Loan Amount (र)</p>
              {/* <p>{loneAmount}</p> */}
              <input className="w-28 text-right rounded-3xl px-2 text-black" type="number" min="0" value={loneAmount} onChange={(e)=>(setLoanAmount(e.target.value))}/>
            </div>
            <input
              className="w-full styled-slider slider-progress bg-transparent"
              type="range"
              onChange={(e)=>(setLoanAmount(e.target.value))}
              value={loneAmount}
              min="0"
              max="1000000"
            />
          </div>
          <div>
            <div className="flex justify-between" style={{ color: !mode.dark ? "black" : "white" }}>
              <p>Interest Rate (%)</p>
              <div>
              <input className="w-8 text-center rounded-3xl px-2 text-black" type="number" min="0" max="100" value={InterestRate} onChange={(e)=>(setInterestRate(e.target.value))}/><span>%</span>
              </div>
            </div>
            <input
              className="w-full styled-slider slider-progress bg-transparent"
              type="range"
              onChange={(e)=>(setInterestRate(e.target.value))}
              value={InterestRate}
            />
          </div>
          <div>
            <div className="flex justify-between" style={{ color: !mode.dark ? "black" : "white" }}>
              <p>Tenure (Months)</p>
              <input className="w-8 text-center rounded-3xl px-2 text-black outline-none" type="number" min="0" max="100" value={loneTime} onChange={(e)=>(setLoanTime(e.target.value))}/>
            </div>
            <input
              className="w-full styled-slider slider-progress bg-transparent"
              type="range"
              onChange={(e)=>(setLoanTime(e.target.value))}
              value={loneTime}
            />
          </div>
        </div>
        <div className="flex flex-col items-end w-full">
          <p className="text-[#003478] text-[18px]" style={{ color: !mode.dark ? "#003478" : "white" }}>Monthly EMI</p>
          <p className="text-[#5EBB46]">{roundedemi}</p>
        </div>
      </div>
      <div className="w-full lg:w-[70%]">
        <div className={`h-full flex flex-col items-center ${!mode.dark ? "bg-white" : "bg-[#303237]"} bg-white shadow-black shadow-md rounded-3xl p-4`}>
          <div className="relative">
            <Progress
              trailColor="rgba(56, 169, 241, 0.72)"
              strokeColor={{
                "0%": "rgba(69, 41, 121, 0.9)",
                "100%": "rgba(69, 41, 121, 0.9)",
              }}
              size={200}
              type="circle"
              percent={princileForGraphInpercent}
              format={(percent) => { }}
            />
            <div className="absolute top-0 w-full h-full flex flex-col justify-center items-center">
              <p>Total Amount</p>
              <p className="text-[rgba(30,99,189,1)]">{tol}</p>
            </div>
          </div>
          <div className="flex flex-col gap-3 mt-3">
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded-full bg-[rgba(69,41,121,0.9)]"></div>
              <p>Principal Amount</p>
            </div>
            <div className="flex items-center gap-3">
              <div className="w-4 h-4 rounded-full bg-[rgba(56,169,241,0.72)]"></div>
              <p>Total Intrest</p>
            </div>
          </div>
          <div className="mt-4 w-[60%]">
            {/* <Button onClick={() => { setEmi( calculateEMI(loneAmount, InterestRate, loneTime)) }} text="Calculate" /> */}
            <button className='text-white self-center w-[10rem] font-bold text-xs xl:text-sm bg-gradient-to-l from-[#003478] to-[#452979] hover:bg-gradient-to-l hover:from-white hover:to-white hover:text-[#003478] border border-transparent hover:border hover:border-[#452979] py-2 xl:px-8 px-6 2xl:px-10 rounded-full' onClick={() => { navigate(mode.loneUrl) }}>Apply</button>
          </div>
        </div>
      </div>
    </div>
      {/* <button className="text-white w-[80%] self-center font-bold text-xs xl:text-sm bg-gradient-to-l from-[#003478] to-[#452979] hover:bg-gradient-to-l hover:from-white hover:to-white hover:text-[#003478] border border-transparent hover:border hover:border-[#452979] py-2 xl:px-8 px-6 2xl:px-10 rounded-full">Apply Lone</button> */}
    </div>
  );
}

const CalculateSection = (mode) => {
  const [loanTitle, setLoanTitle] = useState("Home Loan");

  const [loneUrl,setLoneUrl]=useState("/business-loan");


  // const arr=[1,2,3,4,5];

  console.log(loanTitle);

  



  return (
    <section className="w-screen min-h-screen h-full flex flex-col items-center justify-center" id="calculator" >
      <div className="w-full max-w-screen-2xl mx-auto mt-[1rem] h-full flex items-center justify-center">
        <div className="w-full h-full flex items-center justify-center">
          <div className="w-full h-full flex items-center justify-center flex-col relative">
            <SwitchCircle radius={"w-[500px] h-[500px]"} left={"65%"} top={"-10%"} />
            <SwitchCircle radius={"w-[400px] h-[400px]"} left={"-10%"} top={"60%"} />
            <div className="w-full h-full flex gap-2 flex-col lg:flex-row">
              <div className="hidden lg:flex relative items-center justify-center w-full h-full lg:w-[50%]" >
                <img
                  src={calculate}
                  alt="calculate"
                  className="w-full lg:w-[100%]"
                />
                <div className="w-[75%] hidden absolute bottom-6 left-0 right-0 mx-auto lg:flex flex-col items-start justify-center">
                  <h1 className="text-2xl  font-semibold text-white whitespace-pre-wrap">Ready to discover
                    <span className="text-[#125BBA]"> your loan </span>
                    options?
                  </h1>
                  <p className="text-xl font-semibold text-white whitespace-pre-wrap">
                    Enter the amount you need to borrow and let us calculate your personalized rate
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start justify-center gap-4 w-full lg:w-[50%] ">
                <div className="flex items-center justify-around w-fit h-full md:flex-nowrap flex-wrap gap-2 flex-row overflow-x-scroll">
                  <div
                    onClick={() => {
                      setLoanTitle("Home Loan");
                    }}
                    className={`flex w-[12rem]  items-center flex-row justify-center px-4 py-2 lg:px-5 lg:py-3 gap-3 rounded-full ${mode.dark ? "bg-[#56585d] hover:bg-[#0d2436] text-white" : (loanTitle ==="Home Loan" ? "bg-[#2457C5] text-white":" bg-white text-black") }  hover:bg-[#2457C5] hover:text-white border border-[#757575] hover:cursor-pointer border-1  hover:border-2 hover:border-[#125BBA] group transition-all`}
                  >
                    <div className="flex items-center justify-start">
                      <AiFillHome className="lg:w-6 lg:h-6 w-4 h-4 group-hover:text-white" />
                    </div>
                    <div className="flex items-center justify-start" onClick={()=>{setLoneUrl("/home-loan")}}>
                      <p className="text-[0.5rem] lg:text-xs font-semibold">
                        Home Loan
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setLoanTitle("Car Loan");
                      setLoneUrl("/preowned-car-loan")
                    }}
                    className={`flex w-[12rem] items-center flex-row justify-center px-4 py-2 lg:px-5 lg:py-3 gap-3 rounded-full ${mode.dark ? "bg-[#56585d] hover:bg-[#0d2436] text-white" : (loanTitle ==="Car Loan" ? "bg-[#2457C5] text-white ":" bg-white text-black") } hover:bg-[#2457C5] hover:text-white  border border-[#757575] hover:cursor-pointer border-1  hover:border-2 hover:border-[#125BBA] group transition-all`}
                  >
                    <div className="flex items-center justify-start">
                      <AiFillCar className="lg:w-6 lg:h-6 w-4 h-4 group-hover:text-white " />
                    </div>
                    <div className="flex items-center justify-start">
                      <p className="text-[0.5rem]  lg:text-xs font-semibold">
                        Car Loan
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setLoanTitle("Business Loan");
                      setLoneUrl("/business-loan")
                    }}
                    className={`flex w-[12rem] items-center flex-row justify-center px-4 py-2 lg:px-5 lg:py-3 gap-3 rounded-full ${mode.dark ? "bg-[#56585d] hover:bg-[#0d2436] text-white" :  (loanTitle ==="Business Loan" ? "bg-[#2457C5] text-white ":" bg-white text-black")} hover:bg-[#2457C5] hover:text-white border border-[#757575] hover:cursor-pointer hover:border-2 hover:border-[#125BBA] group transition-all`}
                  >
                    <div className="flex items-center justify-start">
                      <MdBusinessCenter className="lg:w-6 lg:h-6 w-4 h-4 group-hover:text-white transition-all" />
                    </div>
                    <div className="flex items-center justify-start">
                      <p className="text-[0.5rem]  lg:text-xs font-semibold">
                        Business Loan
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setLoanTitle("Personal Loan");
                      setLoneUrl("/personal-loan")
                    }}
                    className={`flex w-[12rem] items-center flex-row justify-center px-4 py-2 lg:px-5 lg:py-3 gap-3 rounded-full ${mode.dark ? "bg-[#56585d] hover:bg-[#0d2436] text-white" : (loanTitle ==="Personal Loan" ? "bg-[#2457C5] text-white ":" bg-white text-black")} hover:bg-[#2457C5] hover:text-white border-[#757575] hover:cursor-pointer border hover:border-2 hover:border-[#125BBA] group transition-all`}
                  >
                    <div className="flex items-center justify-start">
                      <CiLink className="lg:w-6 lg:h-6 w-4 h-4 group-hover:text-white transition-all" />
                    </div>
                    <div className="flex items-center justify-start">
                      <p className="text-[0.5rem]  lg:text-xs font-semibold">
                        Personal Loan
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setLoanTitle("loan Against Property");
                      setLoneUrl("/loan-Against-Property")
                    }}
                    className={`flex w-[12rem] items-center flex-row justify-center px-4 py-2 lg:px-5 lg:py-3 gap-3 rounded-full ${mode.dark ? "bg-[#56585d] hover:bg-[#0d2436] text-white" : (loanTitle ==="loan Against Property" ? "bg-[#2457C5] text-white ":" bg-white text-black")} hover:bg-[#2457C5] hover:text-white border-[#757575] hover:cursor-pointer hover:border-[#125BBA] group transition-all`}
                  >
                    <div className="flex items-center justify-start">
                      <CiLink className="lg:w-6 lg:h-6 w-4 h-4 group-hover:text-white transition-all" />
                    </div>
                    <div className="flex items-center justify-start">
                      <p className="text-[0.5rem]  lg:text-xs font-semibold">
                      loan Against Property
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setLoanTitle("professional loan");
                      setLoneUrl("/professional-loan")
                    }}
                    className={`flex w-[12rem] items-center flex-row justify-center px-4 py-2 lg:px-5 lg:py-3 gap-3 rounded-full ${mode.dark ? "bg-[#56585d] hover:bg-[#0d2436] text-white" : (loanTitle ==="professional loan" ? "bg-[#2457C5] text-white ":" bg-white text-black")} hover:bg-[#2457C5] hover:text-white border-[#757575] hover:cursor-pointer hover:border-[#125BBA] group transition-all`}
                  >
                    <div className="flex items-center justify-start">
                      <CiLink className="lg:w-6 lg:h-6 w-4 h-4 group-hover:text-white transition-all" />
                    </div>
                    <div className="flex items-center justify-start">
                      <p className="text-[0.5rem]  lg:text-xs font-semibold">
                      professional loan
                      </p>
                    </div>
                  </div>
                </div>
                <LoanCalculator loneUrl={loneUrl} title={loanTitle} dark={mode.dark} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CalculateSection;
