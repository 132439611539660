import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper/modules";
import "swiper/css/bundle";
import "swiper/css/navigation";
import g10 from "../../assets/newLanding/personal.svg";
import g20 from "../../assets/newLanding/home.svg";
import g30 from "../../assets/newLanding/property.svg";
import g40 from "../../assets/newLanding/business.svg";
import SwitchCircle from "../Global/Circle";
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { useNavigate } from "react-router";


const cards = [
  {
    heading: "Business Loan",
    description: "Paperless process at low rate",
    thing: "10.49%",
    button: "Check Eligibility",
    id: "1",
    image: g40,
    color: "#9293C4",
    link: "/business-loan"
  },
  {
    heading: "Personal Loan",
    description: "Paperless process at low rate",
    thing: "10.49%",
    button: "Check Eligibility",
    id: "1",
    image: g10,
    color: "#D44B5E",
    link: "/personal-loan"
  },
  {
    heading: "Home Loan",
    description: "Paperless process at low rate",
    thing: "10.49%",
    button: "Check Eligibility",
    id: "1",
    image: g20,
    color: "#4B50D4",
    // link: "/home-loan"
    link: "/home-loan"
  },
  {
    heading: "Pre-owned Car Loan",
    description: "Paperless process at low rate",
    thing: "10.49%",
    button: "Check Eligibility",
    id: "1",
    image: g30,
    color: "#59ABE7",
    link: "/preowned-car-loan"
  },
  {
    heading: "Loan Against Property",
    description: "Paperless process at low rate",
    thing: "10.49%",
    button: "Check Eligibility",
    id: "1",
    image: g10,
    color: "#9293C4",
    link: "/loan-Against-Property"
  },
  {
    heading: "Professional Loan",
    description: "Paperless process at low rate",
    thing: "10.49%",
    button: "Check Eligibility",
    id: "1",
    image: g20,
    color: "#4F94C6",
    link: "/professional-loan"
  },
];


const OfferSection = ({ dark }) => {
  const swiperRef = useRef();
  let navigate = useNavigate();
  return (
    <section className="py-16 h-full flex items-center justify-center" id="services">
      <div className="w-full max-w-screen-2xl mx-auto mt-[1rem] h-full flex items-center flex-col gap-8 py-4 px-4 justify-center">
        <div className="flex items-center relative justify-center xl:gap-4 lg:gap-3 xl:px-10 px-6 w-full h-full">
          <SwitchCircle radius={"w-[80%] h-[700px]"} center={true} />
          <div onClick={() => swiperRef.current?.slidePrev()} className="w-10 h-10 flex items-center justify-center bg-white p-2 rounded-full shadow-sm cursor-pointer">
            <FaLessThan className="text-gray-600" />
          </div>
          <Swiper
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            modules={[Navigation, Pagination, EffectFade, Autoplay]}
            slidesPerView={1}
            spaceBetween={30}
            preventInteractionOnTransition={true}
            className="relative xl:h-72 h-64 w-full"
            keyboard={{
              enabled: true,
              onlyInViewport: true,
            }}
            freeMode={false}
            draggable={true}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 50,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {cards?.map((card, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="h-full w-[90%] max-w-screen-2xl mx-auto">
                    <div className={`rounded-md ${!dark ? "bg-[#fff] bg-opacity-70 text-black" : "bg-black/70 text-white"} h-full flex`}>
                      <div className="w-[60%] h-full pb-4 flex flex-col justify-between">
                        <div className="pl-3 pt-3">
                          <p style={{
                            color: card.color
                          }} className="2xl:text-2xl xl:text-xl text-base">
                            {card.heading}
                          </p>
                          <div style={{
                            backgroundColor: card.color
                          }} className="h-1" />
                        </div>
                        <div className="flex flex-col gap-3  ">
                          <p className=" px-3 text-[#777777] xl:text-base text-[13px]">{card.description}</p>
                          <div className="">
                            <p style={{
                              backgroundColor: card.color
                            }} className="w-fit 2xl:p-4 p-3 rounded-r-xl text-white xl:text-sm text-xs">
                              {card.thing}
                            </p>
                          </div>
                        </div>
                        <button onClick={() => {
                          navigate(card.link);
                        }} style={{
                          backgroundColor: dark && card.color,
                          color: dark ? "white" : card.color
                        }} className="text-[#474EBB] rounded-lg flex items-center justify-start w-[max-content] ml-3 px-2 py-1.5 gap-1">
                          <p className="text-[11px] 2xl:text-base whitespace-nowrap">{card.button}</p>
                          {!dark && <FaGreaterThan size={12} />}
                        </button>
                      </div>
                      <div className=" flex h-full w-[40%] items-end justify-end">
                        <img
                          src={card.image}
                          alt=""
                          className="xl:translate-x-10 translate-x-6 w-full h-fit object-contain"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div onClick={() => swiperRef.current?.slideNext()} className="cursor-pointer w-10 h-10 flex items-center justify-center bg-white p-2 rounded-full shadow-sm">
            <FaGreaterThan className="text-gray-600" />
          </div>
        </div>
        <div>
          <SwitchCircle />
        </div>
      </div>
    </section>
  );
};

export default OfferSection;
