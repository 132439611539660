import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import logo from "../../assets/logo.png";
import Button from "./Button";
import { useLocation, useNavigate } from "react-router";
import { UserContext } from "../../context/UserState";
// import { publicAxios } from '../../Api';
import { BiMenu } from "react-icons/bi";
import { DarkModeContext } from "../../context/DarkModeState";
import { Link } from "react-router-dom";

import { NavHashLink } from "react-router-hash-link"

const LoanForms = [
  "business-loan",
  "personal-loan",
  "home-loan",
  "preowned-car-loan"
];

const Header = ({ visible }) => {
  // const [loader, setLoader] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let darkmode = useContext(DarkModeContext);
  let { data, logoutUser } = useContext(UserContext);
  const [showDropDown, setShowDropDown] = useState(false);
  const [profileData, setProfileData] = useState();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [dark, setDark] = useState(false);
  const [navBg, setNavBg] = useState("bg-transparent");
  const [navDisplay, setNavDisplay] = useState("flex");

  useEffect(() => {
    setProfileData(data);
  }, [data]);

  useLayoutEffect(() => {
    setDark(darkmode.darkMode);
  }, [darkmode.darkMode]);

  useEffect(() => {
    if (window.scrollY > 100) {
      setNavBg(!darkmode.darkMode
        ? " bg-white"
        : " bg-[#363942] ");
    } else {
      setNavBg('bg-transparent');
    }
    window.onscroll = () => {
      if (window.scrollY > 100) {
        setNavBg(!darkmode.darkMode
          ? " bg-white"
          : " bg-[#363942] ");
      } else {
        setNavBg('bg-transparent');
      }
    }
  }, [darkmode.darkMode, location.pathname]);

  useLayoutEffect(() => {
    if (!visible) {
      if (LoanForms.every((form) => !location.pathname.includes(form))) {
        setNavDisplay("flex");
      } else {
        setNavDisplay("hidden");
      }
    }
  }, [location.pathname, visible]);

  return (
    <header className={`fixed top-0 w-full items-center z-[99] justify-center transition ease-in-out duration-300 ${navDisplay} ${navBg}`}>
      <nav className="w-full max-w-screen-2xl mx-auto h-full flex items-center justify-center">
        <div className="w-full h-full flex p-1 xs:py-2 sm:px-6 items-center justify-center">
          <div className="w-full h-full p-1 xs:py-2 sm:px-4 flex items-center justify-between flex-row">
            <div className="flex items-center justify-center">
              <img
                onClick={() => {
                  navigate("/");
                }}
                src={logo}
                alt="logo"
                className="w-36 xs:w-44 2 xl:48 hover:cursor-pointer"
              />
            </div>
            <div className="items-center justify-start flex-row gap-8 lg:flex hidden">
              <div className={`flex items-center justify-center gap-3 ${dark ? "text-white" : "text-gray-900"}`}>
                <NavHashLink to={"/#services"} smooth={true} className="xl:text-base text-sm cursor-pointer">Services</NavHashLink>
                <div className="w-4 h-[1px] bg-black rotate-90 "></div>
                <Link to={"/partner-us"} className="xl:text-base text-sm cursor-pointer">Partner with us</Link>
                {/* <NavHashLink to={"/partner-us#new"} smooth={true} className="xl:text-base text-sm cursor-pointer">Partner with us</NavHashLink> */}
                <div className="w-4 h-[1px] bg-black rotate-90 "></div>
                <NavHashLink to={"/#calculator"} smooth={true} className="xl:text-base text-sm cursor-pointer">Calculator</NavHashLink>
                <div className="w-4 h-[1px] bg-black rotate-90 "></div>
                <Link to={"/about"} className="xl:text-base text-sm cursor-pointer">About Us</Link>
              </div>
              <div className="flex">
                {profileData &&
                  profileData.avatar &&
                  profileData.avatar.url && (
                    <div className="relative hover:cursor-pointer">
                      <img
                        onClick={() => {
                          setShowDropDown(!showDropDown);
                        }}
                        className="rounded-full overflow-hidden w-5 h-5 xs:w-8 xs:h-8"
                        src={profileData.avatar?.url}
                        alt="avatar"
                      />
                      {showDropDown && (
                        <div className="absolute right-0 drop-shadow-xl bg-white rounded-md overflow-hidden z-[30]">
                          <p
                            onClick={() => {
                              navigate("/profile");
                              setShowDropDown(false);
                            }}
                            className="px-4 py-3 hover:bg-[rgb(18,91,186,0.1)]"
                          >
                            Profile
                          </p>
                          <p className="px-4 py-3 hover:bg-[rgb(18,91,186,0.1)]">
                            <a
                              onClick={() => {
                                setShowDropDown(false);
                              }}
                              href={
                                "https://activepaisa-server.vercel.app/auth/google/logout"
                              }
                              className=""
                            >
                              Logout
                            </a>
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                {showDropDown && (
                  <div
                    onClick={() => {
                      setShowDropDown(false);
                    }}
                    className="fixed top-0 left-0 w-screen h-screen z-[20]"
                  ></div>
                )}
                {!profileData && (
                  <Button
                    link={true}
                    href={"https://activepaisa-server.vercel.app/auth/google"}
                    text="Login"
                  />
                )}
              </div>
            </div>
            <div
              className="lg:hidden flex items-center justify-center"
              onClick={() => setNavbarOpen(true)}
            >
              <BiMenu className="text-gray-900 w-8 h-8" />
            </div>
          </div>
        </div>
      </nav>
      <div
        className={
          "navbar-menu relative z-50 lg:hidden transition-opacity" +
          (navbarOpen ? "flex" : " hidden")
        }
      >
        <div className="navbar-backdrop backdrop-blur-sm fixed inset-0 bg-black/40 transition duration-200 ease-in-out"></div>
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 bg-white max-w-sm py-6 px-6 bg-back text-[#3c4858] border-r overflow-y-auto transition duration-200 ease-in-out">
          <div className="flex items-center mb-8">
            <a className="mr-auto text-3xl font-bold leading-none" href="#!">
              <img
                src={logo}
                alt="activepaisa"
                className="h-12 sm:h-[4rem] object-cover"
              />
            </a>
            <button
              className="navbar-close"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <svg
                className="h-6 w-6 text-gray-200 cursor-pointer hover:text-gray-200"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="flex flex-col items-start justify-center">
            <div className="flex flex-col items-start justify-center gap-8 font-semibold text-grey-600">
              <div>
                {" "}
                <div
                  className="flex item-center justify-center cursor-pointer font-semibold"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  <NavHashLink to={"/#services"} smooth={true}>
                    <p>Services</p>
                  </NavHashLink>
                </div>
              </div>
              <div>
                <div
                  className="flex item-center justify-center flex-row cursor-pointer gap-2 font-semibold"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  <Link to={"/partner-us"}>Partner with us</Link>
                </div>
              </div>
              <div>
                {" "}
                <div
                  className="flex item-center justify-center flex-row cursor-pointer gap-2 font-semibold "
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  <NavHashLink to={"/#calculator"} smooth={true}>
                    <p>Calculator</p>
                  </NavHashLink>
                </div>
              </div>

              <div>
                <div
                  className="flex item-center justify-center flex-row cursor-pointer gap-2 font-semibold"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  <Link to={"/about"}>
                    About Us
                  </Link>
                </div>
              </div>
              <div className="flex">
                {profileData &&
                  profileData.avatar &&
                  profileData.avatar.url && (
                    <div className="relative hover:cursor-pointer">
                      <img
                        onClick={() => {
                          setShowDropDown(!showDropDown);
                        }}
                        className="rounded-full overflow-hidden w-5 h-5 xs:w-8 xs:h-8"
                        src={profileData.avatar?.url}
                        alt="avatar"
                      />
                      {showDropDown && (
                        <div className="absolute right-0 drop-shadow-xl bg-white rounded-md overflow-hidden z-[30]">
                          <p
                            onClick={() => {
                              navigate("/profile");
                              setShowDropDown(false);
                            }}
                            className="px-4 py-3 hover:bg-[rgb(18,91,186,0.1)]"
                          >
                            Profile
                          </p>
                          <p className="px-4 py-3 hover:bg-[rgb(18,91,186,0.1)]">
                            <a
                              onClick={() => {
                                setShowDropDown(false);
                              }}
                              href={
                                "https://activepaisa-server.vercel.app/auth/google/logout"
                              }
                              className=""
                            >
                              Logout
                            </a>
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                {showDropDown && (
                  <div
                    onClick={() => {
                      setShowDropDown(false);
                    }}
                    className="fixed top-0 left-0 w-screen h-screen z-[20]"
                  ></div>
                )}
                {!profileData && (
                  <Button
                    link={true}
                    href={"https://activepaisa-server.vercel.app/auth/google"}
                    text="Login"
                  />
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
