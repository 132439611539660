import React from "react";
import text1 from "../../assets/newLanding/text1.png";
import text2 from "../../assets/newLanding/text2.png";
import text3 from "../../assets/newLanding/text3.png";
import text4 from "../../assets/newLanding/text4.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import SwitchCircle from "../Global/Circle";
import { BsTwitter } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";

// import profile from "../assets/About/profile.png"
import profile from "../../assets/About/profile.png"

const InvestorsSection = (mode) => {
  return (
    <section className="w-screen min-h-screen h-full flex items-center justify-center" >
      <div className="w-full max-w-screen-2xl mx-auto h-full flex items-center justify-center">
        <div className="w-full h-full flex items-center justify-center relative">
          <SwitchCircle radius={"w-[800px] h-[800px]"} center={true} />
          <div className="flex flex-col justify-center items-center h-full w-full gap-14 lg:px-0 px-12">
            <div className="leading-[1.5] md:leading-[1.4] xl:leading-[1.2] text-center font-semibold">
              <h1 className="text-[2.5rem] md:text-[3rem] xl:text-[3.5rem] 2xl:text-[4rem]" style={{ color: mode.dark ? "white" : "black" }}>
                Aligned Investors Empowering
              </h1>
              <h1 className="text-[#2e88fe] text-[2rem] md:text-[2.5rem] xl:text-[3rem] 2xl:text-[3.5rem]" >
                Our Vision <span className="text-[black]" style={{ color: mode.dark ? "white" : "black" }}>and Fueling</span> Our
                Mission
              </h1>
            </div>
            <div className="flex items-center justify-center mt-14 gap-4 w-full h-full">
              <Swiper
                modules={[Navigation, Pagination]}
                className="h-full  gap-4 w-full"
                loop={true}
                autoplay={{ delay: 2500 }}
                centeredSlides={true}
                initialSlide={1}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >
                {/* <SwiperSlide className=" text-white flex items-center justify-center xl:px-20 lg:px-16 px-4">
                  <div className={`w-full flex flex-col justify-center items-center ${mode.dark ? "bg-[#d9d9d9] text-[black]" : "bg-[#606060] text-white"}  px-5 py-6 rounded-2xl gap-3`}>
                    <img className="w-[9rem]" src={text4} alt="sds" />
                    <h1>Karl Buckley</h1>
                    <p className="text-center text-[12px]">
                      Enable decision making and create business plan
                    </p>
                  </div>
                </SwiperSlide> */}
                <SwiperSlide className=" flex items-center justify-center xl:px-20 lg:px-16 px-4">
                  <div className={`w-full flex flex-col justify-center items-center ${mode.dark ? "bg-[#d9d9d9] text-[black]" : "bg-[#606060] text-white"}  px-5 py-6 rounded-2xl gap-3`}>
                    <img className="w-[9rem] rounded-full" src={profile} alt="sds" />
                    <h1>Mohammad Anwar</h1>
                    <p className="text-center text-[12px]">
                      Enable decision making and create business plan
                    </p>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide className=" text-white flex items-center justify-center xl:px-20 lg:px-16 px-4">
                  <div className={`w-full flex flex-col justify-center items-center ${mode.dark ? "bg-[#d9d9d9] text-[black]" : "bg-[#606060] text-white"}  px-5 py-6 rounded-2xl gap-3`}>
                    <img className="w-[9rem]" src={text2} alt="sds" />
                    <h1>Alicja Podwięż</h1>
                    <p className="text-center text-[12px]">
                      Enable decision making and create business plan
                    </p>
                  </div>
                </SwiperSlide> */}
                {/* <SwiperSlide className=" text-white flex items-center justify-center xl:px-20 lg:px-16 px-4">
                  <div className="w-full flex flex-col justify-center items-center bg-[#606060] px-5 py-6 rounded-2xl gap-3">
                    <img className="w-[9rem]" src={text1} alt="sds" />
                    <h1>Patricia Adams</h1>
                    <p className="text-center text-[12px]">
                      Enable decision making and create business plan
                    </p>
                  </div>
                </SwiperSlide> */}
                {/* <SwiperSlide className=" text-white flex items-center justify-center xl:px-20 lg:px-16 px-4">
                  <div className="w-full flex flex-col justify-center items-center bg-[#606060] px-5 py-6 rounded-2xl gap-3">
                    <img className="w-[9rem]" src={text1} alt="sds" />
                    <h1>Patricia Adams</h1>
                    <p className="text-center text-[12px]">
                      Enable decision making and create business plan
                    </p>
                  </div>
                </SwiperSlide> */}
              </Swiper>
            </div>


            {/* <div className='flex  justify-center px-6 text-center md:px-16'>
              <div className='flex flex-col md:flex-row max-w-screen-lg 2xl:max-w-screen-xl bg-[rgb(0,0,0,0.7)] rounded-2xl p-12 gap-10'>
                <div className='md:w-[40%] flex justify-center items-center '>
                  <img className='w-[330px] h-full object-cover self-center rounded-full' src={profile} />
                </div>
                <div className='flex-1 flex flex-col gap-4 text-white text-left'>
                  <h2 className='text-[1.75rem] font-bold'>"Highly regarded figure in the financial products distribution"</h2>
                  <p className='font-thin'>A Chartered Accountant qualified in 1979, Raj worked overseas with KPMG for 2 years and practiced professionally in Delhi for a decade before founding ActivPaisa 1989. His visionary and pioneering effort helped set the tone for a flourishing distribution.</p>
                  <div>
                    <p>Mohammad Anwar</p>
                    <p className='font-thin text-[0.75rem]'>CEO</p>
                  </div>
                  <div className='flex flex-col gap-1'>
                    <p className='font-thin text-[0.75rem] border-b border-white w-[max-content]'>Connect with Us!</p>
                    <div className='flex gap-2'>
                      <BsTwitter color='3080CF' />
                      <GrLinkedinOption color='3080CF' />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}


          </div>
        </div>
      </div>

    </section>
  );
};

export default InvestorsSection;
