import { Route, Routes } from "react-router";
import LandingPage from "./pages/LandingPage";
import CommingSoon from "./components/Global/CommingSoon";
import ProfileSection from "./components/ProfileSection/ProfileSection";
import LoanForm from "./components/Forms/LoanForm";
import BusinesLoan from "./components/Forms/BusinesLoan";
import PersonalLoan from "./components/Forms/PersonalLoan";
// import CraditCard from "./components/Global/CraditCard";
import AboutUs from "./pages/AboutUs";
import HomeLoan from "./components/Forms/HomeLoan";
import CarLoan from "./components/Forms/CarLoan";
import Thankyou from "./components/Forms/Thankyou";
import PartnerUsPage from "./pages/PartnerUsPage";
import LoanAgainstProperty from "./components/Forms/LoanAgainstProperty";
import ProfessionalLaon from "./components/Forms/ProfessionalLaon";


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/comingsoon" element={<CommingSoon />} />
        <Route path="/profile" element={<ProfileSection />} />
        <Route path="/form" element={<LoanForm />} />
        <Route path="/business-loan" element={<BusinesLoan />} />
        <Route path="/home-loan" element={<HomeLoan />} />
        <Route path="/personal-loan" element={<PersonalLoan />} />
        <Route path="/home-loan" element={<HomeLoan />} />
        <Route path="/preowned-car-loan" element={<CarLoan />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/partner-us" element={<PartnerUsPage />} />
        <Route path="/personal-loan" element={<PersonalLoan />} />
        <Route path="/loan-Against-Property" element={<LoanAgainstProperty />} />
        <Route path="/professional-loan" element={<ProfessionalLaon />} />
        {/* <Route path="/home-loan" element={<Home} */}
        {/* <Route path="/" element={<Thankyou />} /> */}
        {/* <Route path="/pepp" element={<CraditCard />} /> */}
      </Routes>
    </>
  );
}

export default App;
