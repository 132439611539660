import React, { useContext, useEffect } from 'react';
import { UserContext } from "../../context/UserState";
import { useNavigate } from 'react-router';
import Global from '../Global/Global';

const ProfileSection = () => {
  let { data } = useContext(UserContext);
  let navigate = useNavigate();

  useEffect(() => {
    if (!data) {
      navigate("/");
    }
  }, [data]);

  return (
    <Global>
      <section className="w-screen min-h-screen flex items-start justify-center">
        <div className="w-full mx-auto mt-[1rem] h-full flex items-start justify-center">
          <div className="w-full h-full flex items-start pt-[4rem] justify-center pb-12">
            <div className="w-full h-full flex items-center justify-start gap-4 flex-col">
              <div className="w-full h-full flex items-center justify-center relative">
                <div className="w-full h-64 items-center justify-center bg-mount bg-cover bg-center bg-fixed bg-no-repeat"></div>
                <div className="w-full flex items-center justify-center absolute left-0 right-0 -bottom-16">
                  <img className="w-36 h-36 rounded-full p-2" src={(data && data.avatar && data.avatar.url) ? data.avatar.url : "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?size=626&ext=jpg"} alt="profile" /></div>
              </div>
              <div className="w-full h-full flex items-center justify-center gap-4 flex-col mt-16 p-2">
                <div className="w-full sm:w-[85%] md:w-[70%] lg:w-[50%] h-full flex items-center justify-center md:justify-between gap-4 md:flex-row flex-col">
                  <div className="w-full h-full flex items-center justify-center gap-4">
                    <h1 className="text-lg sm:text-xl font-bold">Name: </h1>
                    <p className="text-base sm:text-lg text-gray-500 font-medium">{(data && data.name) && data.name}</p>
                  </div>
                  <div className="w-full h-full flex items-center justify-center gap-4">
                    <h1 className="text-lg sm:text-xl font-bold">Email: </h1>
                    <p className="text-base sm:text-lg text-gray-500 font-medium">{(data && data.email) && data.email}</p>
                  </div>
                </div>
                <div className="w-full sm:w-[75%] md:w-[60%] lg:w-[40%] h-full flex items-center justify-center gap-4 mt-8">
                  <p className="text-2xl md:text-3xl font-medium text-[#2f8f5f] text-center">You have been registered, we will connect to you soon.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Global>
  )
}

export default ProfileSection