import React, { useLayoutEffect, useState } from "react";
import FormTempelate from "./FormTempelate";
import { BsPerson, BsCreditCard2Front } from "react-icons/bs";
import { CiMobile3 } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbBuildingEstate } from "react-icons/tb";
import { CgCalendarDates } from "react-icons/cg";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { BsFillPersonLinesFill, BsGenderAmbiguous } from "react-icons/bs";
import { CgPassword } from "react-icons/cg";
import { FaCity, FaCarSide } from "react-icons/fa";
// import { FaMountainCity } from 'react-icons/fa';
import StepIndicator from "../Global/StepIndicator";
import SelectField from "./Fields/SelectField";
import TextField from "./Fields/TextField";
import RadioField from "./Fields/RadioField";
import { useNavigate } from "react-router";
import axios from "axios";

export const Page1 = ({ data, setData, nextPage }) => {
    const [selected, setSelected] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [errorCheck, setErrorCheck] = useState(false);

    return (
        <div>
            <div className="flex flex-col gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.
                </h1>
                <p className="text-[24px] text-[#452979]">
                    Now take the first step to fulfil your business goals.
                </p>
            </div>
            <div className="w-[50%]">
                <SelectField
                    errorCheck={errorCheck}
                    value={data.applicantType}
                    setValue={(value) => {
                        setData((prev) => {
                            return { ...prev, applicantType: value };
                        });
                    }}
                    label="Applicant Type"
                    errorMessage={"Select Applicant type"}
                    options={["Individual", "Non-individual"]}
                />
            </div>
            {data.applicantType && (
                <div className="flex flex-col gap-1 py-5">
                    <div className="pb-10">
                        <StepIndicator step={1} total={3} />
                    </div>
                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 place-items-center">
                        {data.applicantType === "Individual" ? (
                            <TextField
                                errorCheck={errorCheck}
                                label={"First Name"}
                                value={data.firstName}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, firstName: value };
                                    });
                                }}
                                errorMessage={"Enter First Name"}
                                Icon={BsPerson}
                            />
                        ) : (
                            <TextField
                                errorCheck={errorCheck}
                                label={"Firm/Company Name"}
                                value={data.firmName}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, firmName: value };
                                    });
                                }}
                                errorMessage={"Enter Firm/Company Name"}
                                Icon={BsFillPersonLinesFill}
                            />
                        )}
                        {data.applicantType === "Individual" ? (
                            <TextField
                                errorCheck={errorCheck}
                                label={"Last Name"}
                                value={data.lastName}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, lastName: value };
                                    });
                                }}
                                errorMessage={"Enter Last Name"}
                                Icon={BsPerson}
                            />
                        ) : (
                            <SelectField
                                errorCheck={errorCheck}
                                value={data.constitution}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, constitution: value };
                                    });
                                }}
                                label="Enter Constitution"
                                errorMessage={"Enter Constitution"}
                                options={[
                                    "Individual",
                                    "Proprietorship",
                                    "Partnership",
                                    "Limited Liability Partnership",
                                    "Private Limited Company",
                                    "Public Limited Company",
                                    "Trust",
                                ]}
                            />
                        )}
                        <TextField
                            errorCheck={errorCheck}
                            type="number"
                            value={data.number}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, number: value };
                                });
                            }}
                            label={"MobileNumber"}
                            errorMessage={"Enter a valid number"}
                            Icon={CiMobile3}
                        />
                        {/* <TextField type='number' value={data.verifyOtp} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, verifyOtp: value }
                    });
                }} label={"Verify Otp"} Icon={RiQuestionAnswerLine} /> */}

                        <TextField errorCheck={errorCheck} type='number' value={data.verifyOtp} setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, verifyOtp: value }
                            });
                        }} label={"OTP"} errorMessage={"Enter a valid OTP"} Icon={CiMobile3} />

                        <TextField
                            errorCheck={errorCheck}
                            value={data.email}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, email: value };
                                });
                            }}
                            label={"Email"}
                            errorMessage={"Enter a valid email Id"}
                            Icon={AiOutlineMail}
                            type="email"
                        />
                        <TextField
                            errorCheck={errorCheck}
                            value={data.businessPanNumber}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, businessPanNumber: value };
                                });
                            }}
                            label={"PAN Number"}
                            errorMessage={"Enter pan as per entity selected"}
                            Icon={BsCreditCard2Front}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            type="number"
                            value={data.loanAmount}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, loanAmount: value };
                                });
                            }}
                            label={"Loan Amount"}
                            errorMessage={"Enter loan amount"}
                            Icon={GiTakeMyMoney}
                        />
                        <SelectField
                            errorCheck={errorCheck}
                            value={data.tenure}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, tenure: value };
                                });
                            }}
                            label="Tenure"
                            errorMessage={"Please select Tenure"}
                            options={[
                                "1 Year",
                                "2 Years",
                                "3 Years",
                                "4 Years",
                                "5 Years",
                            ]}
                        />

                        <SelectField errorCheck={errorCheck} value={data.typeOfProperty}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, typeOfProperty: value };
                                });
                            }}
                            label="Type of Property"
                            errorMessage={"Please select Type of Property"}
                            options={[
                                "Residential",
                                "Commercial",
                                "Industrial",
                                "Warehouse",
                                "Residential Land",
                            ]}
                        />
                        <SelectField errorCheck={errorCheck} value={data.natureOfLoan}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, natureOfLoan: value };
                                });
                            }}
                            label="Nature of Loan"
                            errorMessage={"Please select the Nature of Loan"}
                            options={[
                                "New Loan",
                                "Balance Transfer under",
                                "BT + New",
                                "Top Up",
                                "Top Up + BT",
                                "Refinance"

                            ]}
                        />


                    </div>
                    <div className="mt-10 flex gap-3 items-center">
                        <input
                            onChange={(e) => {
                                setSelected(e.target.checked);
                            }}
                            type="checkbox"
                            id="proceedingConditions"
                            className="border border-[#452979] outline-none checked:bg-[#452979] checked:after:text-white"
                        />
                        <label
                            htmlFor="proceedingConditions"
                            className="text-gray-400 text-xs"
                        >
                            By proceeding I agree to{" "}
                            <span className="text-blue-700 underline">
                                Terms and Conditions
                            </span>{" "}
                            and have read/understood{" "}
                            <span className="text-blue-700 underline">
                                approach for gradation of risk.
                            </span>
                        </label>
                    </div>
                    {termsError && (
                        <p className="font-thin text-sm text-[red]">
                            Select Agree to Terms & Conditions
                        </p>
                    )}
                    <div className="mt-10">
                        <button
                            onClick={() => {
                                if (
                                    data.businessName !== "" &&
                                    data.entityType !== "" &&
                                    data.number !== "" &&
                                    data.email !== "" &&
                                    data.businessPanNumber !== "" &&
                                    data.loanAmount !== "" &&
                                    data.tenure !== ""
                                ) {
                                    setErrorCheck(false);
                                    if (selected) {
                                        setTermsError(false);
                                        nextPage();
                                    } else {
                                        setTermsError(true);
                                    }
                                } else {
                                    setErrorCheck(true);
                                }
                            }}
                            className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};


export const Page2 = ({ data, setData, prevPage, nextPage }) => {
    const [errorCheck, setErrorCheck] = useState(false);
    return (
        <div>
            <div className="flex flex-col gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.                 </h1>
                <p className="text-[24px] text-[#452979]">
                    You are one step closer.
                </p>
            </div>
            <div className="pb-10">
                <StepIndicator step={2} total={2} />
            </div>

            {data.applicantType === "Individual" ? (
                <>
                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 pb-10 place-items-center">

                        <TextField label={"Date of Birth"} Icon={BsPerson}
                            errorCheck={errorCheck}
                            errorMessage={"Enter a valid dateOfBirth"}
                            value={data.dateOfBirth}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, dateOfBirth: value };
                                });
                            }}
                            type='date'
                        />
                        <RadioField errorCheck={errorCheck} value={data.gender} setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, gender: value }
                            });
                        }} label={"Gender"} errorMessage={"Please select your gender"} Icon={HiOutlineCurrencyRupee} options={["Male", "Female", "Transgender"]} />
                        <SelectField

                            value={data.residenceOwnershipStatus}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, residenceOwnershipStatus: value };
                                });
                            }}
                            label="Residence Ownership Status"
                            options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]}
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-2 place-items-center">
                        <TextField label={"Pincode"} Icon={BsPerson} errorCheck={errorCheck}
                            value={data.residentialPincode}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, residentialPincode: value };
                                });
                            }}
                            errorMessage={"Please enter a valid Pincode"} />
                        <TextField label={"City"} Icon={BsPerson} errorCheck={errorCheck}
                            value={data.city}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, city: value };
                                });
                            }}
                            errorMessage={"Please enter City"} />
                        <TextField label={"State"} Icon={BsPerson} errorCheck={errorCheck}
                            value={data.state}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, state: value };
                                });
                            }}
                            errorMessage={"Please enter State"} />
                    </div>
                </>
            ) : (
                <>
                    <div className="grid grid-cols-2 gap-x-12 gap-y-9 pb-10 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            value={data.dateOfIncorporation}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, dateOfIncorporation: value };
                                });
                            }}
                            label={"Date of Incorporation"}
                            Icon={CgCalendarDates}
                            type="date"
                            errorMessage={"Please Select a valid Date of Incorporation"}
                        />
                        <SelectField
                            errorCheck={errorCheck}
                            value={data.businessPremisesOwnership}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, businessPremisesOwnership: value };
                                });
                            }}
                            label="Business Premises Ownership"
                            options={["Owned by Self or Spouse", "Rented"]}
                            errorMessage={"Please Enter Business Premises Ownership"}
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-2 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            value={data.businessPincode}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, businessPincode: value };
                                });
                            }}
                            label={"Business Pincode"}
                            Icon={CgPassword}
                            errorMessage={"Please enter a valid Pincode"}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            value={data.city}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, city: value };
                                });
                            }}
                            label={"City"}
                            Icon={FaCity}
                            errorMessage={"Please enter City"}
                        />
                        <TextField
                            errorCheck={errorCheck}
                            value={data.state}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, state: value };
                                });
                            }}
                            label={"State"}
                            Icon={TbBuildingEstate}
                            errorMessage={"Please enter State"}
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-x-12 gap-y-9 mt-8 place-items-center">
                        <TextField
                            errorCheck={errorCheck}
                            value={data.annualTurnOver}
                            setValue={(value) => {
                                setData((prev) => {
                                    return { ...prev, annualTurnOver: value };
                                });
                            }}
                            label={"Annual TernOver"}
                            Icon={TbBuildingEstate}
                            errorMessage={"Please enter your Annual TernOver"}
                        />
                    </div>
                </>
            )}
            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {

                        if (data.applicantType) {
                            if (data.applicantType === "Individual" &&
                                data.dateOfBirth !== "" &&
                                data.gender !== "" &&
                                data.residenceOwnershipStatus !== "" &&
                                data.residentialPincode !== "" &&
                                data.city !== "" &&
                                data.state !== ""
                            ) {
                                setErrorCheck(false);
                                nextPage();
                                console.log(data.state);
                            }
                            else if (
                                data.applicantType === "Non-individual" &&
                                data.dateOfIncorporation !== "" &&
                                data.businessPremisesOwnership !== "" &&
                                data.annualTurnOver !== "" &&
                                data.businessPincode !== "" &&
                                data.city !== "" &&
                                data.state !== ""
                            ) {
                                setErrorCheck(false);
                                nextPage();
                                // console.log(data.state);

                            }
                            else {
                                setErrorCheck(true);
                                // console.log("")
                            }
                        }
                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};


export const Page3 = ({ data, setData, prevPage, nextPage, hendleSubmit }) => {
    const [errorCheck, setErrorCheck] = useState(false);
    return (
        <div>
            <div className="flex flex-col gap-1 py-10">
                <h1 className="text-[28px] font-semibold text-[#452979]">
                    Gear Up to own a car with our Pre-owned Car Loan.
                </h1>
                <p className="text-[24px] text-[#452979]">
                    Keep going you are about to complete your journey.
                </p>
            </div>
            <div className="pb-10">
                <StepIndicator step={3} total={3} />
            </div>
            {data.applicantType === "Individual" ? (
                <div className="grid grid-cols-2 py-5 gap-x-12 gap-y-9">
                    <SelectField
                        errorCheck={errorCheck}
                        errorMessage={"Enter Select Occupation"}
                        options={["Self Employed", "Salaried"]}
                        value={data.occupation}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, occupation: value };
                            });
                        }}
                        label={"Occupation"}
                    />
                    {data.occupation && (
                        <>
                            <SelectField
                                errorCheck={errorCheck}
                                errorMessage={"Enter Select Sub Occupation"}
                                options={[
                                    "Professional",
                                    "Businessman",
                                ]}
                                value={data.subOccupation}
                                setValue={(value) => {
                                    setData((prev) => {
                                        return { ...prev, subOccupation: value };
                                    });
                                }}
                                label={"Sub Occupation"}
                            />
                        </>
                    )}
                </div>
            ) : (
                <div className="grid grid-cols-2 gap-x-12 gap-y-9 place-items-center">
                    <TextField
                        errorCheck={errorCheck}
                        label={"First Name"}
                        value={data.firstName}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, firstName: value };
                            });
                        }}
                        errorMessage={"Enter First Name"}
                        Icon={BsPerson}
                    />
                    <TextField
                        errorCheck={errorCheck}
                        label={"Last Name"}
                        value={data.lastName}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, lastName: value };
                            });
                        }}
                        errorMessage={"Enter Last Name"}
                        Icon={BsPerson}
                    />
                    <SelectField
                        errorCheck={errorCheck}
                        value={data.residenceOwnershipStatus}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, residenceOwnershipStatus: value };
                            });
                        }}
                        label="Residence Ownership Status"
                        options={["Owned by Self/Spouse", "Owned by Parents", "Rented"]}
                        errorMessage={"Plese Select Residence Status"}
                    />

                    <RadioField
                        errorCheck={errorCheck}
                        value={data.gender}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, gender: value };
                            });
                        }}
                        label={"Gender"}
                        Icon={BsGenderAmbiguous}
                        options={["Male", "Female", "Transgender"]}
                        errorMessage={"Plese Select Your Gender"}
                    />

                    <TextField
                        errorCheck={errorCheck}
                        value={data.dateOfBirth}
                        setValue={(value) => {
                            setData((prev) => {
                                return { ...prev, dateOfBirth: value };
                            });
                        }}
                        label={"Date of Birth"}
                        Icon={CgCalendarDates}
                        type="date"
                        errorMessage={"Plese Select a valid Date Of Birth"}

                    />
                </div>
            )}

            <div className="flex gap-5 mt-10">
                <button
                    onClick={prevPage}
                    className="text-[18px] text-black underline duration-500"
                >
                    Back
                </button>
                <button
                    onClick={() => {

                        if (data.applicantType) {
                            if (data.applicantType === "Individual" &&
                                data.occupation !== "" &&
                                data.subOccupation !== ""
                            ) {
                                setErrorCheck(false);
                                nextPage();
                                hendleSubmit(data)
                            }
                            else if (
                                data.applicantType === "Non-individual" &&
                                data.firmName !== "" &&
                                data.lastName !== "" &&
                                data.residenceOwnershipStatus !== "" &&
                                data.gender !== "" &&
                                data.dateOfBirth !== ""

                            ) {
                                setErrorCheck(false);
                                nextPage();
                                hendleSubmit(data);
                            }
                            else {
                                setErrorCheck(true);
                                console.log("not")
                            }
                        }
                    }}
                    className="rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

const LoanAgainstProperty = () => {
    let navigation = useNavigate();
    const [data, setData] = useState({

        // ---------common
        applicantType: "",
        number: "",
        verifyOtp: "",
        email: "",
        businessPanNumber: "",
        loanAmount: "",
        tenure: "",
        typeOfProperty: "",
        natureOfLoan: "",




        // ----------individual

        // page 1
        firstName: "",
        lastName: "",



        // page 2

        dateOfBirth: "",
        residenceOwnershipStatus: "",
        gender: "",
        residentialPincode: "",
        city: "",
        state: "",

        // page 3
        occupation: "",
        subOccupation: "",



        // ---------non-individual


        // page 1

        firmName: "",
        constitution: "",

        //page 2

        dateOfIncorporation: "",
        businessPremisesOwnership: "",
        annualTurnOver: "",
        businessPincode: "",

    });
    const [page, setPage] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loader, setLoader] = useState(false);
    const nextPage = () => {
        if (page < Pages.length) {
            setPage(page + 1);
        } 
    };
    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const navigate=useNavigate();


    const hendleSubmit = (data) => {
        setLoader(true);
        // console.log(data.applicantType);
        if (data.applicantType) {
            let proObj = {}
            if (data.applicantType === "Individual") {


                proObj["applicantType"] = data.applicantType
                proObj["firstName"] = data.firstName
                proObj["lastName"] = data.lastName
                proObj["mobile"] = data.number
                proObj["otp"] = data.verifyOtp
                proObj["email"] = data.email
                proObj["panNumber"] = data.businessPanNumber
                proObj["loanAmount"] = Number(data.loanAmount)
                proObj["tenure"] = data.tenure
                proObj["typeOfProperty"] = data.typeOfProperty
                proObj["natureOfLoan"] = data.natureOfLoan
                proObj["dateOfBirth"] = data.dateOfBirth
                proObj["gender"] = data.gender
                proObj["residenceOwnershipStatus"] = data.residenceOwnershipStatus
                proObj["pincode"] = data.residentialPincode
                proObj["city"] = data.city
                proObj["state"] = data.state
                proObj["occupation"] = data.occupation
                proObj["subOccupation"] = data.subOccupation

                // console.log(proObj)


                axios.post("https://activ-paisa-server.vercel.app/api/loans/loan-against-property-application", {
                    ...proObj
                }).then(() => {
                    // console.log(proObj);
                    setLoader(false);
                    navigate("/thankyou")
                }).catch((error) => {
                    console.log(error);
                })

            }
            else if (data.applicantType === "Non-individual") {

                proObj["applicantType"] = data.applicantType
                proObj["companyName"] = data.firmName
                proObj["entityType"] = data.constitution
                proObj["mobile"] = data.number
                proObj["otp"] = data.verifyOtp
                proObj["email"] = data.email
                proObj["panNumber"] = data.businessPanNumber
                proObj["loanAmount"] = Number(data.loanAmount)
                proObj["tenure"] = data.tenure
                proObj["typeOfProperty"] = data.typeOfProperty
                proObj["natureOfLoan"] = data.natureOfLoan
                proObj["dateOfIncorporation"] = data.dateOfIncorporation
                proObj["businessPremisesOwnership"] = data.businessPremisesOwnership
                proObj["pincode"] = data.businessPincode
                proObj["city"] = data.city
                proObj["state"] = data.state
                proObj["annualTurnover"] = Number(data.annualTurnOver)
                proObj["firstName"] = data.firstName
                proObj["lastName"] = data.lastName
                proObj["gender"] = data.gender
                proObj["dateOfBirth"] = data.dateOfBirth


                axios.post("https://activ-paisa-server.vercel.app/api/loans/loan-against-property-application", {
                    ...proObj
                }).then(() => {
                    // console.log(proObj, "sent");
                    setLoader(false);
                    navigate("/thankyou")
                }).catch((error) => {
                    console.log(error);
                    setLoader(false)
                })
            }
            else {
                console.log("no")
                setLoader(false);
            }
        }
        else {
            console.log("type");
            setLoader(false)

        }
    }


    const Pages = [
        <Page1 data={data} setData={setData} nextPage={nextPage} />,
        <Page2
            data={data}
            setData={setData}
            prevPage={prevPage}
            nextPage={nextPage}
        />,
        <Page3
            data={data}
            setData={setData}
            prevPage={prevPage}
            nextPage={nextPage}
            hendleSubmit={hendleSubmit}
        />,

    ];

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <FormTempelate isSubmitted={isSubmitted} image={require("../../assets/Loans/carBanner.jpg")}>{Pages[page - 1]}</FormTempelate>;
}

export default LoanAgainstProperty