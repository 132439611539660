import React, { useEffect, useLayoutEffect, useState } from 'react'
import FormTempelate from './FormTempelate'
import { BsPerson, BsCreditCard2Front } from 'react-icons/bs';
import { CiMobile3 } from 'react-icons/ci';
import { RiQuestionAnswerLine } from 'react-icons/ri';
import { AiOutlineMail } from 'react-icons/ai';
import { GiTakeMyMoney } from 'react-icons/gi';
import { CgCalendarDates } from 'react-icons/cg';
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import { BsBuilding } from 'react-icons/bs';
import { CgPassword } from 'react-icons/cg';
import { FaCity } from 'react-icons/fa';
// import { FaMountainCity } from 'react-icons/fa';
import StepIndicator from '../Global/StepIndicator';
import SelectField from './Fields/SelectField';
import TextField from './Fields/TextField';
import RadioField from './Fields/RadioField';
import { useNavigate } from 'react-router';
import axios from 'axios';


export const Page1 = ({ data, setData, nextPage }) => {

    const [selected, setSelected] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [errorCheck, setErrorCheck] = useState(false);

    return (
        <div>
            <div className='flex flex-col gap-1 py-10'>
                <h1 className='text-[28px] font-semibold text-[#452979]'>Great choice! Now soar new heights with our Home Loan.</h1>
                <p className='text-[24px] text-[#452979]'>Now take the first step to fulfil your Home goals.</p>
            </div>
            <div className='pb-10'>
                <StepIndicator step={1} total={2} />
            </div>
            <div className='grid grid-cols-2 gap-x-12 gap-y-12 place-items-center'>
                <TextField errorCheck={errorCheck} label={"First Name"} value={data.firstName} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, firstName: value }
                    });
                }} errorMessage={"Enter a First Name"} Icon={BsPerson} />
                <TextField errorCheck={errorCheck} label={"Last Name"} value={data.lastName} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, lastName: value }
                    });
                }} errorMessage={"Enter a Last Name"} Icon={BsPerson} />
                <TextField errorCheck={errorCheck} type='number' value={data.mobile} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, mobile: value }
                    });
                }} label={"Moblie Number"} errorMessage={"Enter a valid number"} Icon={CiMobile3} />
                {/* <TextField type='number' value={data.verifyOtp} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, verifyOtp: value }
                    });
                }} label={"Verify Otp"} Icon={RiQuestionAnswerLine} /> */}
                <TextField errorCheck={errorCheck} value={data.email} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, email: value }
                    });
                }} label={"Email"} errorMessage={"Enter a valid email Id"} Icon={AiOutlineMail} />
            </div>
            <div className='mt-10 flex gap-3 items-center'>
                <input onChange={(e) => {
                    setSelected(e.target.checked);
                }} type='checkbox' id='proceedingConditions' className='border border-[#452979] outline-none checked:bg-[#452979] checked:after:text-white' />
                <label htmlFor='proceedingConditions' className='text-gray-400 text-xs'>By proceeding I agree to <span className='text-blue-700 underline'>Terms and Conditions</span> and have read/understood <span className='text-blue-700 underline'>approach for gradation of risk.</span></label>
            </div>
            {termsError && <p className='font-thin text-sm text-[red]'>Select Agree to Terms & Conditions</p>}
            <div className='mt-10'>
                <button onClick={() => {
                    if (data.businessName !== "" && data.entityType !== "" && data.number !== "" && data.email !== "" && data.businessPanNumber !== "" && data.loanAmount !== "" && data.tenure !== "") {
                        setErrorCheck(false);
                        if (selected) {
                            setTermsError(false);
                            nextPage();
                        } else {
                            setTermsError(true);
                        }
                    } else {
                        setErrorCheck(true);
                    }
                }} className='rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500'>Next</button>
            </div>
        </div>
    );
}
export const Page2 = ({ data, setData, prevPage, nextPage,hendleSubmitApi }) => {
    return (
        <div>
            <div className='flex flex-col gap-1 py-10'>
                <h1 className='text-[28px] font-semibold text-[#452979]'>Great choice! Now soar new heights with our Business Loan.</h1>
                <p className='text-[24px] text-[#452979]'>Keep going you are almost there.</p>
            </div>
            <div className='pb-10'>
                <StepIndicator step={2} total={2} />
            </div>

            <div className='grid grid-cols-2 gap-x-12 gap-y-12 pb-10 place-items-center'>
                <TextField value={data.dateOfBirth} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, dateOfBirth: value }
                    });
                }} label={"Date of Birth"} Icon={CgCalendarDates} type='date' />
                  
            </div>
            <div className='grid grid-cols-3 gap-x-12 gap-y-12 mt-8 place-items-center'>
                
                <TextField value={data.city} setValue={(value) => {
                    setData((prev) => {
                        return { ...prev, city: value }
                    });
                }} label={"City"} Icon={FaCity} />
                
            </div>
            <div className='flex gap-5 mt-10'>
                <button onClick={prevPage} className='text-[18px] text-black underline duration-500'>Back</button>
                <button onClick={()=>{
                    hendleSubmitApi(data);
                }} className='rounded-lg bg-[#452979] text-[18px] text-white px-6 py-1 hover:bg-white border-2 border-[#452979] hover:text-[#452979] duration-500'>Next</button>
            </div>
        </div>
    );
}


export default function HomeLoan() {

    let navigation = useNavigate();
    const [data, setData] = useState({
        firstName:"",
        lastName:"",
        mobile:"",
        email:"",
        city:"",
        dateOfBirth:""
    });
    const [page, setPage] = useState(1);
    const nextPage = () => {
        if (page < Pages.length) {
            setPage(page + 1);
        }
    }
    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    

    const hendleSubmitApi=(data)=>{
        console.log(data);

        let proObj=data;

        axios.post("https://activ-paisa-server.vercel.app/api/loans/home-application",{
            ...proObj
        }).then(()=>{
            console.log("done");

        }).catch((e)=>{
            console.log(e);
        })
    }

    const Pages = [
        <Page1 data={data} setData={setData} nextPage={nextPage} />,
        <Page2 data={data} setData={setData} prevPage={prevPage} nextPage={nextPage} hendleSubmitApi={hendleSubmitApi}  />,
    ];

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FormTempelate image={require("../../assets/Loans/business-loan.jpg")}>
            {Pages[page - 1]}
        </FormTempelate>
    );
}