import React from "react";
import arrow1 from "../../assets/arrow1.png";
import arrow2 from "../../assets/arrow2.png";
import quote from "../../assets/quote.png";
import decision from "../../assets/decision.png";
import funding from "../../assets/funding.png";
import Button from "../../components/Global/Button";
import privacy from "../../assets/privacy.png";
import MessagesBro from "../../assets/newLanding/MessagesBro.png";
import ThinkingFaceBro1 from "../../assets/newLanding/ThinkingFaceBro1.png";
import OnlineTransactionsAmico from "../../assets/newLanding/OnlineTransactionsAmico 1.png";
import Vector from "../../assets/newLanding/Private data-bro 1.png";
import discovery from "../../assets/newLanding/gg.png";
import SwitchCircle from "../Global/Circle";

const ApplicationSection = (mode) => {
  return (
    <section className="w-screen min-h-screen h-full flex items-center justify-center" >
      <div className="w-full max-w-screen-2xl mx-auto mt-[1rem] h-full flex items-center py-4 xl:px-4 px-12 justify-center flex-col gap-12">
        <div className="flex flex-col w-full justify-center gap-8 ">
          <div className="flex justify-center w-full items-center">
            <h1 className="text-[1.8rem] md:text-[2rem] lg:text-[3rem] xl:text-[4rem]" style={{ color: mode.dark ? "white" : "black" }}>
              We offer a{" "}
              <span className="text-[#2e88fe]">Simple Online Solution!</span>
            </h1>
          </div>
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 justify-center gap-2 p-2 max-w-screen-xl mx-auto">
            <div className="grid-cols-1 flex justify-center w-full h-full relative">
              <div className={`flex p-4 w-full sm:w-[75%] flex-col justify-center items-center gap-3 drop-shadow-xl shadow-2xl rounded-3xl ${mode.dark ? "bg-[#b1b2b7]" : "bg-white"}`}>
                <img className="w-[18rem]" src={MessagesBro} alt="messagebro"></img>
                <h1 className="font-[500] xl:text-[1.5rem] text-xl text-center">Get a Free Quotec</h1>
                <p className="text-[#626262] lg:text-sm text-xs self-center text-center">
                  We will customize a loan based on the amount of cash your
                  company need term length
                </p>
              </div>
              <SwitchCircle radius={"w-[600px] h-[600px]"} center={true} />
            </div>
            <div className="grid-cols-1 flex justify-center w-full h-full relative">
              <div className={`flex p-4 w-full sm:w-[75%] flex-col justify-center items-center gap-3 drop-shadow-xl shadow-2xl rounded-3xl ${mode.dark ? "bg-[#b1b2b7]" : "bg-white"}`}>
                <img className="w-[18rem]" src={ThinkingFaceBro1} alt="thinking"></img>
                <h1 className="font-[500] xl:text-[1.5rem] text-xl text-center">
                  Get an Instant Decision
                </h1>
                <p className="text-[#626262] lg:text-sm text-xs self-center text-center">
                  We provide online instant cash loans with quick approval that
                  suit your term length
                </p>
              </div>
              <SwitchCircle radius={"w-[600px] h-[600px]"} center={true} />
            </div>
            <div className="grid-cols-1 flex justify-center w-full h-full relative">
              <div className={`flex p-4 w-full sm:w-[75%] flex-col justify-center items-center gap-3 drop-shadow-xl shadow-2xl rounded-3xl ${mode.dark ? "bg-[#b1b2b7]" : "bg-white"}`}>
                <img className="w-[18rem]" src={OnlineTransactionsAmico} alt="online"></img>
                <h1 className="font-[500] xl:text-[1.5rem] text-xl text-center">Get Funding Fast</h1>
                <p className="text-[#626262] lg:text-sm text-xs self-center text-center">
                  When you have a good credit profile and you have built your loan
                  cheaper with us
                </p>
              </div>
              <SwitchCircle radius={"w-[600px] h-[600px]"} center={true} />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center py-12">
          <div className="flex items-center justify-center">
            <div className="w-full h-full items-center justify-center flex md:flex-row flex-col">
              <div className="w-full md:w-[60%] flex flex-col justify-center items-center gap-8 ">
                <div className="leading-[1.5] md:leading-[1.4] xl:leading-[1.2] flex p-1 flex-col">
                  <h1 className="text-[1.8rem] md:text-[2.4rem] lg:text-[3rem] xl:text-[3.6rem]" style={{ color: mode.dark ? "white" : "black" }}>
                    Protecting{" "}
                    <span className="text-[#2e88fe]">Your Privacy</span> and
                  </h1>
                  <h1 className="text-[1.4rem] lg:text-[2rem] xl:text-[2.6rem]" style={{ color: mode.dark ? "white" : "black" }}>
                    Empowering{" "}
                    <span className="text-[#2e88fe]">Consent Management</span>
                  </h1>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <ul className="list-disc text-[400] text-[#626262] xl:text-[17px] text-sm w-[80%] gap-3 flex flex-col" style={{ color: mode.dark ? "#999ba0" : "#626262" }}>
                    <li>
                      We prioritize the privacy and security of your personal
                      information. Our robust data privacy measures ensure that
                      your data is protected and handled with utmost care
                    </li>
                    <li>
                      Additionally, we provide transparent consent management,
                      giving you control over how your data is used and allowing
                      you to make informed decisions about sharing your
                      information
                    </li>
                    <li>
                      Your trust and privacy are of utmost importance to us, and
                      we are committed to maintaining the highest standards of
                      data privacy and consent management
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex  md:w-[40%] w-full justify-center items-center p-2">
                <img className="w-[20rem] h-[20rem] lg:w-[30rem] lg:h-[30rem] object-fill" src={Vector} alt="vector"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplicationSection;
